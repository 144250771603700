import { HorizontalFlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const Container = styled(HorizontalFlexDiv)`
    justify-content: space-between;
    padding: 8px 20px 4px 20px;
    align-items: center;
    margin-bottom: 0px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
`
export const Title = styled.span`
    font-size: 20px;
    font-weight: bold;
    font-family: Inter;
`