import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS } from "./config"
import { ProductPresentation } from "src/common/models/productPresentation"

interface Props {
    productPresentations: ProductPresentation[]
}

export const ProductPresentationsList = (props: Props) => {
    return (
        <SearchableTable
            items={props.productPresentations}
            tableColumns={TABLE_COLUMNS}
            showLoader={false}
        />
    )
}
