import { Languages } from "src/common/descriptions/types";
import { ProductsDescriptions } from "./types";

const DescriptionsEnUs: ProductsDescriptions = {
    TITLE: "Products - Active",
    TABLE: {
        ACTION_BUTTON: "Create Product",
        SEARCH_PLACEHOLDER: "Search Product",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        ID: "ID",
        DESCRIPTION: "Description",
        PRESENTATIONS: "Presentations",
        CATEGORY: "Category",
        BRAND: "Brand",
        SUBBRAND: "SubBrand",
        STATUS: "Status",
        ACTIONS: "Actions",
    },
    PRESENTATIONS: {
        QTY: "Quantity",
        PRICE: "Price",
        MEASSURE: "Meassure",
    },
    TITLE_INACTIVES: "Products - Inactive",
    BUTTON_ACTIVES: "View Actives",
    BUTTON_INACTIVES: "View Inactives",
}

const DescriptionsEsUs: ProductsDescriptions = {
    TITLE: "Productos - Activos",
    TABLE: {
        ACTION_BUTTON: "Crear Producto",
        SEARCH_PLACEHOLDER: "Buscar Producto",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        ID: "Código",
        DESCRIPTION: "Descripción",
        PRESENTATIONS: "Presentaciones",
        CATEGORY: "Categoría",
        BRAND: "Marca",
        SUBBRAND: "SubMarca",
        STATUS: "Estado",
        ACTIONS: "Acciones",
    },
    PRESENTATIONS: {
        QTY: "Cantidad",
        PRICE: "Precio",
        MEASSURE: "Presentación",
    },
    TITLE_INACTIVES: "Productos - Inactivos",
    BUTTON_ACTIVES: "Ver Activos",
    BUTTON_INACTIVES: "Ver Inactivos",
}

export const productsDictionary = {}
productsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
productsDictionary[Languages.SPANISH_US] = DescriptionsEsUs