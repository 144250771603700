import { Alert, Button, DatePicker, Form, Input, Row, Select, Skeleton, Space, Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"
import { addTimeToDateMoment, cleanCurrencyValue, formatToCurrency, getInputRequiredRule, parseDataKey, selectFilterOption } from "src/common/util"
import { Col12, Col24, Col4, Col6, Col9 } from "src/components/Columns"
import { CurrencyInput } from "src/components/CurrencyInput"
import { SearchableTable } from "src/components/SearchableTable"
import { summaryColumns, tableColumns } from "./config"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { SystemDescriptions } from "src/common/descriptions"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { SystemConstants } from "src/common/constants"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { Product } from "src/common/models/product"
import { getProductsApi } from "src/features/Product/state/action"
import { ProductTransactionDetail, ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { ProductTransactionModal } from "src/components/ProductTransactionModal"
import { FormActionButtons } from "src/components/FormActionButtons"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import dayjs from 'dayjs'
import { getSessionData } from "src/session/sessionStore"
import { GET_PRODUCTS_API } from "src/features/Product/state/actionTypes"
import { getRoutingCustomersApi } from "../../state/action"
import { RoutingCustomer } from "src/common/models/routingCustomer"
import { RoutingSaleError } from "../../state/types"
import { GET_ROUTING_CUSTOMERS_API } from "../../state/actionTypes"
import moment from "moment"
import { RoutingSale, RoutingSaleDetail } from "src/common/models/routingSale"

interface ReduxProps {
    customers?: RoutingCustomer[]
    products?: Product[]
    saleError?: RoutingSaleError
    isGettingProducts: boolean
    isGettingCustomers: boolean
}

interface Props {
    onFinish: (sale: RoutingSale) => void
    onCancel: () => void
    initialValues?: RoutingSale
    readonly?: boolean
    reCertification?: boolean
}

export const CreateRoutingSaleForm = (props: Props) => {

    const dispatch = useDispatch()
    const selectRef = useRef<any>(null);

    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
    const [saleDetail, setSaleDetail] = useState<ProductTransactionDetail[]>([])
    const [selectedDetail, setSelectedDetail] = useState<ProductTransactionDetail | undefined>(undefined)
    const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(false)

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            handleFocus()
        }
    };

    useEffect(() => {

        window.addEventListener('keydown', handleKeyPress);

        if (!props.readonly) {
            dispatch(getRoutingCustomersApi())
            dispatch(getProductsApi({
                status: 'ACTIVE',
            }))
            // form.setFieldsValue({
            //     datetime: moment(),
            // })
        }

        if (props.initialValues) {
            // const sale: RoutingSale = { ...props.initialValues }

            // const detail: ProductTransactionDetail[] = sale.detail.map(detailItem => ({
            //     productId: detailItem.productId,
            //     product: detailItem.description,
            //     meassureId: detailItem.meassureId,
            //     meassure: detailItem.meassure,
            //     meassureSymbol: detailItem.meassure,
            //     qty: detailItem.qty,
            //     unitPrice: detailItem.price,
            //     subtotal: detailItem.total,
            //     disscount: 0,
            //     total: detailItem.total,

            //     productType: ProductType.GOOD,
            // }))
            // setSaleDetail(detail)

            // form.setFieldsValue({
            //     customer: sale.customerId,
            //     shipTo: sale.shipTo,
            //     document: sale.document,
            //     datetime: dayjs(sale.datetime),
            //     type: sale.paymentType,
            //     expirationDate: dayjs(sale.expirationDate),
            //     paymentType: sale.paymentType,
            //     subtotal: formatToCurrency(sale.amount.toString()).toString(),
            //     disscount: formatToCurrency(sale.disscount.toString()),
            //     total: formatToCurrency(sale.amount.toString()),
            //     invoiceReceiverId: sale.invoiceReceiverId,
            // })
        } else {
            const saleDetail = getSessionData().saleDetail

            if (saleDetail) {
                setSaleDetail(saleDetail)
            }
        }

        form.setFieldsValue({
            disscount: 0,
        })

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [])

    const handleFocus = () => {
        if (selectRef.current && !selectedProduct) {
            selectRef.current.focus();
        }
    };

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        customers: state.routing.customers,
        products: state.product.products,
        saleError: state.routing.error,
        isGettingCustomers: serviceLoading(state, [GET_ROUTING_CUSTOMERS_API]),
        isGettingProducts: serviceLoading(state, [GET_PRODUCTS_API]),
    }))

    const descriptions = SystemDescriptions.PAGES.SALE.CREATE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const [form] = Form.useForm();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const tblColumns = [
        ...tableColumns,
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            hidden: props.readonly,
            render: (_, record: ProductTransactionDetail) => {
                const handleEditClick = (detailItem: ProductTransactionDetail) => {
                    const foundProduct = reduxProps?.products?.find(candidate => candidate.id === detailItem.productId)
                    setSelectedProduct(foundProduct)
                    setSelectedDetail(detailItem)
                }

                const handleDeleteClick = (ref: string) => {
                    const newDetail: ProductTransactionDetail[] = []
                    saleDetail.forEach(detail => {
                        if (detail.ref !== ref) {
                            newDetail.push(detail)
                        }
                    })
                    setSaleDetail(newDetail)
                    // storeSessionData({
                    //     saleDetail: newDetail,
                    // })
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.EDIT} >
                        <Button type="primary" ghost shape="circle"
                            icon={<EditOutlined rev={undefined} />}
                            onClick={() => handleEditClick(record)}
                        />
                    </Tooltip>
                    <Tooltip title={commonDescriptions.ACTIONS.DELETE} >
                        <Button type="primary" danger ghost shape="circle"
                            icon={<DeleteOutlined rev={undefined} />}
                            onClick={() => handleDeleteClick(record.ref!)}
                        />
                    </Tooltip>
                </Space>
            },
        },
    ]

    const onFinish = (formValues) => {

        const saleDetailParsed: RoutingSaleDetail[] = saleDetail.map(detailItem => {
            return {
                productId: detailItem.productId,
                qty: detailItem.qty,
                price: detailItem.unitPrice,
                total: detailItem.total,
                description: detailItem.product,
                meassureId: detailItem.meassureId,
                meassure: detailItem.meassure,
                minimalQty: 1,
                minimalMeassure: "",
                costeMinimal: 0,
            }
        })

        const sale: RoutingSale = {
            customerId: Number(formValues.customer),
            datetime: addTimeToDateMoment(formValues.datetime),
            shipTo: formValues.shipTo,
            subtotal: Number(cleanCurrencyValue(formValues.total)),
            disscount: 0,
            total: Number(cleanCurrencyValue(formValues.total)),
            status: "READY",
            paymentStatus: "PENDING",
            seller: "", // ADD SELECT
            userId: 1, // todo: get from redux
            detail: saleDetailParsed,
        }

        props.onFinish(sale)
    }

    const renderPurchaseDetail = () => (
        <SearchableTable
            showLoader={false}
            items={saleDetail}
            tableColumns={tblColumns}
            summaryColumns={summaryColumns}
        />
    )

    const handleCustomerChange = (value) => {
        const foundCustomer = reduxProps?.customers?.find(candidate => candidate.id! === Number(value))

        form.setFieldsValue({
            shipTo: foundCustomer?.name,
        })
        setIsCustomerSelected(true)
    }

    const handleProductChange = (value) => {
        const foundProduct = reduxProps.products?.find(candidate => candidate.id == value)
        setSelectedProduct(foundProduct)
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || !saleDetail || saleDetail.length === 0 || !isCustomerSelected
    }

    const renderActionButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={props.onCancel}
        />
    )

    const handlePaymentChange = () => {
        const { customer, datetime, type } = form.getFieldsValue()

        if (!customer || !datetime || !type) {
            return
        }

        if (type === 1) {
            form.setFieldsValue({
                expirationDate: datetime,
            })
            return
        }

        form.setFieldsValue({
            expirationDate: datetime.add(1, 'days')
        })
    }

    const handleInputFocus = (event: any) => event.target.select();

    const renderForm = () => (
        <Form
            form={form}
            layout="horizontal"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={onFinish}
            disabled={props.readonly}
            onKeyPress={handleKeyDown}
        >
            <Row gutter={[16, 0]}>
                <Col9>
                    <Form.Item
                        label={descriptions.FORM.CUSTOMER.LABEL}
                        name={"customer"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.CUSTOMER.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps.customers?.map((customer: RoutingCustomer) => ({
                                value: `${customer.id}`,
                                label: customer.name,
                            }))}
                            placeholder={descriptions.FORM.CUSTOMER.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleCustomerChange}
                        />
                    </Form.Item>
                </Col9>
                <Col9>
                    <Form.Item
                        label={descriptions.FORM.SHIP_TO.LABEL}
                        name={"shipTo"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.SHIP_TO.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.SHIP_TO.PLACEHOLDER}
                            onFocus={handleInputFocus}
                        />
                    </Form.Item>
                </Col9>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.DATETIME.LABEL}
                        name={"datetime"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DATETIME.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                            onChange={handlePaymentChange}
                            disabledDate={(current) => {
                                let customDate = dayjs()
                                return current && current > customDate
                            }}
                            defaultValue={moment()}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.TYPE.LABEL}
                        name={"type"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.TYPE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: descriptions.FORM.TYPE_CASH,
                                },
                                {
                                    value: 2,
                                    label: descriptions.FORM.TYPE_CREDIT,
                                },
                            ]}
                            placeholder={descriptions.FORM.TYPE.PLACEHOLDER}
                            onChange={handlePaymentChange}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.EXPIRATION_DATE.LABEL}
                        name={"expirationDate"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.EXPIRATION_DATE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                        />
                    </Form.Item>
                </Col6>
                {
                    !props.readonly &&
                    <Col24>
                        <Form.Item
                            label={descriptions.FORM.PRODUCTS.LABEL}
                            name={"products"}
                        >
                            <Select
                                ref={!selectedProduct ? selectRef : undefined}
                                options={reduxProps.products?.map(product => ({
                                    value: `${product.id}`,
                                    label: `${product.name} - ${product.id}`,
                                    disabled: (product.stock ?? 0) <= 0,
                                }))}
                                placeholder={descriptions.FORM.PRODUCTS.PLACEHOLDER}
                                filterOption={selectFilterOption}
                                showSearch
                                optionFilterProp="children"
                                onChange={handleProductChange}
                            />
                        </Form.Item>
                    </Col24>
                }
            </Row>
            {renderPurchaseDetail()}
            <Row
                gutter={[16, 0]}
                style={{ marginTop: 16 }}
            >
                <Col12></Col12>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.SUBTOTAL}
                        name={"subtotal"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.DISSCOUNT}
                        name={"disscount"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"} />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.TOTAL}
                        name={"total"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
            </Row>
            {
                reduxProps.saleError &&
                <Alert
                    message={descriptions.FORM.ERRORS.TITLE}
                    showIcon
                    description={reduxProps.saleError?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {(!props.readonly || props.reCertification) && renderActionButtons()}
        </Form>
    )

    useEffect(() => {
        if (props.readonly) {
            return
        }

        let subtotal: number = 0
        let disscount: number = 0
        let total: number = 0

        saleDetail.forEach(detailItem => {
            subtotal += detailItem.subtotal
            disscount += detailItem.disscount
            total += detailItem.total
        })

        form.setFieldsValue({
            disscount: formatToCurrency(cleanCurrencyValue(disscount.toString()).toString()),
            subtotal: formatToCurrency(cleanCurrencyValue(subtotal.toString()).toString()),
            total: formatToCurrency(cleanCurrencyValue(total.toString()).toString()),
        })

        // TODO: INVESTIGATE WHY THIS HAPPENS
        form.setFieldValue("disscount", "0")
    }, [saleDetail])

    const handleAddProductAccept = (detailList: ProductTransactionDetail[]) => {
        setSelectedProduct(undefined)

        let newDetail: ProductTransactionDetail[] = [...saleDetail]

        detailList.forEach(detail => {
            const foundItem = saleDetail.find(candidate => candidate.ref === detail.ref)

            if (foundItem) {
                newDetail = newDetail.map(item => {
                    if (item.ref === detail.ref) {
                        item = { ...detail }
                    }
                    return item
                })
            } else {
                newDetail.push(detail)
            }

            setSaleDetail(parseDataKey(newDetail))
        })

        form.setFieldsValue({
            products: undefined,
        })

        // storeSessionData({
        //     saleDetail: newDetail,
        // })
    }

    const renderModals = () => (
        <ProductTransactionModal
            open={!!selectedProduct}
            product={selectedProduct}
            onAccept={handleAddProductAccept}
            onCancel={function (): void {
                setSelectedProduct(undefined)
                setSelectedDetail(undefined)
                form.setFieldsValue({
                    products: undefined,
                })
            }}
            detail={selectedDetail}
            type={ProductTransactionType.SALE}
        />
    )

    const renderInitalLoader = () => (

        <Skeleton active />
    )

    return (
        <>
            {
                (reduxProps.isGettingCustomers || reduxProps.isGettingProducts)
                    ? renderInitalLoader()
                    : renderForm()
            }
            {renderModals()}
        </>
    )
}
