
import { Colors } from 'src/common/styles/colors';
import styled, { css, keyframes } from 'styled-components';

export const Spinner = styled.div`
    position: relative;

    div & {
        transform: scale(0.8);
    }
`

export const keyBounce = keyframes` 
    0%, 80%, 100% {
        transform: scale(0.0);
    } 40% { 
        transform: scale(1.0);
    }
`

export const bounce = props =>
    css`
    ${keyBounce} 1.4s infinite ease-in-out both;
`
export interface BounceProps {
    backgroundColor?: string
}

export const Bounce1 = styled.div<{ backgroundColor?: string }>`
    width: 11px;
    height: 11px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.WHITE};

    border-radius: 100%;
    display: inline-block;

    animation-delay: -0.32s;
    animation: ${bounce};
`;

export const Bounce2 = styled(Bounce1)`
    animation-delay: -0.16s;
`
export const Bounce3 = styled(Bounce1)`
    animation-delay: -0.32s;
`
export const BounceContainer = styled.div``
export const LoaderContainer = styled.div`
    flex-direction: 'row';
    gap: 12px;
    display: flex;
`