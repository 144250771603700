import { Languages } from "src/common/descriptions/types";
import { PurchaseRecordsDescriptions } from "./types";

const DescriptionsEnUs: PurchaseRecordsDescriptions = {
    TITLE: "Purchase Records",
    TABLE: {
        ACTION_BUTTON: "Get Records",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        SUPPLIER: "Supplier",
        DOCUMENT: "Document",
        TOTAL: "Total",
        OUTSTANDING: "Outstanding",
        EXPIRATION: "Expiration",
        ELLAPSED: "Ellapsed",
        STATUS: "Status",
        ACTIONS: "Actions",
    },
    ACTIONS: {
        VIEW_DETAIL: "View Detail",
        PURCHASE_PAYMENT_RECORDS: "Payment Records",
    },
    STATUS_PENDING: "Pending",
    STATUS_PAYED: "Payed",
}

const DescriptionsEsUs: PurchaseRecordsDescriptions = {
    TITLE: "Registro de Ventas",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        SUPPLIER: "Proveedor",
        DOCUMENT: "Documento",
        TOTAL: "Total",
        OUTSTANDING: "Saldo",
        EXPIRATION: "Vencimiento",
        ELLAPSED: "Transcurrido",
        STATUS: "Estado",
        ACTIONS: "Acciones",
    },
    ACTIONS: {
        VIEW_DETAIL: "Ver Detalle",
        PURCHASE_PAYMENT_RECORDS: "Historial de pagos",
    },
    STATUS_PENDING: "Pendiente",
    STATUS_PAYED: "Pagada",
}

export const purchaseRecordsDictionary = {}
purchaseRecordsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
purchaseRecordsDictionary[Languages.SPANISH_US] = DescriptionsEsUs