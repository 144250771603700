import { Action } from 'redux'
import * as Actions from './actionTypes'
import { User } from 'src/common/models/user'

export interface getUsersAction extends Action {
}

export interface getUsersSuccessAction extends Action {
    users: User[]
}

export interface ErrorAction extends Action {
    error?
}

export const getUsersApi = (): getUsersAction => ({
    type: Actions.GET_USERS_API
})

export const getUsersSuccess = (users: User[]): getUsersSuccessAction => ({
    type: Actions.GET_USERS_SUCCESS,
    users
})

export const getUsersError = (error?): ErrorAction => ({
    type: Actions.GET_USERS_ERROR,
    error,
})

export interface createUserAction extends Action {
    user: User
}

export interface createUserSuccessAction extends Action {
}

export const createUserApi = (user: User): createUserAction => ({
    type: Actions.CREATE_USER_API,
    user
})

export const createUserSuccess = (): createUserSuccessAction => ({
    type: Actions.GET_USERS_SUCCESS
})

export const createUserError = (error?): ErrorAction => ({
    type: Actions.CREATE_USER_ERROR,
    error,
})
