import { Card, Empty, Tooltip, Typography } from "antd"
import { formatToCurrency } from "src/common/util"
import { ChartData } from "../types"
import { ChartComponent } from "./ChartComponent"
import { InfoCircleOutlined, } from '@ant-design/icons';
import * as Styles from './styles'
import React from "react";

interface Props {
    params?: ChartData
    isLoading: boolean
    loadMode?: boolean
    tooltipContent?: React.ReactNode
}

export const LineChart = (props: Props) => {

    const { Text } = Typography;

    const renderExtraContent = () => {
        return <Text strong>
            {formatToCurrency(props.params?.summaryAmount?.toString() || '0')}
        </Text>
    }

    return (
        <Card
            title={
                <Styles.SummaryContainer>
                    {props.params?.title || ''}
                    {
                        props.tooltipContent &&
                        <Tooltip
                            placement="bottomLeft" title={props.tooltipContent}>
                            <InfoCircleOutlined rev={undefined} />
                        </Tooltip>
                    }
                </Styles.SummaryContainer>
            }
            extra={renderExtraContent()}
            loading={props.isLoading || props.loadMode}
        >
            {
                (props.params && props.params?.data.some(dataItem => dataItem.data.length > 0))
                    ? <ChartComponent
                        params={props.params}
                    />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </Card >
    )
}
