import { Provider } from "react-redux"
import AppRouter from "./router/appRouter"
import store from "./state/store"
import { ConfigProvider } from "antd"
import { DefaultSettings, Languages } from "./common/descriptions/types"
import esES from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";

function App() {
	return (
		<Provider store={store}>
			<ConfigProvider locale={DefaultSettings.SYSTEM_LANG === Languages.ENGLISH_US ? enUS : esES}>
				<AppRouter />
			</ConfigProvider>
		</Provider>
	)
}

export default App
