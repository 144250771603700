import { Tabs } from "antd";
import { PageCard } from "src/common/styles/styles"
import { SystemSettings } from "../SystemSettings";
import { SystemDescriptions } from "src/common/descriptions";

export const Settings = () => {

    const descriptions = SystemDescriptions.PAGES.SETTINGS

    const settingItems = [
        {
            label: descriptions.SYSTEM_SETTINGS.TITLE,
            key: "SYSTEM_SETTINGS",
            children: <SystemSettings />,
        }
    ]

    return (
        <PageCard size="small" title={""} >
            <Tabs
                defaultActiveKey="SYSTEM_SETTINGS"
                type="card"
                size={"small"}
                items={settingItems}
            />
        </PageCard>
    )
}
