import { Customer } from "src/common/models/customer";
import { ErrorAction, getCustomersSuccessAction } from "./action";
import { GET_CUSTOMERS_API, GET_CUSTOMERS_API_ERROR, GET_CUSTOMERS_API_SUCCESS } from "./actionTypes";
import { CustomerErrorOrigin, ICustomerError } from "./types";
import { parseDataKey } from "src/common/util";

export interface CustomerState {
    customers: Customer[]
    error: ICustomerError | undefined
}

const initialState: CustomerState = {
    error: undefined,
    customers: []
}

export default (state: CustomerState = initialState, action: any): CustomerState => {
    switch (action.type) {
        case GET_CUSTOMERS_API: {
            return {
                ...state,
                customers: [],
                error: undefined
            }
        }
        case GET_CUSTOMERS_API_SUCCESS: {
            const successAction = action as getCustomersSuccessAction;

            return {
                ...state,
                customers: parseDataKey(successAction.customers),
                error: undefined
            }
        }
        case GET_CUSTOMERS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                customers: [],
                error: {
                    type: CustomerErrorOrigin.GET_CUSTOMERS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};
