import Axios, { AxiosInstance } from 'axios'
import { SystemConstants } from 'src/common/constants'
import { getSessionData } from 'src/session/sessionStore'
import { Texts } from 'src/common/texts'
import { redirectToLogout } from 'src/features/logout/redirector'

export class NetworkingProvider {
    private static instance: NetworkingProvider

    private controlInterface
    private securityInterface
    private printDteInterface

    private constructor() {
        this.controlInterface = Axios.create({
            baseURL: `${SystemConstants.BACKEND_SERVER.CONTROL_POV}/api/v1`
        })
        this.controlInterface.interceptors.response.use(this.responseInterceptorResponse, this.responseInterceptorError)

        this.securityInterface = Axios.create({
            baseURL: `${SystemConstants.BACKEND_SERVER.CONTROL_POV}/api/v1`
        })

        this.printDteInterface = Axios.create({
            baseURL: `${SystemConstants.BACKEND_SERVER.PRINT_BASE_URL}`
        })
    }

    public static getInstance = () => {
        if (!NetworkingProvider.instance) {
            NetworkingProvider.instance = new NetworkingProvider()
        }
        return NetworkingProvider.instance
    }

    public getControlInterface = (): AxiosInstance => {
        const sessionData = getSessionData()
        if (sessionData.accessToken) {
            this.controlInterface.defaults.headers.common['Authorization'] = sessionData.accessToken
            return this.controlInterface
        } else {
            redirectToLogout()
            throw Texts.SECURITY.ERRORS.NO_ACCESS_TOKEN
        }
    }

    public getPrintDteInterface = (): AxiosInstance => {
        return this.printDteInterface
    }

    public getLoginInterface = (): AxiosInstance => {
        return this.securityInterface
    }

    public getSecurityInterface = (): AxiosInstance => {
        delete this.securityInterface.defaults.headers.common['Authorization']
        return this.securityInterface
    }

    private responseInterceptorResponse = (response) => {
        return response
    }

    private responseInterceptorError = async (error) => {
        if (error.response.status === 401) {
            try {
                // TODO: REFRESH TOKEN
                redirectToLogout()
            } catch (err) {
                redirectToLogout()
                throw err
            }
        } else {
            throw error
        }
    }
}