import { SystemConstants } from 'src/common/constants';

export type ApiLoaderState = {
    [id: string]: any
}

const defaultState: ApiLoaderState = {};

export const API_REGEXP = new RegExp(`(.*)(${SystemConstants.API_LOADER.TRIGGER_SUFFIX}|${SystemConstants.API_LOADER.SUCCESS_SUFFIX}|${SystemConstants.API_LOADER.ERROR_SUFFIX})`);

export default (state: ApiLoaderState = defaultState, action): ApiLoaderState => {
    const actionType = action.type;
    const matches = API_REGEXP.exec(actionType);
    if (!matches) {
        return state;
    }
    const [, requestName, requestState] = matches;
    if (requestState === SystemConstants.API_LOADER.ERROR_SUFFIX || requestState === SystemConstants.API_LOADER.SUCCESS_SUFFIX) {
        delete state[requestName];
        return state;
    } else {
        return {
            ...state,
            [requestName]: true,
        };
    }
};