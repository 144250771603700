import * as React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Urls } from "src/common/urls"
import { Login } from "src/features/Login"
import { Logout } from "src/features/logout"
import { PrivateRoute } from "./PrivateRoute"
import { AdminRouter } from "./adminRouter"

const AppRouter: React.FunctionComponent = () => {

	return <BrowserRouter>
		<Routes>
			<Route path={Urls.FRONTEND.LOGOUT} element={<Logout />} />
			<Route path={Urls.FRONTEND.LOGIN} element={<Login />} />
			<Route
				path={'/*'}
				element={
					<PrivateRoute>
						<AdminRouter />
					</PrivateRoute>
				} />
		</Routes>
	</BrowserRouter>
}

export default AppRouter
