import { SystemConstants } from "src/common/constants";

const CREATE_ROUTING_SALE = 'CREATE_ROUTING_SALE';
export const CREATE_ROUTING_SALE_API = CREATE_ROUTING_SALE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_ROUTING_SALE_API_SUCCESS = CREATE_ROUTING_SALE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_ROUTING_SALE_API_ERROR = CREATE_ROUTING_SALE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_ROUTING_CUSTOMERS = 'GET_ROUTING_CUSTOMERS';
export const GET_ROUTING_CUSTOMERS_API = GET_ROUTING_CUSTOMERS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_ROUTING_CUSTOMERS_API_SUCCESS = GET_ROUTING_CUSTOMERS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_ROUTING_CUSTOMERS_API_ERROR = GET_ROUTING_CUSTOMERS + SystemConstants.API_LOADER.ERROR_SUFFIX;