import { Button } from "antd";
import styled from "styled-components";
import { ActionButtonType, DictionaryOfBG, DictionaryOfColor } from "./types";

export const ActionButton = styled(Button) <{ actionButtonType?: ActionButtonType }>`
    background-color: ${(props) => DictionaryOfBG[props.actionButtonType ?? ActionButtonType.PRIMARY]};
    color: ${(props) => DictionaryOfColor[props.actionButtonType ?? ActionButtonType.PRIMARY]};

    &:not(:disabled):hover {
        color: ${(props) => DictionaryOfColor[props.actionButtonType ?? ActionButtonType.PRIMARY]} !important;
        font-weight: bold !important;
        background-color: ${(props) => DictionaryOfBG[props.actionButtonType ?? ActionButtonType.PRIMARY]} !important;
    }
    
    &:disabled {
    }
`