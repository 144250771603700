import { Action } from 'redux'
import * as Actions from './actionTypes'
import { InvoiceReceiver, ReceiverIdType } from 'src/common/models/dte'

export interface GetInvoiceReceiverByIdAction extends Action {
    id: string
}
export interface GetInvoiceReceiverByIdSuccessAction extends Action {
    invoiceReceiver: InvoiceReceiver
    idType: ReceiverIdType
}
export interface ErrorAction extends Action {
    error?
}
export const getInvoiceReceiverByIdApi = (id: string): GetInvoiceReceiverByIdAction => ({
    type: Actions.GET_INVOICE_RECEIVER_BY_ID_API,
    id,
})
export const getInvoiceReceiverByIdSuccess = (invoiceReceiver: InvoiceReceiver, idType: ReceiverIdType): GetInvoiceReceiverByIdSuccessAction => ({
    type: Actions.GET_INVOICE_RECEIVER_BY_ID_API_SUCCESS,
    invoiceReceiver,
    idType,
})
export const getInvoiceReceiverByIdError = (error?): ErrorAction => ({
    type: Actions.GET_INVOICE_RECEIVER_BY_ID_API_ERROR,
    error,
})

export interface GetDtePdfAction extends Action {
    authId: string
}
export interface GetDtePdfSuccessAction extends Action {
    response: any
}
export const getDtePdfApi = (authId: string): GetDtePdfAction => ({
    type: Actions.GET_DTE_PDF_API,
    authId,
})
export const getDtePdfSuccess = (response: any): GetDtePdfSuccessAction => ({
    type: Actions.GET_DTE_PDF_API_SUCCESS,
    response,
})
export const getDtePdfError = (error?): ErrorAction => ({
    type: Actions.GET_DTE_PDF_API_ERROR,
    error,
})

export const clearInvoiceReceiver = (): Action => ({
    type: Actions.CLEAR_INVOICE_RECEIVER,
})