import { ApiError } from "src/features/Security/networking/types";

export enum ReportErrorOrigin {
    GET_KARDEX_REPORT = 'GET_KARDEX_REPORT',
    GET_UNITS_SOLD_REPORT = 'GET_UNITS_SOLD_REPORT',
}

export interface ReportError {
    type: ReportErrorOrigin,
    detail: ApiError
}

export interface ReportParams {
    dateFrom: string
    dateTo: string
}

export interface KardexParams extends ReportParams {
    productId: number
    type: string
}