import { Alert, Form, Input, Row, Select, Tabs, TabsProps } from "antd";
import { ProductTabIds } from "./types";
import { Col12, Col6 } from "src/components/Columns";
import { ProductBrand } from "src/common/models/productBrand";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/reducer";
import { useEffect, useState } from "react";
import { getProductSettings } from "src/features/ProductSettings/state/action";
import { ProductSubBrand } from "src/common/models/productSubBrand";
import { ProductCategory } from "src/common/models/productCategory";
import { Supplier } from "src/common/models/supplier";
import { getSuppliersApi } from "src/features/Supplier/state/action";
import { SystemDescriptions } from "src/common/descriptions";
import { PresentationsForm } from "./PresentationsForm";
import { Product } from "src/common/models/product";
import { ProductPresentation } from "src/common/models/productPresentation";
import { amountValidator } from "src/common/util";
import { FormActionButtons } from "src/components/FormActionButtons";
import { ProductType } from "src/components/ProductTransactionModal/types";
import { ProductError } from "../../state/types";

interface ReduxProps {
    brands?: ProductBrand[]
    subBrands?: ProductSubBrand[]
    categories?: ProductCategory[]
    suppliers?: Supplier[]
    error?: ProductError
}

interface Props {
    initialValues?: Product
    onFinish: (product: Partial<Product>) => void
    onCancel: () => void
}

export const CreateProductForm = (props: Props) => {
    const dispatch = useDispatch()

    const sortArrayByProperty = (array: any[], propertyName: string) => array.sort((a, b) => a[propertyName].localeCompare(b[propertyName]));

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        brands: sortArrayByProperty(state.productSettings.brands ?? [], "name"),
        subBrands: sortArrayByProperty(state.productSettings.subBrands ?? [], "name"),
        categories: sortArrayByProperty(state.productSettings.categories ?? [], "name"),
        suppliers: sortArrayByProperty(state.supplier.suppliers ?? [], "name"),
        currentProduct: state.product.currentProduct,
        error: state.product.error,
    }))

    const [selectedBrand, setSelectedBrand] = useState<ProductBrand | undefined>()
    const [productToCreate, setProductToCreate] = useState<Partial<Product> | undefined>()

    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getProductSettings())
        dispatch(getSuppliersApi())
    }, [])

    useEffect(() => {
        if (!props.initialValues) {
            return
        }
        const currentProduct = props.initialValues

        form.setFieldsValue({
            description: currentProduct.name,
            brand: currentProduct.productBrandId,
            subBrand: currentProduct.subBrandId,
            category: currentProduct.categoryId,
            supplier: currentProduct.providerId,
            status: currentProduct.statusId,
            volume: currentProduct.volume,
        })

        // Get the current fields' values and errors
        const fields = form.getFieldsValue(true);

        // Mark fields as touched
        Object.keys(fields).forEach(field => {
            form.setFields([{
                name: field,
                touched: true,
            }]);
        });


        setProductToCreate({
            coste: currentProduct.coste,
            presentations: currentProduct.presentations,
        })
    }, [props.initialValues])


    const descriptions = SystemDescriptions.PAGES.PRODUCT.CREATE_PRODUCT
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const handleBrandChange = (value) => {
        form.setFieldsValue({
            subBrand: undefined,
        })
        const brand = reduxProps?.brands?.find(candidate => candidate.id === value)
        setSelectedBrand(brand)
    }

    const buildRequiredMessageErr = (fieldName: string): string => descriptions.ERRORS.REQUIRED_FIELD(fieldName)

    const handleCancelClick = () => {
        props.onCancel()
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || !productToCreate || productToCreate?.presentations?.length === 0
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={handleCancelClick}
        />
    )

    const onFinish = (formValues) => {
        const product: Partial<Product> = {
            name: formValues.description,
            coste: productToCreate?.coste,
            productTypeId: 1,
            meassureId: 1,
            statusId: formValues.status,
            categoryId: formValues.category,
            providerId: formValues.supplier,
            barcode: "",
            saleType: 1,
            urlPath: "",
            lastCoste: 0,
            costeCPP: 0,
            volume: parseFloat(formValues.volume),
            comment: "",
            productBrandId: formValues.brand,
            subBrandId: formValues.subBrand,

            presentations: productToCreate?.presentations,
            id: props.initialValues?.id,
            productType: ProductType.GOOD,
        }

        props.onFinish(product)
    }

    const handleFieldsChange = () => {
        console.log("this?")
    }

    const renderProductData = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            onFinish={onFinish}
            onFieldsChange={handleFieldsChange}
        >
            <Row gutter={[16, 8]}>
                <Col12>
                    <Form.Item
                        label={descriptions.FORM.DESCRIPTION.LABEL}
                        name={"description"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.DESCRIPTION.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.DESCRIPTION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col12>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.BRAND.LABEL}
                        name={"brand"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.BRAND.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            onChange={handleBrandChange}
                            options={reduxProps?.brands?.map(brand => ({
                                value: brand.id,
                                label: brand.name,
                            }))}
                            placeholder={descriptions.FORM.BRAND.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.SUBBRAND.LABEL}
                        name={"subBrand"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.SUBBRAND.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps?.subBrands?.filter(subBrand => subBrand.brandId === selectedBrand?.id || subBrand.id === 1)
                                .map(subBrand => ({
                                    value: subBrand.id,
                                    label: subBrand.name,
                                }))}
                            placeholder={descriptions.FORM.SUBBRAND.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.CATEGORY.LABEL}
                        name={"category"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.CATEGORY.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps?.categories?.map(category => ({
                                value: category.id,
                                label: category.name,
                            }))}
                            placeholder={descriptions.FORM.CATEGORY.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.SUIPPLIER.LABEL}
                        name={"supplier"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.SUIPPLIER.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps?.suppliers?.map(supplier => ({
                                value: supplier.id,
                                label: supplier.name,
                            }))}
                            placeholder={descriptions.FORM.SUIPPLIER.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.STATUS.LABEL}
                        name={"status"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.STATUS.LABEL)
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: commonDescriptions.ACTIVE_STATUS
                                },
                                {
                                    value: 2,
                                    label: commonDescriptions.INACTIVE_STATUS,
                                },
                            ]}
                            placeholder={descriptions.FORM.STATUS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.VOLUME.LABEL}
                        name={"volume"}
                        rules={[
                            {
                                required: true,
                                message: buildRequiredMessageErr(descriptions.FORM.VOLUME.LABEL)
                            },
                            {
                                validator: (_, value) => {
                                    return amountValidator(value)
                                }
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.VOLUME.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
            </Row>
            {
                reduxProps.error &&
                <Alert
                    message={"ERROR"}
                    showIcon
                    description={reduxProps.error?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {renderButtons()}
        </Form>
    )

    const onFinishPresentations = (coste: number, presentations: Partial<ProductPresentation>[]) => {
        setProductToCreate({
            coste,
            presentations: presentations as ProductPresentation[],
        })
    }

    const items: TabsProps['items'] = [
        {
            key: ProductTabIds.PRODUCT_DATA,
            label: descriptions.TAB_DATA,
            children: renderProductData(),
        },
        {
            key: ProductTabIds.PRESENTATIONS,
            label: descriptions.TAB_PRESENTATIONS,
            children: <PresentationsForm
                onFinish={onFinishPresentations}
            />
        },
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" items={items} />
        </>
    )
}
