import { Languages } from "src/common/descriptions/types";
import { ProductKardexDescriptions } from "./types";

const DescriptionsEnUs: ProductKardexDescriptions = {
    TITLE: "Product Kardex Report",
    TABLE: {
        ACTION_BUTTON: "Generate Report",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        PRODUCT_ID: "Product Id",
        MEASSURE: "Meassure",
        DESCRIPTION: "Description",
        DOCUMENT: "Document",
        PREVIOUS: "Previous",
        INCOME: "Income",
        OUTCOME: "Outcome",
        BALANCE: "Balance",
        TOTAL: "Total",
        COSTE: "Coste",
        UTILITY: "Utility",
    },
    ALL_PRODUCTS: "All Products",
    SELECT_PRODUCT: "Select product",
    EXPORT_DATA: "Export Data",
}

const DescriptionsEsUs: ProductKardexDescriptions = {
    TITLE: "Kardex de Productos",
    TABLE: {
        ACTION_BUTTON: "Generar Reporte",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        PRODUCT_ID: "Cod Producto",
        MEASSURE: "Medida",
        DESCRIPTION: "Descripción",
        DOCUMENT: "Documento",
        PREVIOUS: "Previo",
        INCOME: "Ingreso",
        OUTCOME: "Salida",
        BALANCE: "Saldo",
        TOTAL: "Total",
        COSTE: "Costo",
        UTILITY: "Utilidad",
    },
    ALL_PRODUCTS: "Todos",
    SELECT_PRODUCT: "Seleccione producto",
    EXPORT_DATA: "Exportar",
}

export const productKardexDictionary = {}
productKardexDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
productKardexDictionary[Languages.SPANISH_US] = DescriptionsEsUs