import { Action } from 'redux'
import * as Actions from './actionTypes';
import { User } from 'src/common/models/user';

export interface LoginAction extends Action {
    username: string;
    password: string;
}

export interface LoginSuccessAction extends Action {
    user: User;
    accessToken?: string
}

export interface ErrorAction extends Action {
    error?
}

export interface LogoutAction extends Action { }

export const login = (username: string, password: string): LoginAction => ({
    type: Actions.LOGIN_API,
    username,
    password
});

export const loginSuccess = (user: User): LoginSuccessAction => ({
    type: Actions.LOGIN_SUCCESS,
    user,
})

export const loginError = (error): ErrorAction => ({
    type: Actions.LOGIN_ERROR,
    error,
});

export const logout = (): LogoutAction => ({
    type: Actions.LOGOUT_API
});