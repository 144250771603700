import * as XLSX from 'xlsx';
import moment from "moment"
import { DEFAULTS, ExporterParams } from './types';

export class XlsExporter {
    private sheetNamePrefix: string
    private documentName: string

    public constructor(params: ExporterParams) {
        if (params.sheetPrefixName) {
            this.sheetNamePrefix = params.sheetPrefixName
        } else {
            this.sheetNamePrefix = DEFAULTS.SHEET_NAME_PREFIX
        }

        if (params.documentName) {
            this.documentName = this.buildFileName(params.documentName, params.appendTimestamp)
        } else {
            this.documentName = this.buildFileName(DEFAULTS.DOCUMENT_NAME, params.appendTimestamp)
        }
    }

    private buildFileName = (name: string, addTimestamp?: boolean): string => {
        return addTimestamp
            ? `${name}_${moment().unix()}.xls`
            : `${name}.xls`
    }

    public exportData = (reportData: any[]): void => {
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(reportData);
        XLSX.utils.book_append_sheet(workbook, worksheet, this.sheetNamePrefix);

        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xls' });
        const dataUrl = window.URL.createObjectURL(
            new Blob([excelBuffer], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', this.documentName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}