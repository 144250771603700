import { SystemConstants } from "src/common/constants";

const GET_INVOICE_RECEIVER_BY_ID = 'GET_INVOICE_RECEIVER_BY_ID';
export const GET_INVOICE_RECEIVER_BY_ID_API = GET_INVOICE_RECEIVER_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_INVOICE_RECEIVER_BY_ID_API_SUCCESS = GET_INVOICE_RECEIVER_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_INVOICE_RECEIVER_BY_ID_API_ERROR = GET_INVOICE_RECEIVER_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_DTE_PDF = 'GET_DTE_PDF';
export const GET_DTE_PDF_API = GET_DTE_PDF + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_DTE_PDF_API_SUCCESS = GET_DTE_PDF + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_DTE_PDF_API_ERROR = GET_DTE_PDF + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAR_INVOICE_RECEIVER = "CLEAR_INVOICE_RECIEVER";