import { Form } from "antd";
import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  gap: 12px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonFormItem = styled(Form.Item)`
	padding: 0;
	margin: 0;
`