import { ApiError } from "src/features/Security/networking/types";

export enum ProductErrorOrigin {
    CREATE_PRODUCT = 'CREATE_PRODUCT',
    GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID',
    GET_PRODUCTS = 'GET_PRODUCTS',
    GET_PRODUCTS_BY_BATCH = 'GET_PRODUCTS_BY_BATCH',
    UPDATE_PRODUCT = 'UPDATE_PRODUCT',
    GET_WAREHOUSE_STOCK = 'GET_WAREHOUSE_STOCK',
}

export interface ProductError {
    type: ProductErrorOrigin,
    detail: ApiError
}