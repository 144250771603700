import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { cleanPurchasePaymentRecordsApi, getPurchaseByIdApi, getPurchasePaymentRecordsApi, getPurchaseRecordsApi } from "../state/action"
import { Purchase } from "src/common/models/purchase"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_PURCHASE_PAYMENT_API, GET_PURCHASE_BY_ID_API, GET_PURCHASE_PAYMENTS_API, GET_PURCHASE_RECORDS_API } from "../state/actionTypes"
import { DownOutlined } from '@ant-design/icons'
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { Button, Dropdown, MenuProps } from "antd"
import { PurchaseDetailModal } from "../PurchaseRecords/PurchaseDetailModal"
import { Loader } from "src/components/Loader"
import { PaymentModal } from "src/components/PaymentModal"
import { ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { PaymentTransaction } from "src/components/PaymentModal/PaymentForm/types"
import { SystemDescriptions } from "src/common/descriptions"
import { User } from "src/common/models/user"
import * as XLSX from 'xlsx';
import moment from "moment"
import { formatToDisplayDate } from "src/common/util"
import { PurchasePaymentRecords } from "../PurchaseRecords/PurchasePaymentRecords"
import { PurchasePayment } from "src/common/models/purchasePayment"

interface ReduxProps {
    isLoadingData: boolean
    accountPayables?: Purchase[]
    isGettingPurchase: boolean
    currentPurchase?: Purchase
    isCreatingPurchasePayment: boolean
    createPurchasePaymentSuccess: boolean
    user?: User
    isGettingPurchasePayments: boolean
    purchasePayments?: PurchasePayment[]
}

export const AccountPayables = () => {
    const dispatch = useDispatch()

    const [selectedPurchase, setSelectedPurchase] = useState<Purchase | undefined>(undefined)
    const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false)
    const [paymentTransaction, setPaymentTransaction] = useState<PaymentTransaction | undefined>(undefined)
    const [detailButtonClicked, setDetailButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PURCHASE_RECORDS_API]),
        accountPayables: state.purchase.purchases,
        isGettingPurchase: serviceLoading(state, [GET_PURCHASE_BY_ID_API]),
        currentPurchase: state.purchase.currentPurchase,
        isCreatingPurchasePayment: serviceLoading(state, [CREATE_PURCHASE_PAYMENT_API]),
        createPurchasePaymentSuccess: state.purchase.createPurchasePaymentSuccess,
        user: state.security.user,
        isGettingPurchasePayments: serviceLoading(state, [GET_PURCHASE_PAYMENTS_API]),
        purchasePayments: state.purchase.purchasePayments,
    }))

    const descriptions = SystemDescriptions.PAGES.PURCHASE.ACCOUNT_PAYABLES

    useEffect(() => {
        dispatch(getPurchaseRecordsApi({
            paymentStatusId: "PENDING",
        }))
    }, [])

    useEffect(() => {
        if (reduxProps.currentPurchase && detailButtonClicked) {
            setSelectedPurchase(reduxProps.currentPurchase)
        }
    }, [reduxProps.currentPurchase])

    useEffect(() => {
        dispatch(getPurchaseRecordsApi({
            paymentStatusId: "PENDING",
        }))
    }, [reduxProps.createPurchasePaymentSuccess])


    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Purchase) => {
                const handleDetailClick = (purchaseId: number) => {
                    setDetailButtonClicked(true)
                    dispatch(getPurchaseByIdApi(purchaseId))
                }

                const handleCreatePaymentClick = (purchaseId: number) => {
                    const paymentTransaction: PaymentTransaction = {
                        targetId: record.id!,
                        type: "PURCHASE",
                        amount: record.amount,
                        amountPayed: record.amountPayed,
                        currentBalance: record.amountPending,
                        target: record.supplier || '',
                        targetDocument: record.document,
                        targetExternalId: record.supplierId,
                        targetExternalName: record.supplier || '',
                        username: reduxProps?.user?.username || '',
                        userId: 1,
                    }
                    setPaymentTransaction(paymentTransaction)
                    setPaymentModalOpen(true)
                }

                const handleViewPaymentRecordsClick = (purchaseId: number) => {
                    dispatch(getPurchasePaymentRecordsApi({
                        purchaseId,
                    }))
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleCreatePaymentClick(record.id!)}
                            >
                                {descriptions.ACTIONS.CREATE_PAYMENT}
                            </Button>
                        ),
                    },
                    {
                        key: '3',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleViewPaymentRecordsClick(record.id!)}
                                disabled={record.amount === record.amountPending}
                            >
                                {descriptions.ACTIONS.PAYMENT_RECORDS}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.accountPayables || []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    const buildReportData = () => {
        return reduxProps?.accountPayables?.map((reportItem: Purchase) => {
            return {
                ID: reportItem.key,
                DATETIME: formatToDisplayDate(reportItem.datetime),
                SUPPLIER: reportItem.supplier,
                DOCUMENT: reportItem.document,
                TOTAL: reportItem.amount,
                PAYED: reportItem.amountPayed,
                OUTSTANDING: reportItem.amountPending,
                EXPIRATION: formatToDisplayDate(reportItem.expirationDate),
            }
        }) ?? []
    }

    const handleExport = () => {
        if (!reduxProps.accountPayables) {
            return
        }

        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(buildReportData());
        XLSX.utils.book_append_sheet(workbook, worksheet, "WarehouseStock");

        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xls' });
        const dataUrl = window.URL.createObjectURL(
            new Blob([excelBuffer], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', `PAYABLES_${moment().unix()}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
                onClick={handleExport}
            >
                {descriptions.EXPORT}
            </Button>
        </div>
    }

    return (
        <>
            {renderContent()}
            {
                selectedPurchase &&
                <PurchaseDetailModal
                    purchase={selectedPurchase}
                    open={!!selectedPurchase}
                    onAccept={() => { setSelectedPurchase(undefined) }}
                    onCancel={() => { setSelectedPurchase(undefined) }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingPurchase || reduxProps.isGettingPurchasePayments}
            />
            {
                paymentTransaction &&
                <PaymentModal
                    open={paymentModalOpen}
                    type={ProductTransactionType.PURCHASE}
                    onAccept={() => { setPaymentModalOpen(false) }}
                    onCancel={() => { setPaymentModalOpen(false) }}
                    data={paymentTransaction}
                />
            }
            <PurchasePaymentRecords
                purchasePayments={reduxProps.purchasePayments || []}
                open={!!reduxProps.purchasePayments}
                onAccept={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
            />
        </>
    )
}
