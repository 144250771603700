import { Languages } from "../../types";
import { FooterDescriptions } from "./types";

const DescriptionsEnUs: FooterDescriptions = {
    COPYRIGHT: "Copyright © 2024 Control Mayor. All rights reserved",
    VERSION: "Version 1.0.1",
}

const DescriptionsEsUs: FooterDescriptions = {
    COPYRIGHT: "Copyright © 2024 Control Mayor. Derechos reservados",
    VERSION: "Version 1.0.1",
}

const footerDictionary = {}
footerDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
footerDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getFooterDescriptions = (systemLang: Languages): FooterDescriptions => (
    footerDictionary[systemLang]
)