import { Languages } from "../../types";
import { productKardexDictionary } from "./ProductKardex";
import { salesReportDictionary } from "./SalesReport";
import { ReportsPagesDescriptions } from "./types";
import { unitsSoldDictionary } from "./UnitsSold";

export const getReportsDescriptions = (systemLang: Languages): ReportsPagesDescriptions => ({
    PRODUCT_KARDEX: productKardexDictionary[systemLang],
    UNITS_SOLD: unitsSoldDictionary[systemLang],
    SALES_REPORT: salesReportDictionary[systemLang],
})