import { ApiError } from "src/features/Security/networking/types";

export enum DteErrorOrigin {
    GET_INVOICE_RECEIVER_BY_ID = 'GET_INVOICE_RECEIVER_BY_ID',
    GET_DTE_PDF = 'GET_DTE_PDF',
}

export interface DteError {
    type: DteErrorOrigin,
    detail: ApiError
}