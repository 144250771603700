import { Modal } from "antd"
import { CreatePurchaseForm } from "../../CreatePurchase/CreatePurchaseForm"
import { Purchase } from "src/common/models/purchase"

interface Props {
    purchase: Purchase
    open: boolean
    onAccept: () => void
    onCancel: () => void
}

export const PurchaseDetailModal = (props: Props) => {
    return (
        <Modal
            title={"Detail"}
            centered
            open={props.open}
            onOk={props.onAccept}
            onCancel={props.onCancel}
            width={1000}
            footer={null}
            closable={true}
        >
            <CreatePurchaseForm
                onFinish={() => { }}
                onCancel={() => { }}
                initialValues={props.purchase}
                readonly
            />
        </Modal>
    )
}
