import { Alert, Button, DatePicker, Form, Input, Row, Select, Space, Tooltip } from "antd";
import { Col12, Col24, Col4, Col6 } from "src/components/Columns";
import { SearchableTable } from "src/components/SearchableTable";
import { ColumnsKeys, summaryColumns, tableColumns } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliersApi } from "src/features/Supplier/state/action";
import { useEffect, useRef, useState } from "react";
import { RootState } from "src/state/reducer";
import { Supplier } from "src/common/models/supplier";
import { addTimeToDateMoment, cleanCurrencyValue, formatToCurrency, getInputRequiredRule, parseDataKey, selectFilterOption } from "src/common/util";
import { SystemConstants } from "src/common/constants";
import { Product } from "src/common/models/product";
import { getProductsApi } from "src/features/Product/state/action";
import { SystemDescriptions } from "src/common/descriptions";
import { Purchase } from "src/common/models/purchase";
import { searchableTableUtils } from "src/components/SearchableTable/utils";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormActionButtons } from "src/components/FormActionButtons";
import { CurrencyInput } from "src/components/CurrencyInput";
import { ProductTransactionDetail, ProductTransactionType, ProductType } from "src/components/ProductTransactionModal/types";
import { ProductTransactionModal } from "src/components/ProductTransactionModal";
import { getSessionData, storeSessionData } from "src/session/sessionStore";
import { PurchaseError } from "../../state/types";

interface ReduxProps {
    suppliers?: Supplier[]
    products?: Product[]
    purchaseError?: PurchaseError
}

interface Props {
    onFinish: (purchase: Purchase) => void
    onCancel: () => void
    initialValues?: any // TODO: FIX WHEN EDIT PURCHASE
    readonly?: boolean

}

export const CreatePurchaseForm = (props: Props) => {
    const dispatch = useDispatch()

    const selectRef = useRef<any>(null);

    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
    const [purchaseDetail, setPurchaseDetail] = useState<ProductTransactionDetail[]>([])
    const [selectedDetail, setSelectedDetail] = useState<ProductTransactionDetail | undefined>(undefined)

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.shiftKey && event.key === 'Enter') {
                handleFocus()
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        if (!props.readonly) {
            dispatch(getSuppliersApi())
            dispatch(getProductsApi({
                status: 'ACTIVE',
            }))
        }

        if (props.initialValues) {
            const purchase: Purchase = { ...props.initialValues }

            const detail: ProductTransactionDetail[] = purchase.detail.map(detailItem => ({
                productId: detailItem.productId,
                product: detailItem.description ?? '',
                meassureId: detailItem.meassureId,
                meassure: detailItem.meassure,
                meassureSymbol: detailItem.meassure,
                qty: detailItem.qty,
                unitPrice: detailItem.unitPrice,
                subtotal: detailItem.total,
                disscount: 0,
                total: detailItem.total,

                productType: ProductType.GOOD,
            }))
            setPurchaseDetail(detail)

            // form.setFieldsValue({
            //     customer: sale.customerId,
            //     shipTo: sale.shipTo,
            //     document: sale.document,
            //     datetime: dayjs(sale.datetime),
            //     type: sale.paymentType,
            //     expirationDate: dayjs(sale.expirationDate),
            //     paymentType: sale.paymentType,
            //     subtotal: formatToCurrency(sale.amount.toString()).toString(),
            //     disscount: formatToCurrency(sale.disscount.toString()),
            //     total: formatToCurrency(sale.amount.toString()),
            //     invoiceReceiverId: sale.invoiceReceiverId,
            // })
        } else {
            const purchaseDetail = getSessionData().purchaseDetail

            if (purchaseDetail) {
                setPurchaseDetail(purchaseDetail)
            }
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [])

    useEffect(() => {
        if (props.readonly) {
            return
        }

        let subtotal: number = 0
        let disscount: number = 0
        let total: number = 0

        purchaseDetail.forEach(detailItem => {
            subtotal += detailItem.subtotal
            disscount += detailItem.disscount
            total += detailItem.total
        })

        form.setFieldsValue({
            disscount: formatToCurrency(cleanCurrencyValue(disscount.toString()).toString()),
            subtotal: formatToCurrency(cleanCurrencyValue(subtotal.toString()).toString()),
            total: formatToCurrency(cleanCurrencyValue(total.toString()).toString()),
        })
    }, [purchaseDetail])

    const handleFocus = () => {
        if (selectRef.current) {
            selectRef.current.focus();
        }
    };

    const handlePaymentChange = () => {
        const { supplier, datetime, type } = form.getFieldsValue()

        if (!supplier || !datetime || !type) {
            return
        }

        if (type === 1) {
            form.setFieldsValue({
                paymentDate: datetime,
            })
            return
        }

        const foundSupplier = reduxProps?.suppliers?.find(candidate => candidate.id === Number(supplier))
        if (!foundSupplier) {
            return
        }

        const creditDays: number = foundSupplier.creditDays ?? 0
        form.setFieldsValue({
            paymentDate: datetime.add(creditDays, 'days')
        })
    }

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        suppliers: state.supplier.suppliers,
        products: state.product.products,
        purchaseError: state.purchase.error,
    }))

    const [form] = Form.useForm();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const descriptions = SystemDescriptions.PAGES.PURCHASE.CREATE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const tblColumns = [
        ...tableColumns,
        {
            title: descriptions.FORM.DETAIL.COLUMNS.ACTIONS,
            dataIndex: ColumnsKeys.ACTIONS,
            key: ColumnsKeys.ACTIONS,
            align: searchableTableUtils.alignment.centerAlign,
            hidden: props.readonly,
            render: (_, record: ProductTransactionDetail) => {
                const handleEditClick = (detailItem: ProductTransactionDetail) => {
                    const foundProduct = reduxProps?.products?.find(candidate => candidate.id === detailItem.productId)
                    setSelectedProduct(foundProduct)
                    setSelectedDetail(detailItem)
                }

                const handleDeleteClick = (ref: string) => {
                    const newDetail: ProductTransactionDetail[] = []
                    purchaseDetail.forEach(detail => {
                        if (detail.ref !== ref) {
                            newDetail.push(detail)
                        }
                    })
                    setPurchaseDetail(newDetail)
                    storeSessionData({
                        purchaseDetail: newDetail,
                    })
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.EDIT} >
                        <Button type="primary" ghost shape="circle"
                            icon={<EditOutlined rev={undefined} />}
                            onClick={() => handleEditClick(record)}
                        />
                    </Tooltip>
                    <Tooltip title={commonDescriptions.ACTIONS.DELETE} >
                        <Button type="primary" danger ghost shape="circle"
                            icon={<DeleteOutlined rev={undefined} />}
                            onClick={() => handleDeleteClick(record.ref!)}
                        />
                    </Tooltip>
                </Space>
            },
        },
    ]

    const renderPurchaseDetail = () => (
        <SearchableTable
            showLoader={false}
            items={purchaseDetail}
            tableColumns={tblColumns}
            summaryColumns={summaryColumns}
        />
    )

    const handleProductChange = (value) => {
        if (!value) {
            return
        }

        const foundProduct = reduxProps.products?.find(candidate => candidate.id == value)
        setSelectedProduct(foundProduct)
        setSelectedDetail(undefined)
    }

    const onFinish = (formValues) => {
        const purchase: Purchase = {
            document: formValues.document,
            datetime: addTimeToDateMoment(formValues.datetime), // todo this must be the date of the DTE
            amount: Number(cleanCurrencyValue(formValues.total)),
            disscount: formValues.disscount,
            expirationDate: addTimeToDateMoment(formValues.paymentDate),
            seller: "",
            type: formValues.type,
            paymentStatus: 1, // ALL PURCHASES ARE STORED AS PENDING
            supplierId: Number(formValues.supplier),
            paymentDate: "",
            paymentDocument: "",
            warehouseId: 1,
            amountPayed: 0,
            amountPending: Number(cleanCurrencyValue(formValues.total)),
            datetimeReceived: addTimeToDateMoment(formValues.datetime), // todo: change when input is added
            detail: purchaseDetail,
            regularization: formValues.regularization,
            isAccountable: formValues.isAccountable,
        }

        props.onFinish(purchase)
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || !purchaseDetail || purchaseDetail.length === 0
    }

    const renderActionButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={props.onCancel}
        />
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="horizontal"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={onFinish}
            disabled={props.readonly}
            onKeyPress={handleKeyDown}
        >
            <Row gutter={[16, 0]}>
                <Col12>
                    <Form.Item
                        label={descriptions.FORM.PROVIDER.LABEL}
                        name={"supplier"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.TYPE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps.suppliers?.map(supplier => ({
                                value: `${supplier.id}`,
                                label: supplier.name,
                            }))}
                            placeholder={descriptions.FORM.PROVIDER.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handlePaymentChange}
                        />
                    </Form.Item>
                </Col12>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.DOCUMENT.LABEL}
                        name={"document"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input placeholder={descriptions.FORM.DOCUMENT.PLACEHOLDER} />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.DATETIME.LABEL}
                        name={"datetime"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DATETIME.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                            onChange={handlePaymentChange}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.TYPE.LABEL}
                        name={"type"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.TYPE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: descriptions.FORM.TYPE_CASH,
                                },
                                {
                                    value: 2,
                                    label: descriptions.FORM.TYPE_CREDIT,
                                },
                            ]}
                            placeholder={descriptions.FORM.TYPE.PLACEHOLDER}
                            onChange={handlePaymentChange}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.PAYMENT_DATE.LABEL}
                        name={"paymentDate"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.PAYMENT_DATE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                            placeholder={descriptions.FORM.PAYMENT_DATE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.REGULARIZATION.LABEL}
                        name={"regularization"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.REGULARIZATION.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: commonDescriptions.YES,
                                },
                                {
                                    value: 2,
                                    label: commonDescriptions.NO,
                                },
                            ]}
                            placeholder={descriptions.FORM.REGULARIZATION.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.IS_ACCOUNTABLE.LABEL}
                        name={"isAccountable"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.IS_ACCOUNTABLE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: commonDescriptions.YES,
                                },
                                {
                                    value: 2,
                                    label: commonDescriptions.NO,
                                },
                            ]}
                            placeholder={descriptions.FORM.IS_ACCOUNTABLE.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                {
                    !props.readonly &&
                    <Col24>
                        <Form.Item
                            label={descriptions.FORM.PRODUCT.LABEL}
                            name={"products"}
                        >
                            <Select
                                ref={!selectedProduct ? selectRef : undefined}
                                options={reduxProps.products?.map(product => ({
                                    value: `${product.id}`,
                                    label: `${product.name} - ${product.id}`,
                                }))}
                                placeholder={descriptions.FORM.PRODUCT.PLACEHOLDER}
                                filterOption={selectFilterOption}
                                showSearch
                                optionFilterProp="children"
                                onChange={handleProductChange}
                            />
                        </Form.Item>
                    </Col24>
                }
            </Row>
            {renderPurchaseDetail()}
            <Row
                gutter={[16, 0]}
                style={{ marginTop: 16 }}
            >
                <Col12></Col12>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.SUBTOTAL}
                        name={"subtotal"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={descriptions.FORM.DOCUMENT.PLACEHOLDER}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.DISSCOUNT}
                        name={"disscount"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={descriptions.FORM.DOCUMENT.PLACEHOLDER}
                            readOnly />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.TOTAL}
                        name={"total"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={descriptions.FORM.DOCUMENT.PLACEHOLDER}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
            </Row>
            {
                reduxProps.purchaseError &&
                <Alert
                    message={descriptions.FORM.ERRORS.TITLE}
                    showIcon
                    description={reduxProps.purchaseError?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {!props.readonly && renderActionButtons()}
        </Form>
    )

    const handleAddProductAccept = (detailList: ProductTransactionDetail[]) => {
        setSelectedProduct(undefined)
        let newDetail: ProductTransactionDetail[] = [...purchaseDetail]

        detailList.forEach(detail => {
            const foundItem = purchaseDetail.find(candidate => candidate.ref === detail.ref)

            if (foundItem) {
                newDetail = newDetail.map(item => {
                    if (item.ref === detail.ref) {
                        item = { ...detail }
                    }
                    return item
                })
            } else {
                newDetail.push(detail)
            }

            setPurchaseDetail(parseDataKey(newDetail))

            storeSessionData({
                purchaseDetail: newDetail,
            })
        })

        let subtotal: number = 0
        let disscount: number = 0
        let total: number = 0

        newDetail.forEach(detailItem => {
            subtotal += detailItem.subtotal
            disscount += detailItem.disscount
            total += detailItem.total
        })

        form.setFieldsValue({
            subtotal: formatToCurrency(cleanCurrencyValue(subtotal.toString()).toString()),
            disscount: formatToCurrency(cleanCurrencyValue(disscount.toString()).toString()),
            total: formatToCurrency(cleanCurrencyValue(total.toString()).toString()),
            products: undefined,
        })
    }

    const renderModals = () => (
        <ProductTransactionModal
            open={!!selectedProduct}
            product={selectedProduct}
            onAccept={handleAddProductAccept}
            onCancel={function (): void {
                setSelectedProduct(undefined)
                setSelectedDetail(undefined)
                form.setFieldsValue({
                    products: undefined,
                })
            }}
            detail={selectedDetail}
            type={ProductTransactionType.PURCHASE}
        />
    )

    return (
        <>
            {renderForm()}
            {renderModals()}
        </>
    )
}
