import { SystemDescriptions } from 'src/common/descriptions'
import * as Styles from './styles'

export const Footer = () => {

    const descriptions = SystemDescriptions.COMPONENTS.FOOTER

    return (
        <Styles.AppFooter>
            {descriptions.COPYRIGHT}
        </Styles.AppFooter>
    )
}
