import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency, formatToDisplayDate } from "src/common/util";
import { SummaryType } from "src/components/SearchableTable/types";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.REPORTS.SALES_REPORT

export enum ColumnsKeysMonth {
    KEY = 'key',
    DATETIME = 'datetime',
    AMOUNT = 'amount',
    AMOUNT_WITH_ID = 'amountWitId',
    AMOUNT_WITHOUT_ID = 'amountWithoutId',
    SALE_QTY = 'saleQty',
}

export const summaryColumnsMonth: Partial<Record<ColumnsKeysMonth, SummaryType>> = {
    [ColumnsKeysMonth.AMOUNT]: SummaryType.SUM,
    [ColumnsKeysMonth.AMOUNT_WITH_ID]: SummaryType.SUM,
    [ColumnsKeysMonth.AMOUNT_WITHOUT_ID]: SummaryType.SUM,
}

export const TABLE_COLUMNS_MONTH: any = [
    {
        title: descriptions.TABLE_COLUMNS.MONTH.KEY,
        dataIndex: ColumnsKeysMonth.KEY,
        key: ColumnsKeysMonth.KEY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MONTH.DATE,
        dataIndex: ColumnsKeysMonth.DATETIME,
        key: ColumnsKeysMonth.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDate(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.MONTH.QTY,
        dataIndex: ColumnsKeysMonth.SALE_QTY,
        key: ColumnsKeysMonth.SALE_QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MONTH.AMOUNT,
        dataIndex: ColumnsKeysMonth.AMOUNT,
        key: ColumnsKeysMonth.AMOUNT,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.MONTH.AMOUNT_WITH_ID,
        dataIndex: ColumnsKeysMonth.AMOUNT_WITH_ID,
        key: ColumnsKeysMonth.AMOUNT_WITH_ID,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.MONTH.AMOUNT_WITHOUT_ID,
        dataIndex: ColumnsKeysMonth.AMOUNT_WITHOUT_ID,
        key: ColumnsKeysMonth.AMOUNT_WITHOUT_ID,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];