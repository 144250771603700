import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatToCurrency, formatToDisplayDate, formatToDisplayDatetime } from 'src/common/util';
import { SystemDescriptions } from 'src/common/descriptions';
import { Company } from 'src/common/models/company';
import dayjs from "dayjs"
import { SystemConstants } from 'src/common/constants';
import { ReportView } from 'src/features/Reports/types';
import { SalesReportDayView, SalesReportMonthView } from 'src/common/models/sale';

interface Props {
    data: any
    view: ReportView
    dateFrom: string
    dateTo: string
    company: Company
}

const SalesRecordsReport = (props: Props) => {

    const pdf = new jsPDF();

    const descriptions = SystemDescriptions.PAGES.REPORTS.SALES_REPORT
    const fontConstants = SystemConstants.REPORTS.FONT

    const buildDayDetail = () => {
        const data = props.data as SalesReportDayView[]

        let total = 0

        data.forEach(record => {
            total += record.amount
        })

        const itemDetailsRows = data?.map((item, index) => [
            (index + 1).toString(),
            formatToDisplayDatetime(item.datetime),
            item.invoiceReceiverId,
            item.invoiceReceiverName,
            formatToCurrency(item.amount.toString()),
        ]);

        itemDetailsRows.push([
            "--",
            "--",
            "--",
            descriptions.REPORT.TOTAL,
            formatToCurrency(total.toString())
        ])

        const itemDetailsHeaders = [
            descriptions.TABLE_COLUMNS.DAY.KEY,
            descriptions.TABLE_COLUMNS.DAY.DATETIME,
            descriptions.TABLE_COLUMNS.DAY.INVOICE_RECEIVER_ID,
            descriptions.TABLE_COLUMNS.DAY.CUSTOMER,
            descriptions.TABLE_COLUMNS.DAY.TOTAL,
        ];
        const columnWidths = [15, 35, 30, 90, 23];

        const headerStyles = {
            fillColor: [240, 240, 240],
            textColor: [0],
            fontFamily: fontConstants.FAMILY,
            fontStyle: fontConstants.BOLD,
        };

        pdf.setFont(fontConstants.FAMILY);
        const itemDetailsYStart = 38;
        (pdf as any).autoTable({
            head: [itemDetailsHeaders],
            body: itemDetailsRows,
            startY: itemDetailsYStart,
            headStyles: {
                fillColor: headerStyles.fillColor,
                textColor: headerStyles.textColor,
                fontStyle: headerStyles.fontStyle,
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                halign: 'left',
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0] },
                1: { cellWidth: columnWidths[1] },
                2: { cellWidth: columnWidths[2] },
                3: { cellWidth: columnWidths[3] },
                4: { cellWidth: columnWidths[4] },
            },
            alternateRowStyles: { fillColor: [255, 255, 255] },
            bodyStyles: {
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
                textColor: [0, 0, 0],
                rowPageBreak: 'avoid',
            },
            margin: { top: 10, left: 13 },
        });
    }

    const buildMonthDetail = () => {
        const data = props.data?.data as SalesReportMonthView[]

        let total = 0
        let totalWithId = 0
        let totalWithoutId = 0

        data.forEach(record => {
            total += record.amount
            totalWithId += record.amountWitId
            totalWithoutId += record.amountWithoutId
        })

        const itemDetailsRows = data.map((item, index) => [
            (index + 1).toString(),
            formatToDisplayDate(item.datetime),
            item.saleQty,
            formatToCurrency(item.amount.toString()),
            formatToCurrency(item.amountWitId.toString()),
            formatToCurrency(item.amountWithoutId.toString()),
        ]);

        itemDetailsRows.push([
            "--",
            "--",
            descriptions.REPORT.TOTAL,
            formatToCurrency(total.toString()),
            formatToCurrency(totalWithId.toString()),
            formatToCurrency(totalWithoutId.toString()),
        ])

        const itemDetailsHeaders = [
            descriptions.TABLE_COLUMNS.MONTH.KEY,
            descriptions.TABLE_COLUMNS.MONTH.DATE,
            descriptions.TABLE_COLUMNS.MONTH.QTY,
            descriptions.TABLE_COLUMNS.MONTH.AMOUNT,
            descriptions.TABLE_COLUMNS.MONTH.AMOUNT_WITH_ID,
            descriptions.TABLE_COLUMNS.MONTH.AMOUNT_WITHOUT_ID,
        ];
        // FULL WITDH = 193
        const columnWidths = [15, 35, 35, 35, 35, 35];

        const headerStyles = {
            fillColor: [240, 240, 240],
            textColor: [0],
            fontFamily: fontConstants.FAMILY,
            fontStyle: fontConstants.BOLD,
        };

        pdf.setFont(fontConstants.FAMILY);
        const itemDetailsYStart = 38;
        (pdf as any).autoTable({
            head: [itemDetailsHeaders],
            body: itemDetailsRows,
            startY: itemDetailsYStart,
            headStyles: {
                fillColor: headerStyles.fillColor,
                textColor: headerStyles.textColor,
                fontStyle: headerStyles.fontStyle,
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                halign: 'left',
            },
            columnStyles: {
                0: { cellWidth: columnWidths[0] },
                1: { cellWidth: columnWidths[1] },
                2: { cellWidth: columnWidths[2] },
                3: { cellWidth: columnWidths[3] },
                4: { cellWidth: columnWidths[4] },
                5: { cellWidth: columnWidths[5] },
            },
            alternateRowStyles: { fillColor: [255, 255, 255] },
            bodyStyles: {
                fontSize: fontConstants.SIZE,
                font: fontConstants.FAMILY,
                cellPadding: { top: 1, right: 5, bottom: 1, left: 2 },
                textColor: [0, 0, 0],
                rowPageBreak: 'avoid',
            },
            margin: { top: 10, left: 13 },
        });

    }

    const buildDetailData = () => {
        switch (props.view) {
            case ReportView.DAY:
                buildDayDetail()
                break;

            case ReportView.MONTH:
                buildMonthDetail()
                break;

            default:
                break;
        }

    }

    const buildHeader = () => {
        pdf.setProperties({
            title: descriptions.TITLE,
        })

        pdf.setFontSize(9);
        pdf.setFont(fontConstants.FAMILY, fontConstants.BOLD);
        pdf.text(descriptions.TITLE, 150, 12);

        pdf.setLineWidth(0.1);

        pdf.setDrawColor(200, 200, 200);
        pdf.line(10, 18, 200, 18)
        pdf.text(props.company.tradeName, 13, 23)
        pdf.setFont(fontConstants.FAMILY, fontConstants.NORMAL);
        pdf.text("NIT: " + props.company.nit, 13, 28)
        pdf.text(props.company.address, 13, 32)

        pdf.setFont(fontConstants.FAMILY, fontConstants.BOLD)
        pdf.text(`${descriptions.REPORT.DATE_FROM}      :`, 130, 23)
        pdf.text(`${descriptions.REPORT.DATE_TO}   :`, 130, 27)
        pdf.text(`${descriptions.REPORT.PRINTED_AT}    :`, 130, 31)
        pdf.setFont(fontConstants.FAMILY, fontConstants.NORMAL)
        pdf.text(formatToDisplayDatetime(props.dateFrom), 155, 23)
        pdf.text(formatToDisplayDatetime(props.dateTo), 155, 27)
        pdf.text(dayjs().format(SystemConstants.DATETIME_FORMAT_DISPLAY), 155, 31)
        pdf.line(10, 34, 200, 34)
    }

    const buildFooter = () => {
        const totalPages = pdf.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            pdf.line(10, 283, 200, 283)
            pdf.setPage(i);
            pdf.setFont(fontConstants.FAMILY);
            pdf.text(
                `${descriptions.REPORT.PAGE} ${i} / ${totalPages}`,
                185,
                pdf.internal.pageSize.getHeight() - 5
            );
        }

        // TODO: DISABLED, TO NOT AUTO SAVE
        // pdf.save(`RFQ.pdf`);

        const pdfDataUri = pdf.output('datauristring');
        const newTab = window.open();
        newTab?.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);
    }

    buildHeader()

    buildDetailData()

    buildFooter()

}

export default SalesRecordsReport