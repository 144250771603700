import { Action } from 'redux'
import * as Actions from './actionTypes'
import { ProductSettings, ProductSettingsType } from './types'

export interface GetProductSettingsAction extends Action {
    settingsTypes?: ProductSettingsType[]
}

export interface GetProductSettingsSuccessAction extends Action {
    productSettings: ProductSettings
}

export interface ErrorAction extends Action {
    error?
}

export const getProductSettings = (settingsTypes?: ProductSettingsType[]): GetProductSettingsAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_API,
    settingsTypes,
})
export const getProductSettingsSuccess = (productSettings: ProductSettings): GetProductSettingsSuccessAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_SUCCESS,
    productSettings,
})
export const getProductSettingsError = (error?): ErrorAction => ({
    type: Actions.GET_PRODUCT_SETTINGS_ERROR,
    error,
})