import { Alert, Button, DatePicker, Form, Input, Row, Select, Skeleton, Space, Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"
import { addTimeToDateMoment, cleanCurrencyValue, formatToCurrency, getInputRequiredRule, parseDataKey, selectFilterOption } from "src/common/util"
import { Col12, Col24, Col4, Col6, Col9 } from "src/components/Columns"
import { CurrencyInput } from "src/components/CurrencyInput"
import { SearchableTable } from "src/components/SearchableTable"
import { summaryColumns, tableColumns } from "./config"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { SystemDescriptions } from "src/common/descriptions"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { SystemConstants } from "src/common/constants"
import { useDispatch, useSelector } from "react-redux"
import { getCustomersApi } from "src/features/Customer/state/action"
import { Customer } from "src/common/models/customer"
import { RootState } from "src/state/reducer"
import { Product } from "src/common/models/product"
import { getProductsApi } from "src/features/Product/state/action"
import { ProductTransactionDetail, ProductTransactionType, ProductType } from "src/components/ProductTransactionModal/types"
import { ProductTransactionModal } from "src/components/ProductTransactionModal"
import { Sale, SaleDetail } from "src/common/models/sale"
import { FormActionButtons } from "src/components/FormActionButtons"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_INVOICE_RECEIVER_BY_ID_API } from "src/features/Dte/state/actionTypes"
import { getInvoiceReceiverByIdApi } from "src/features/Dte/state/action"
import { DteError, DteErrorOrigin } from "src/features/Dte/state/types"
import { InvoiceReceiver } from "src/common/models/dte"
import dayjs from 'dayjs'
import { getSessionData, storeSessionData } from "src/session/sessionStore"
import { SaleError } from "../../state/types"
import { GET_CUSTOMERS_API } from "src/features/Customer/state/actionTypes"
import { GET_PRODUCTS_API } from "src/features/Product/state/actionTypes"

interface ReduxProps {
    customers?: Customer[]
    products?: Product[]
    isGettingInvoiceReceiver: boolean
    dteError?: DteError
    invoiceReceiver?: InvoiceReceiver
    saleError?: SaleError
    isGettingProducts: boolean
    isGettingCustomers: boolean
}

interface Props {
    onFinish: (sale: any) => void
    onCancel: () => void
    initialValues?: Sale
    readonly?: boolean
    reCertification?: boolean
}

export const CreateSaleForm = (props: Props) => {

    const dispatch = useDispatch()

    const selectRef = useRef<any>(null);
    const inputReceiverIdRef = useRef<any>(null);

    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
    const [saleDetail, setSaleDetail] = useState<ProductTransactionDetail[]>([])
    const [selectedDetail, setSelectedDetail] = useState<ProductTransactionDetail | undefined>(undefined)
    const [isCustomerSelected, setIsCustomerSelected] = useState<boolean>(false)
    const [invoiceLimitFlag, setInvoiceLimitFlag] = useState<boolean>(false)
    const [invoiceReceiverReadOnly, setInvoiceReceiverReadOnly] = useState<boolean>(true)

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            handleFocus()
        }
    };

    useEffect(() => {

        window.addEventListener('keydown', handleKeyPress);

        if (!props.readonly) {
            dispatch(getCustomersApi())
            dispatch(getProductsApi({
                status: 'ACTIVE',
            }))
        }

        if (props.initialValues) {
            const sale: Sale = { ...props.initialValues }

            const detail: ProductTransactionDetail[] = sale.detail.map(detailItem => ({
                productId: detailItem.productId,
                product: detailItem.description,
                meassureId: detailItem.meassureId,
                meassure: detailItem.meassure,
                meassureSymbol: detailItem.meassureSymbol,
                qty: detailItem.qty,
                unitPrice: detailItem.price,
                subtotal: detailItem.total,
                disscount: 0,
                total: detailItem.total,

                productType: ProductType.GOOD,
            }))
            setSaleDetail(detail)

            form.setFieldsValue({
                customer: sale.customerId,
                shipTo: sale.shipTo,
                document: sale.document,
                datetime: dayjs(sale.datetime),
                type: 1,
                expirationDate: dayjs(sale.expirationDate),
                paymentType: 1,
                subtotal: formatToCurrency(sale.amount.toString()).toString(),
                disscount: formatToCurrency(sale.disscount.toString()),
                total: formatToCurrency(sale.amount.toString()),
                invoiceReceiverId: sale.invoiceReceiverId,
                invoiceReceiverName: sale.invoiceReceiverName,
                invoiceReceiverAddress: sale.invoiceReceiverAddress,
                invoiceAuth: sale?.certificationData?.authorization,
                invoiceSeries: sale?.certificationData?.series,
                invoiceNumber: sale?.certificationData?.number,
            })
        } else {
            const saleDetail = getSessionData().saleDetail

            if (saleDetail) {
                setSaleDetail(saleDetail)
            }
        }

        form.setFieldsValue({
            disscount: 0,
        })

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [])

    const handleFocus = () => {
        if (selectRef.current && !selectedProduct) {
            selectRef.current.focus();
        }
    };

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        customers: state.customer.customers,
        products: state.product.products,
        isGettingInvoiceReceiver: serviceLoading(state, [GET_INVOICE_RECEIVER_BY_ID_API]),
        dteError: state.dte.error,
        invoiceReceiver: state.dte.invoiceReceiver,
        saleError: state.sale.error,
        isGettingCustomers: serviceLoading(state, [GET_CUSTOMERS_API]),
        isGettingProducts: serviceLoading(state, [GET_PRODUCTS_API]),
    }))

    const descriptions = SystemDescriptions.PAGES.SALE.CREATE
    const commonDescriptions = SystemDescriptions.PAGES.COMMON

    const { Search } = Input;

    const [form] = Form.useForm();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (reduxProps.dteError && reduxProps.dteError.type === DteErrorOrigin.GET_INVOICE_RECEIVER_BY_ID) {
            form.setFields([
                {
                    name: "invoiceReceiverId",
                    errors: [reduxProps?.dteError?.detail?.message ?? commonDescriptions.FORM.UNKNOWN_ERROR]
                }
            ])
        }
    }, [reduxProps.dteError])

    useEffect(() => {
        if (reduxProps.invoiceReceiver) {
            setInvoiceReceiverData(reduxProps.invoiceReceiver.name, reduxProps.invoiceReceiver.address,)
            validateInvoiceLimit()
            setInvoiceReceiverReadOnly(true)
        }
    }, [reduxProps.invoiceReceiver])

    const validateInvoiceLimit = () => {
        const { invoiceReceiverId, total } = form.getFieldsValue()

        if (!invoiceReceiverId || !total) {
            return
        }

        if (Number(cleanCurrencyValue(total)) >= SystemConstants.INVOICE.MIN_AMOUNT_WITHOUT_ID && SystemConstants.INVOICE.FINAL_CONSUMER_ITEMS.includes(invoiceReceiverId)) {
            setInvoiceLimitFlag(true)
        } else {
            setInvoiceLimitFlag(false)
        }
    }

    const setInvoiceReceiverData = (name: string, address: string, receiverId?: string) => {

        const newValues = {
            invoiceReceiverName: name,
            invoiceReceiverAddress: address,
        }

        if (receiverId) {
            newValues['invoiceReceiverId'] = receiverId
        }

        form.setFieldsValue(newValues)
    }

    const tblColumns = [
        ...tableColumns,
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            hidden: props.readonly,
            render: (_, record: ProductTransactionDetail) => {
                const handleEditClick = (detailItem: ProductTransactionDetail) => {
                    const foundProduct = reduxProps?.products?.find(candidate => candidate.id === detailItem.productId)
                    setSelectedProduct(foundProduct)
                    setSelectedDetail(detailItem)
                }

                const handleDeleteClick = (ref: string) => {
                    const newDetail: ProductTransactionDetail[] = []
                    saleDetail.forEach(detail => {
                        if (detail.ref !== ref) {
                            newDetail.push(detail)
                        }
                    })
                    setSaleDetail(newDetail)
                    storeSessionData({
                        saleDetail: newDetail,
                    })
                }

                return <Space size="middle">
                    <Tooltip title={commonDescriptions.ACTIONS.EDIT} >
                        <Button type="primary" ghost shape="circle"
                            icon={<EditOutlined rev={undefined} />}
                            onClick={() => handleEditClick(record)}
                        />
                    </Tooltip>
                    <Tooltip title={commonDescriptions.ACTIONS.DELETE} >
                        <Button type="primary" danger ghost shape="circle"
                            icon={<DeleteOutlined rev={undefined} />}
                            onClick={() => handleDeleteClick(record.ref!)}
                        />
                    </Tooltip>
                </Space>
            },
        },
    ]

    const onFinish = (formValues) => {
        let saleUtility: number = 0
        const paymentCondition: string = ""

        const saleDetailParsed = saleDetail.map(detailItem => {
            saleUtility += Number(detailItem.utility) ?? 0

            return {
                price: detailItem.unitPrice,
                productId: detailItem.productId,
                qty: detailItem.qty,
                meassure: detailItem.meassure,
                total: detailItem.total,
                meassureId: detailItem.meassureId,
                disscount: detailItem.disscount,
                utility: detailItem.utility,
                warehouseId: 1,
                description: detailItem.product,
                productType: detailItem.productType,
                meassureSymbol: detailItem.meassureSymbol,
                lastCoste: detailItem.lastCoste,
            }
        })

        const sale: Sale = {
            customerId: Number(formValues.customer),
            document: formValues.document,
            datetime: addTimeToDateMoment(formValues.datetime),
            amount: Number(cleanCurrencyValue(formValues.total)),
            expirationDate: addTimeToDateMoment(formValues.expirationDate),
            disscount: Number(formValues.disscount),
            typeId: formValues.type,
            paymentStatusId: formValues.paymentType,
            utility: saleUtility,
            paymentType: paymentCondition,
            transportation: "",
            ref: "NA",
            shipTo: formValues.shipTo,
            payed: false,
            outstanding: Number(cleanCurrencyValue(formValues.total)),
            warehouseId: 1,
            detail: saleDetailParsed as SaleDetail[],
            isDteCertified: false,
            authorization: null,
            invoiceReceiverId: "",
        }

        if (props.initialValues) {
            sale.id = props.initialValues.id
        }

        if (SystemConstants.INVOICE_FEATURE_FLAG) {
            sale['invoiceData'] = {
                receptorId: formValues.invoiceReceiverId,
                receptorName: formValues.invoiceReceiverName,
                receptorAddress: formValues.invoiceReceiverAddress,
            }
        }
        console.log("sale", sale)
        props.onFinish(sale)
    }

    const renderPurchaseDetail = () => (
        <SearchableTable
            showLoader={false}
            items={saleDetail}
            tableColumns={tblColumns}
            summaryColumns={summaryColumns}
        />
    )

    const buildClientName = (clientName: string, tradeName: string): string => {
        return tradeName && tradeName.trim().length > 0
            ? `${clientName} - ${tradeName}`
            : clientName
    }

    const handleCustomerChange = (value) => {
        const foundCustomer = reduxProps?.customers?.find(candidate => candidate.id! === Number(value))

        form.setFieldsValue({
            shipTo: foundCustomer?.clientName,
            invoiceReceiverId: foundCustomer?.nit,
            invoiceReceiverName: foundCustomer?.tradeName,
            invoiceReceiverAddress: foundCustomer?.address,
        })
        setIsCustomerSelected(true)
    }

    const handleProductChange = (value) => {
        console.log("vv", value)
        if (!value) {
            return
        }

        const foundProduct = reduxProps.products?.find(candidate => candidate.id == value)
        setSelectedProduct(foundProduct)
        setSelectedDetail(undefined)
    }

    const getButtonStatus = (): boolean => {
        return props.initialValues
            ? false
            : !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0 || !saleDetail || saleDetail.length === 0 || reduxProps.isGettingInvoiceReceiver
    }

    const renderActionButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            actionDisabled={getButtonStatus}
            onCancel={props.onCancel}
        />
    )

    const handlePaymentChange = () => {
        const { customer, datetime, type } = form.getFieldsValue()

        if (!customer || !datetime || !type) {
            return
        }

        if (type === 1) {
            form.setFieldsValue({
                expirationDate: datetime,
            })
            return
        }

        const foundCustomer = reduxProps?.customers?.find(candidate => candidate.id === Number(customer))

        if (!foundCustomer) {
            return
        }

        const creditDays: number = foundCustomer.creditDays ?? 0
        form.setFieldsValue({
            expirationDate: datetime.add(creditDays, 'days')
        })
    }

    const handleSearchInvoiceId = (value) => {
        form.setFields([
            {
                name: "invoiceReceiverId",
                errors: []
            }
        ])

        if (!value || SystemConstants.INVOICE.FINAL_CONSUMER_ITEMS.includes(value)) {
            setInvoiceReceiverData("CONSUMIDOR FINAL", "CIUDAD", "CF")
            validateInvoiceLimit()
            setInvoiceReceiverReadOnly(false)
        } else {
            dispatch(getInvoiceReceiverByIdApi(value))
        }
    }

    const handleInputFocus = (event: any) => event.target.select();

    const containsLowercase = (str: string): boolean => {
        return /[a-z]/.test(str);
    }

    const invoiceReceiverIdValidator = (value): Promise<string> => {
        if (value.includes(" ")) {
            return Promise.reject(descriptions.FORM.ERRORS.SPACE_NOT_ALLOWED)
        } else if (containsLowercase(value)) {
            return Promise.reject(descriptions.FORM.ERRORS.LOWER_CASE_NOT_ALLOWED)
        }

        return Promise.resolve("")
    }

    const handleInvoiceReceiverIdChange = () => {
        // const { invoiceReceiverId } = form.getFieldsValue()

        // if (invoiceReceiverId) {
        //     form.setFieldsValue({
        //         invoiceReceiverId: undefined,
        //         invoiceReceiverName: undefined,
        //         invoiceReceiverAddress: undefined,
        //     })
        // }
    }

    const renderInvoiceInputs = () => (
        SystemConstants.INVOICE_FEATURE_FLAG &&
        <>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.INVOICE_RECEIVER_ID.LABEL}
                    name={"invoiceReceiverId"}
                    rules={[
                        getInputRequiredRule(descriptions.FORM.INVOICE_RECEIVER_ID.LABEL),
                        {
                            validator: (_, value) => invoiceReceiverIdValidator(value)
                        },
                    ]}
                    validateTrigger={["onBlur", "onChange"]}
                >
                    <Search
                        ref={inputReceiverIdRef}
                        placeholder={descriptions.FORM.INVOICE_RECEIVER_ID.PLACEHOLDER}
                        onSearch={handleSearchInvoiceId}
                        style={{ width: '100%' }}
                        loading={reduxProps.isGettingInvoiceReceiver}
                        disabled={!isCustomerSelected}
                        autoComplete="none"
                        onFocus={handleInputFocus}
                        onChange={handleInvoiceReceiverIdChange}
                    />
                </Form.Item>
            </Col6>
            <Col9>
                <Form.Item
                    label={descriptions.FORM.INVOICE_RECEIVER_NAME.LABEL}
                    name={"invoiceReceiverName"}
                    rules={[
                        getInputRequiredRule(descriptions.FORM.INVOICE_RECEIVER_NAME.LABEL),
                    ]}
                    validateTrigger="onBlur"
                >
                    <Input
                        placeholder={descriptions.FORM.INVOICE_RECEIVER_NAME.PLACEHOLDER}
                        readOnly={invoiceReceiverReadOnly}
                        onFocus={handleInputFocus}
                    />
                </Form.Item>
            </Col9>
            <Col9>
                <Form.Item
                    label={descriptions.FORM.INVOICE_RECEIVER_ADDRESS.LABEL}
                    name={"invoiceReceiverAddress"}
                    rules={[
                        getInputRequiredRule(descriptions.FORM.INVOICE_RECEIVER_ADDRESS.LABEL),
                    ]}
                    validateTrigger="onBlur"
                >
                    <Input
                        placeholder={descriptions.FORM.INVOICE_RECEIVER_ADDRESS.PLACEHOLDER}
                        readOnly={invoiceReceiverReadOnly}
                        onFocus={handleInputFocus}
                    />
                    {/* <Search
                        placeholder={descriptions.FORM.INVOICE_RECEIVER_ADDRESS.PLACEHOLDER}
                        onSearch={clearInvoiceReceiverAddress}
                        style={{ width: '100%' }}
                        disabled={invoiceReceiverReadOnly}
                        autoComplete="none"
                        enterButton={
                            <Button
                                icon={<ClearOutlined rev={undefined} />}
                            />
                        }
                        ref={inputReceiverAddressRef}
                        onFocus={() => {
                            inputReceiverAddressRef.current?.input?.select()
                        }}
                    /> */}
                </Form.Item>
            </Col9>
        </>
    )

    const renderCertificationDataInputs = () => (
        SystemConstants.INVOICE_FEATURE_FLAG &&
        <>
            <Col12>
                <Form.Item
                    label={descriptions.FORM.INVOICE_AUTH.LABEL}
                    name={"invoiceAuth"}
                    validateTrigger="onBlur"
                >
                    <Input
                        placeholder={descriptions.FORM.INVOICE_AUTH.PLACEHOLDER}
                        readOnly={invoiceReceiverReadOnly}
                    />
                </Form.Item>
            </Col12>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.INVOICE_SERIES.LABEL}
                    name={"invoiceSeries"}
                    validateTrigger="onBlur"
                >
                    <Input
                        placeholder={descriptions.FORM.INVOICE_SERIES.PLACEHOLDER}
                        readOnly={invoiceReceiverReadOnly}
                    />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.INVOICE_NUMBER.LABEL}
                    name={"invoiceNumber"}
                    validateTrigger="onBlur"
                >
                    <Input
                        placeholder={descriptions.FORM.INVOICE_NUMBER.PLACEHOLDER}
                        readOnly={invoiceReceiverReadOnly}
                    />
                </Form.Item>
            </Col6>
        </>
    )

    const renderForm = () => (
        <Form
            form={form}
            layout="horizontal"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={onFinish}
            disabled={props.readonly}
            onKeyPress={handleKeyDown}
        >
            <Row gutter={[16, 0]}>
                <Col9>
                    <Form.Item
                        label={descriptions.FORM.CUSTOMER.LABEL}
                        name={"customer"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.CUSTOMER.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={reduxProps.customers?.map((customer: Customer) => ({
                                value: `${customer.id}`,
                                label: buildClientName(customer.clientName, customer.tradeName),
                            }))}
                            placeholder={descriptions.FORM.CUSTOMER.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleCustomerChange}
                        />
                    </Form.Item>
                </Col9>
                <Col9>
                    <Form.Item
                        label={descriptions.FORM.SHIP_TO.LABEL}
                        name={"shipTo"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.SHIP_TO.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input placeholder={descriptions.FORM.SHIP_TO.PLACEHOLDER} />
                    </Form.Item>
                </Col9>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.DOCUMENT.LABEL}
                        name={"document"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={descriptions.FORM.DOCUMENT.PLACEHOLDER}
                            autoComplete="none"
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.DATETIME.LABEL}
                        name={"datetime"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.DATETIME.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                            onChange={handlePaymentChange}
                            disabledDate={(current) => {
                                let customDate = dayjs()
                                return current && current > customDate
                            }}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.TYPE.LABEL}
                        name={"type"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.TYPE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: descriptions.FORM.TYPE_CASH,
                                },
                                {
                                    value: 2,
                                    label: descriptions.FORM.TYPE_CREDIT,
                                },
                            ]}
                            placeholder={descriptions.FORM.TYPE.PLACEHOLDER}
                            onChange={handlePaymentChange}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.EXPIRATION_DATE.LABEL}
                        name={"expirationDate"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.EXPIRATION_DATE.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={SystemConstants.DATE_FORMAT}
                        />
                    </Form.Item>
                </Col6>
                <Col6>
                    <Form.Item
                        label={descriptions.FORM.PAYMENT_STATUS.LABEL}
                        name={"paymentType"}
                        rules={[
                            getInputRequiredRule(descriptions.FORM.PAYMENT_STATUS.LABEL),
                        ]}
                        validateTrigger="onBlur"
                    >
                        <Select
                            options={[
                                {
                                    value: 1,
                                    label: descriptions.FORM.PAYMENT_PENDING
                                },
                                {
                                    value: 2,
                                    label: descriptions.FORM.PAYMENT_PAYED,
                                    disabled: true,
                                },
                            ]}
                            placeholder={descriptions.FORM.PAYMENT_STATUS.PLACEHOLDER}
                        />
                    </Form.Item>
                </Col6>
                {renderInvoiceInputs()}
                {
                    props.readonly &&
                    renderCertificationDataInputs()
                }
                {
                    !props.readonly &&
                    <Col24>
                        <Form.Item
                            label={descriptions.FORM.PRODUCTS.LABEL}
                            name={"products"}
                        >
                            <Select
                                ref={!selectedProduct ? selectRef : undefined}
                                options={reduxProps.products?.map(product => ({
                                    value: `${product.id}`,
                                    label: `${product.name} - ${product.id}`,
                                    disabled: (product.stock ?? 0) <= 0,
                                }))}
                                placeholder={descriptions.FORM.PRODUCTS.PLACEHOLDER}
                                filterOption={selectFilterOption}
                                showSearch
                                optionFilterProp="children"
                                onChange={handleProductChange}
                            />
                        </Form.Item>
                    </Col24>
                }
            </Row>
            {renderPurchaseDetail()}
            <Row
                gutter={[16, 0]}
                style={{ marginTop: 16 }}
            >
                <Col12></Col12>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.SUBTOTAL}
                        name={"subtotal"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.DISSCOUNT}
                        name={"disscount"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"} />
                    </Form.Item>
                </Col4>
                <Col4>
                    <Form.Item
                        label={descriptions.FORM.TOTAL}
                        name={"total"}
                    // rules={[
                    //     // getInputRequiredRule(descriptions.FORM.DOCUMENT.LABEL),
                    // ]}
                    // validateTrigger="onBlur"
                    >
                        <CurrencyInput
                            placeholder={"0.00"}
                            readOnly
                        />
                    </Form.Item>
                </Col4>
            </Row>
            {
                invoiceLimitFlag && SystemConstants.INVOICE_FEATURE_FLAG &&
                renderAlert()
            }
            {
                reduxProps.saleError &&
                <Alert
                    message={descriptions.FORM.ERRORS.TITLE}
                    showIcon
                    description={reduxProps.saleError?.detail.message ?? commonDescriptions.FORM.UNKNOWN_ERROR}
                    type="error"
                    style={{
                        marginBottom: 12,
                    }}
                />
            }
            {(!props.readonly || props.reCertification) && renderActionButtons()}
        </Form>
    )

    useEffect(() => {
        if (props.readonly) {
            return
        }

        let subtotal: number = 0
        let disscount: number = 0
        let total: number = 0

        saleDetail.forEach(detailItem => {
            subtotal += detailItem.subtotal
            disscount += detailItem.disscount
            total += detailItem.total
        })

        form.setFieldsValue({
            disscount: formatToCurrency(cleanCurrencyValue(disscount.toString()).toString()),
            subtotal: formatToCurrency(cleanCurrencyValue(subtotal.toString()).toString()),
            total: formatToCurrency(cleanCurrencyValue(total.toString()).toString()),
        })

        // TODO: INVESTIGATE WHY THIS HAPPENS
        form.setFieldValue("disscount", "0")

        validateInvoiceLimit()
    }, [saleDetail])

    const handleAddProductAccept = (detailList: ProductTransactionDetail[]) => {
        // setSelectedProduct(undefined)

        let newDetail: ProductTransactionDetail[] = [...saleDetail]

        detailList.forEach(detail => {
            const foundItem = saleDetail.find(candidate => candidate.ref === detail.ref)

            if (foundItem) {
                newDetail = newDetail.map(item => {
                    if (item.ref === detail.ref) {
                        item = { ...detail }
                    }
                    return item
                })
            } else {
                newDetail.push(detail)
            }

            setSaleDetail(parseDataKey(newDetail))
        })

        form.setFieldsValue({
            products: undefined,
        })

        storeSessionData({
            saleDetail: newDetail,
        })

        setSelectedProduct(undefined)
        setSelectedDetail(undefined)
    }

    const renderAlert = () => (
        <Alert
            banner
            message={descriptions.FORM.ERRORS.INVOICE_AMOUNT_MIN_LIMIT}
        />
    )

    const renderModals = () => (
        <ProductTransactionModal
            open={!!selectedProduct}
            product={selectedProduct}
            onAccept={handleAddProductAccept}
            onCancel={function (): void {
                form.setFieldsValue({
                    products: undefined,
                })
                setSelectedProduct(undefined)
                setSelectedDetail(undefined)
            }}
            detail={selectedDetail}
            type={ProductTransactionType.SALE}
        />
    )

    const renderInitalLoader = () => (

        <Skeleton active />
    )

    return (
        <>
            {
                (reduxProps.isGettingCustomers || reduxProps.isGettingProducts)
                    ? renderInitalLoader()
                    : renderForm()
            }
            {renderModals()}
        </>
    )
}
