import { Action } from 'redux'
import * as Actions from './actionTypes'
import { RoutingSale } from 'src/common/models/routingSale'
import { RoutingCustomer } from 'src/common/models/routingCustomer'

export interface CreateRoutingSaleAction extends Action {
    sale: RoutingSale
}
export interface CreateRoutingSaleSuccessAction extends Action {
    sale: RoutingSale
}
export interface ErrorAction extends Action {
    error?
}
export const createRoutingSaleApi = (sale: RoutingSale): CreateRoutingSaleAction => ({
    type: Actions.CREATE_ROUTING_SALE_API,
    sale,
})
export const createRoutingSaleSuccess = (sale: RoutingSale): CreateRoutingSaleSuccessAction => ({
    type: Actions.CREATE_ROUTING_SALE_API_SUCCESS,
    sale,
})
export const createRoutingSaleError = (error?): ErrorAction => ({
    type: Actions.CREATE_ROUTING_SALE_API_ERROR,
    error,
})

export interface GetRoutingCustomersAction extends Action {
}
export interface GetRoutingCustomersSuccessAction extends Action {
    customers: RoutingCustomer[]
}
export interface ErrorAction extends Action {
    error?
}
export const getRoutingCustomersApi = (): GetRoutingCustomersAction => ({
    type: Actions.GET_ROUTING_CUSTOMERS_API,
})
export const getRoutingCustomersSuccess = (customers: RoutingCustomer[]): GetRoutingCustomersSuccessAction => ({
    type: Actions.GET_ROUTING_CUSTOMERS_API_SUCCESS,
    customers,
})
export const getRoutingCustomersError = (error?): ErrorAction => ({
    type: Actions.GET_ROUTING_CUSTOMERS_API_ERROR,
    error,
})