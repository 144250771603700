// import { Sale } from "src/common/models/sale"
// import { SalePayment } from "src/common/models/salePayment"
// import { User } from "src/common/models/user"

import { Button, Dropdown, MenuProps } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { SystemDescriptions } from "src/common/descriptions"
import { Sale } from "src/common/models/sale"
import { DownOutlined } from '@ant-design/icons'
import { User } from "src/common/models/user"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { RootState } from "src/state/reducer"
import { GET_SALE_BY_ID_API, GET_SALE_RECORDS_API } from "../state/actionTypes"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { useEffect, useState } from "react"
import { getSaleByIdApi, getSaleRecordsApi } from "../state/action"
import { Loader } from "src/components/Loader"
import { SaleDetailModal } from "../SaleRecords/SaleDetailModal"

interface ReduxProps {
    isLoadingData: boolean
    accountReceivables?: Sale[]
    isGettingSale: boolean
    currentSale?: Sale
    // isCreatingSalePayment: boolean
    // createSalePaymentSuccess: boolean
    user?: User
    // isGettingPurchasePayments: boolean
    // purchasePayments?: SalePayment[]
}

export const AccountReceivables = () => {
    const dispatch = useDispatch()

    const [selectedSale, setSelectedSale] = useState<Sale | undefined>(undefined)
    const [detailButtonClicked, setDetailButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALE_RECORDS_API]),
        accountReceivables: state.sale.sales,
        isGettingSale: serviceLoading(state, [GET_SALE_BY_ID_API]),
        currentSale: state.sale.currentSale,
        // isCreatingSalePayment: serviceLoading(state, [CREATE_SA]),
        // createPurchasePaymentSuccess: state.purchase.createPurchasePaymentSuccess,
        user: state.security.user,
        // isGettingSALEPayments: serviceLoading(state, [GET_SALE_PA]),
        // purchasePayments: state.purchase.purchasePayments,
    }))

    const descriptions = SystemDescriptions.PAGES.PURCHASE.ACCOUNT_PAYABLES

    useEffect(() => {
        dispatch(getSaleRecordsApi({
            paymentStatusId: "PENDING",
        }))
    }, [])

    useEffect(() => {
        console.log("setted", reduxProps.currentSale, detailButtonClicked)
        if (reduxProps.currentSale && detailButtonClicked) {
            console.log("setted")
            setSelectedSale(reduxProps.currentSale)
        }
    }, [reduxProps.currentSale])

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
            // onClick={handleExport}
            >
                {descriptions.EXPORT}
            </Button>
        </div>
    }

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Sale) => {
                const handleDetailClick = (saleId: number) => {
                    setDetailButtonClicked(true)
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleCreatePaymentClick = (saleId: number) => {
                    // const paymentTransaction: PaymentTransaction = {
                    //     targetId: record.id!,
                    //     type: "PURCHASE",
                    //     amount: record.amount,
                    //     amountPayed: record.amountPayed,
                    //     currentBalance: record.amountPending,
                    //     target: record.supplier || '',
                    //     targetDocument: record.document,
                    //     targetExternalId: record.supplierId,
                    //     targetExternalName: record.supplier || '',
                    //     username: reduxProps?.user?.username || '',
                    //     userId: 1,
                    // }
                    // setPaymentTransaction(paymentTransaction)
                    // setPaymentModalOpen(true)
                }

                const handleViewPaymentRecordsClick = (saleId: number) => {
                    // dispatch(getPurchasePaymentRecordsApi({
                    //     purchaseId,
                    // }))
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleCreatePaymentClick(record.id!)}
                            >
                                {descriptions.ACTIONS.CREATE_PAYMENT}
                            </Button>
                        ),
                    },
                    {
                        key: '3',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleViewPaymentRecordsClick(record.id!)}
                                disabled={record.amount === record.outstanding}
                            >
                                {descriptions.ACTIONS.PAYMENT_RECORDS}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const renderContent = () => {
        return <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.accountReceivables || []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    }

    return (
        <>
            {renderContent()}
            {
                selectedSale &&
                <SaleDetailModal
                    sale={selectedSale}
                    open={true}
                    onAccept={() => { setSelectedSale(undefined) }}
                    onCancel={() => { setSelectedSale(undefined) }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingSale}
            />
            {/* {
                paymentTransaction &&
                <PaymentModal
                    open={paymentModalOpen}
                    type={ProductTransactionType.PURCHASE}
                    onAccept={() => { setPaymentModalOpen(false) }}
                    onCancel={() => { setPaymentModalOpen(false) }}
                    data={paymentTransaction}
                />
            } */}
            {/* <PurchasePaymentRecords
                purchasePayments={reduxProps.purchasePayments || []}
                open={!!reduxProps.purchasePayments}
                onAccept={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
            /> */}
        </>
    )
}
