import { SystemConstants } from "src/common/constants";

const CREATE_PURCHASE = 'CREATE_PURCHASE';
export const CREATE_PURCHASE_API = CREATE_PURCHASE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_PURCHASE_API_SUCCESS = CREATE_PURCHASE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_PURCHASE_API_ERROR = CREATE_PURCHASE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PURCHASE_RECORDS = 'GET_PURCHASE_RECORDS';
export const GET_PURCHASE_RECORDS_API = GET_PURCHASE_RECORDS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PURCHASE_RECORDS_API_SUCCESS = GET_PURCHASE_RECORDS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PURCHASE_RECORDS_API_ERROR = GET_PURCHASE_RECORDS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PURCHASE_BY_ID = 'GET_PURCHASE_BY_ID';
export const GET_PURCHASE_BY_ID_API = GET_PURCHASE_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PURCHASE_BY_ID_API_SUCCESS = GET_PURCHASE_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PURCHASE_BY_ID_API_ERROR = GET_PURCHASE_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_PURCHASE_PAYMENT = 'CREATE_PURCHASE_PAYMENT';
export const CREATE_PURCHASE_PAYMENT_API = CREATE_PURCHASE_PAYMENT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_PURCHASE_PAYMENT_API_SUCCESS = CREATE_PURCHASE_PAYMENT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_PURCHASE_PAYMENT_API_ERROR = CREATE_PURCHASE_PAYMENT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PURCHASE_PAYMENTS = 'GET_PURCHASE_PAYMENTS';
export const GET_PURCHASE_PAYMENTS_API = GET_PURCHASE_PAYMENTS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PURCHASE_PAYMENTS_API_SUCCESS = GET_PURCHASE_PAYMENTS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PURCHASE_PAYMENTS_API_ERROR = GET_PURCHASE_PAYMENTS + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAN_PURCHASE_PAYMENTS = 'CLEAN_PURCHASE_PAYMENTS'

const GET_PURCHASES_REPORT = 'GET_PURCHASES_REPORT';
export const GET_PURCHASES_REPORT_API = GET_PURCHASES_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PURCHASES_REPORT_API_SUCCESS = GET_PURCHASES_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PURCHASES_REPORT_API_ERROR = GET_PURCHASES_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;