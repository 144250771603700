import { call, put, takeLatest, } from 'redux-saga/effects'
import * as ActionTypes from './actionTypes'
import * as Actions from './action'
import { Urls } from 'src/common/urls'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* getUsersApi() {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.CUSTOMER.GET_RECORDS());

        if (response.status >= 200 && response.status <= 299) {
            yield put(Actions.getUsersSuccess(response.data.data))
        } else {
            yield put(Actions.getUsersError(JSON.stringify(response.data.error)))
        }
    } catch (err) {
        yield put(Actions.getUsersError(err));
    }
}

// function* createUserApi(action: Actions.createUserAction) {
//     try {
//         const sessionData = getSessionData();
//         const response = yield call(createAxiosInstance(sessionData.accessToken).post, Urls.BACKEND.USERS.CREATE(), action.user);

//         if (response.status >= 200 && response.status <= 299) {
//             yield put(Actions.createUserSuccess())
//         } else {
//             yield put(Actions.createUserError(JSON.stringify(response.data.error)))
//         }
//     } catch (err) {
//         yield put(Actions.createUserError(err));
//     }
// }

export default function* securitySagas() {
    yield takeLatest(ActionTypes.GET_USERS_API, getUsersApi)
}