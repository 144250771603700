import { formatToCurrency, formatToDisplayDate, formatToDisplayDatetime, removeTimeZoneFromDatetime } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";
import dayjs from "dayjs"
import { SystemDescriptions } from "src/common/descriptions";
import { Badge } from "antd";
import { SummaryType } from "src/components/SearchableTable/types";

const descriptions = SystemDescriptions.PAGES.SALE.ACCOUNT_RECEIVABLES

export enum ColumnsKeys {
    NO = 'key',
    DATETIME = 'datetime',
    CUSTOMER = 'customer',
    DOCUMENT = 'document',
    TOTAL = 'amount',
    AMOUNT_PENDING = 'outstanding',
    EXPIRATION_DATE = 'expirationDate',
    ELLAPSED = 'ellapsed',
    STATUS = 'statusId',
    ACTIONS = 'actions',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.KEY,
        dataIndex: ColumnsKeys.NO,
        key: ColumnsKeys.NO,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DATETIME,
        dataIndex: ColumnsKeys.DATETIME,
        key: ColumnsKeys.DATETIME,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDatetime(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.CUSTOMER,
        dataIndex: ColumnsKeys.CUSTOMER,
        key: ColumnsKeys.CUSTOMER,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DOCUMENT,
        dataIndex: ColumnsKeys.DOCUMENT,
        key: ColumnsKeys.DOCUMENT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.TOTAL,
        dataIndex: ColumnsKeys.TOTAL,
        key: ColumnsKeys.TOTAL,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.OUTSTANDING,
        dataIndex: ColumnsKeys.AMOUNT_PENDING,
        key: ColumnsKeys.AMOUNT_PENDING,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.EXPIRATION,
        dataIndex: ColumnsKeys.EXPIRATION_DATE,
        key: ColumnsKeys.EXPIRATION_DATE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToDisplayDate(value),
    },
    {
        title: descriptions.TABLE_COLUMNS.ELLAPSED,
        dataIndex: ColumnsKeys.ELLAPSED,
        key: ColumnsKeys.ELLAPSED,
        align: searchableTableUtils.alignment.centerAlign,
        render: (_, record) => {
            const today = dayjs()
            let color: string | undefined = "#52c41a"
            const expiration = dayjs(removeTimeZoneFromDatetime(record.expirationDate))
            const ellapsed: number = dayjs().diff(dayjs(record.datetime), "days")

            if (expiration.isSame(today)) {
                color = "#faad14"
            } else if (today.isAfter(expiration)) {
                color = "#f5222d"
            }

            return <Badge count={ellapsed} showZero color={color} />
        }
    },
    {
        title: descriptions.TABLE_COLUMNS.STATUS,
        dataIndex: ColumnsKeys.STATUS,
        key: ColumnsKeys.STATUS,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => {
            return value === 1 ? descriptions.STATUS_PENDING : descriptions.STATUS_PAYED
        }
    },
];

export const summaryColumns: Partial<Record<ColumnsKeys, SummaryType>> = {
    [ColumnsKeys.TOTAL]: SummaryType.SUM,
    [ColumnsKeys.AMOUNT_PENDING]: SummaryType.SUM,
    [ColumnsKeys.NO]: SummaryType.COUNT,
}