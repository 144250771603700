import { Languages } from "../../types";
import { NavbarDescriptions } from "./types";

const DescriptionsEnUs: NavbarDescriptions = {
    LOGOUT: {
        DISPLAY_NAME: 'Logout',
        TOOLTIP: '',
    },
}

const DescriptionsEsUs: NavbarDescriptions = {
    LOGOUT: {
        DISPLAY_NAME: 'Salir',
        TOOLTIP: '',
    },
}

const navbarDictionary = {}
navbarDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
navbarDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getNavbarDescriptions = (systemLang: Languages): NavbarDescriptions => (
    navbarDictionary[systemLang]
)