import { RootState } from 'src/state/reducer';
import { API_REGEXP } from './reducer';

export const serviceLoading = (state: RootState, actionList: string[], inclusion: boolean = false) => {
    if (state.apiLoader && actionList.length > 0) {
        for (const action of actionList) {
            const matches = API_REGEXP.exec(action);
            if (!matches) {
                return false;
            }
            const [, requestName] = matches;
            if (!inclusion && !state.apiLoader[requestName]) {
                return false;
            } else if (inclusion && state.apiLoader[requestName]) {
                return true;
            }
        }
        return !inclusion;
    }
    return false;
};