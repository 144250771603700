import { useMemo } from "react"
import { ChartData } from "../types"
import { AxisOptions, Chart } from "react-charts"
import { formatNumberSuffix } from "src/common/util"

interface Props {
    params: ChartData
}

export const ChartComponent = (props: Props) => {

    const primaryAxis = useMemo<
        AxisOptions<typeof props.params.data[number]["data"][number]>
    >(
        () => ({
            getValue: (datum: any) => datum.primary,
            elementType: "line",
        }),
        []
    )

    const secondaryAxes = useMemo<
        AxisOptions<typeof props.params.data[number]["data"][number]>[]
    >(
        () => [
            {
                getValue: (datum: any) => datum?.secondary,
                elementType: "line",
                formatters: {
                    scale: value => formatNumberSuffix(value)
                },
            },
        ],
        []
    )

    return (
        <div style={{ height: 200 }}>
            <Chart
                options={{
                    data: props.params.data,
                    primaryAxis,
                    secondaryAxes,
                }}
            />
        </div>
    )
}
