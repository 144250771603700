import * as _ from 'lodash';
import { Action } from 'redux';
import { ErrorAction, LoginSuccessAction } from './action';
import * as Actions from './actionTypes';
import { User } from 'src/common/models/user';

export interface SecurityState {
    user?: User;
    userIsLogged?: boolean;
    error?: any
}

const defaultState: SecurityState = {};

export default (state: SecurityState = defaultState, action: Action): SecurityState => {
    switch (action.type) {
        case Actions.LOGIN_API: {
            return {
                ...state,
                user: undefined,
                error: undefined
            };
        }
        case Actions.LOGIN_SUCCESS: {
            const loginSuccessAction = action as LoginSuccessAction;
            return {
                ...state,
                user: loginSuccessAction.user,
                error: undefined
            };
        }
        case Actions.LOGIN_ERROR: {
            const errorAction = action as ErrorAction;
            return {
                ...state,
                user: undefined,
                error: errorAction.error
            }
        }
        case Actions.LOGOUT_API: {
            return {
                ...state,
                user: undefined,
                error: undefined
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};