import { Divider, Select, Space } from "antd"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import { useNavigate } from "react-router"
import { Languages } from "src/common/descriptions/types"
import { Urls } from "src/common/urls"
import { FormActionButtons } from "src/components/FormActionButtons"
import { SettingsKeys, SysSettings } from "./types"
import { storeSessionData } from "src/session/sessionStore"
import { useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { ModalSuccess } from "src/components/ModalSuccess"
import { SystemDescriptions } from "src/common/descriptions"

interface ReduxProps {
    language: Languages
}

export const SystemSettings = () => {
    const navigate = useNavigate()

    const [settings, setSettings] = useState<SysSettings>()
    const [modalSuccessOpen, setModalSuccessOpen] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        language: state.common.language ?? Languages.SPANISH_US,
    }))

    const descriptions = SystemDescriptions.PAGES.SETTINGS.SYSTEM_SETTINGS

    const updateSettings = (key: string, value: string) => {
        const newSettings = { ...settings }
        newSettings[key] = value
        setSettings(newSettings)
    }

    const renderLangSection = () => {

        const handleLangChange = (value) => {
            updateSettings(SettingsKeys.LANGUAGE, value)
        }

        return <>
            <Title level={5}>Language</Title>
            <Space>
                Select language:
                <Select
                    defaultValue={reduxProps.language}
                    style={{ width: 120 }}
                    onChange={handleLangChange}
                    options={[
                        {
                            value: Languages.SPANISH_US,
                            label: descriptions.LANGUAGE.LANGUAGES.SPANISH,
                        },
                        {
                            value: Languages.ENGLISH_US,
                            label: descriptions.LANGUAGE.LANGUAGES.ENGLISH,
                        },
                    ]}
                />
            </Space>
            <Divider />
        </>
    }

    const handleCancel = () => {
        navigate(Urls.FRONTEND.HOME)
    }

    const handleApplySettings = () => {
        if (settings?.LANGUAGE) {
            storeSessionData({
                language: settings.LANGUAGE,
            })
        }
        setModalSuccessOpen(true)
    }

    const renderSuccessModal = () => (
        <ModalSuccess
            isVisible={modalSuccessOpen}
            title={descriptions.SUCCESS.TITLE}
            description={descriptions.SUCCESS.DESCRIPTION}
            onPrimaryAction={() => {
                window.location.reload()
            }}
            onSecondaryActiont={() => { }}
            primaryLabel={descriptions.SUCCESS.PRIMARY_LABEL}
            secondaryLabel={descriptions.SUCCESS.SECONDARY_LABEL}
            hideSecondaryButton={true}
        />
    )

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                {renderLangSection()}
                <FormActionButtons
                    cancelText={descriptions.CANCEL}
                    actionText={descriptions.APPLY}
                    onCancel={handleCancel}
                    onAccept={handleApplySettings}
                />
            </Space>
            {renderSuccessModal()}
        </>
    )
}
