import { SystemDescriptions } from "src/common/descriptions";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCTS

export enum ColumnsKeys {
    ID = 'id',
    NAME = 'name',
    PRESENTATIONS = 'presentations',
    CATEGORY = 'category',
    BRAND = 'brand',
    SUBBRAND = 'subbrand',
    ACTIONS = 'actions',
    STATUS = 'status',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.ID,
        key: ColumnsKeys.ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.NAME,
        key: ColumnsKeys.NAME,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.CATEGORY,
        dataIndex: ColumnsKeys.CATEGORY,
        key: ColumnsKeys.CATEGORY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.BRAND,
        dataIndex: ColumnsKeys.BRAND,
        key: ColumnsKeys.BRAND,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.SUBBRAND,
        dataIndex: ColumnsKeys.SUBBRAND,
        key: ColumnsKeys.SUBBRAND,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.STATUS,
        dataIndex: ColumnsKeys.STATUS,
        key: ColumnsKeys.STATUS,
        align: searchableTableUtils.alignment.centerAlign,
    },
];