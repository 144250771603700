import { ErrorAction, GetDtePdfSuccessAction, GetInvoiceReceiverByIdSuccessAction } from "./action";
import { CLEAR_INVOICE_RECEIVER, GET_DTE_PDF_API, GET_DTE_PDF_API_ERROR, GET_DTE_PDF_API_SUCCESS, GET_INVOICE_RECEIVER_BY_ID_API, GET_INVOICE_RECEIVER_BY_ID_API_ERROR, GET_INVOICE_RECEIVER_BY_ID_API_SUCCESS } from "./actionTypes";
import { DteError, DteErrorOrigin } from "./types";
import { InvoiceReceiver } from "src/common/models/dte";

export interface DteState {
    error?: DteError
    invoiceReceiver?: InvoiceReceiver
    dtePdf?: any
}

const initialState: DteState = {
}

const dteReducer = (state: DteState = initialState, action: any): DteState => {
    switch (action.type) {
        case GET_INVOICE_RECEIVER_BY_ID_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_INVOICE_RECEIVER_BY_ID_API_SUCCESS: {
            const successAction = action as GetInvoiceReceiverByIdSuccessAction;

            return {
                ...state,
                invoiceReceiver: {
                    id: successAction.invoiceReceiver.id,
                    deceased: successAction?.invoiceReceiver?.deceased ?? false,
                    type: successAction.idType,
                    name: successAction.invoiceReceiver.name,
                    address: successAction.invoiceReceiver.address,
                }
            }
        }
        case GET_INVOICE_RECEIVER_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: DteErrorOrigin.GET_INVOICE_RECEIVER_BY_ID,
                    detail: errorAction.error
                }
            }
        }
        case CLEAR_INVOICE_RECEIVER: {
            return {
                ...state,
                invoiceReceiver: undefined,
            }
        }
        case GET_DTE_PDF_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_DTE_PDF_API_SUCCESS: {
            const successAction = action as GetDtePdfSuccessAction;

            return {
                ...state,
                dtePdf: successAction.response,
            }
        }
        case GET_DTE_PDF_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: DteErrorOrigin.GET_DTE_PDF,
                    detail: errorAction.error,
                }
            }
        }
    }
    return state;
};

export default dteReducer;