import { Languages } from "../../types"
import { DashboardDescriptions } from "./types"

const DescriptionsEnUs: DashboardDescriptions = {
    COMPANY_INFORMATION: {
        TITLE: "Company Information",
        NIT: "NIT",
        PHONE: "Phone",
        EMAIL: "Email",
        ZIPCODE: "Zipcode",
        ADDRESS: "Address",
    },
    SHORTCUTS: {
        TITLE: "Shortcuts",
        CREATE_SALE: "Create Sale",
        SALES_RECORDS: "Sales Records",
        PRODUCT_STOCK: "Product Stock"
    },
    CHARTS: {
        TITLE: "Dashboard",
        MONTH_SALES: {
            TITLE: "Month Sales",
            TOTAL: "Total",
            TOTAL_WITH_ID: "With Id",
            TOTAL_WITHOUT_ID: "Without Id",
        },
        MONTH_PURCHASES: {
            TITLE: "Month Purchases",
            TOTAL: "Total",
        },
    },
}

const DescriptionsEsUs: DashboardDescriptions = {
    COMPANY_INFORMATION: {
        TITLE: "Información",
        NIT: "NIT",
        PHONE: "Teléfono",
        EMAIL: "Correo",
        ZIPCODE: "Código Postal",
        ADDRESS: "Dirección",
    },
    SHORTCUTS: {
        TITLE: "Accesos Directos",
        CREATE_SALE: "Crear Venta",
        SALES_RECORDS: "Registro de Ventas",
        PRODUCT_STOCK: "Existencias"
    },
    CHARTS: {
        TITLE: "Tablero",
        MONTH_SALES: {
            TITLE: "Ventas del Mes",
            TOTAL: "Total",
            TOTAL_WITH_ID: "Con Id",
            TOTAL_WITHOUT_ID: "Sin Id",
        },
        MONTH_PURCHASES: {
            TITLE: "Compras del mes",
            TOTAL: "Total",
        },
    },
}

const dashboardDictionary = {}
dashboardDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
dashboardDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getDashboardDescriptions = (systemLang: Languages): DashboardDescriptions => (
    dashboardDictionary[systemLang]
)