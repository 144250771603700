import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { SaleKardexReport } from "./types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateRanges } from "src/components/SearchableTable/types"
import moment from "moment"
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_KARDEX_REPORT_API } from "../../state/actionTypes"
import { getKardexReportApi } from "../../state/action"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { Button, Dropdown, Select, Space } from "antd"
import { Product } from "src/common/models/product"
import { getProductsApi } from "src/features/Product/state/action"
import { selectFilterOption } from "src/common/util"
import * as XLSX from 'xlsx';
import { SystemDescriptions } from "src/common/descriptions"
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { SaleDetailModal } from "src/features/Sale/SaleRecords/SaleDetailModal"
import { Sale } from "src/common/models/sale"
import { Loader } from "src/components/Loader"
import { GET_SALE_BY_ID_API } from "src/features/Sale/state/actionTypes"
import { getSaleByIdApi } from "src/features/Sale/state/action"
import { getPurchaseByIdApi } from "src/features/Purchase/state/action"
import { PurchaseDetailModal } from "src/features/Purchase/PurchaseRecords/PurchaseDetailModal"
import { Purchase } from "src/common/models/purchase"
import { GET_PURCHASE_BY_ID_API } from "src/features/Purchase/state/actionTypes"

interface ReduxProps {
    isLoadingData: boolean
    report?: SaleKardexReport[]
    products?: Product[]
    currentSale?: Sale
    isGettingSaleById: boolean
    isGettingPurchase: boolean
    currentPurchase?: Purchase
}

export const ProductsKardex = () => {
    const dispatch = useDispatch()

    const [selectedSale, setSelectedSale] = useState<Sale | undefined>(undefined)
    const [selectedPurchase, setSelectedPurchase] = useState<Purchase | undefined>(undefined)

    const descriptions = SystemDescriptions.PAGES.REPORTS.PRODUCT_KARDEX

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })
    const [selectedProductId, setSelectedProductId] = useState<number | undefined>()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_KARDEX_REPORT_API]),
        report: state.report.kardexReport,
        products: state.product.products ?? [],
        currentSale: state.sale.currentSale,
        isGettingSaleById: serviceLoading(state, [GET_SALE_BY_ID_API]),
        isGettingPurchase: serviceLoading(state, [GET_PURCHASE_BY_ID_API]),
        currentPurchase: state.purchase.currentPurchase,
    }))

    const getReportData = () => {
        if (selectedProductId) {
            dispatch(getKardexReportApi({
                dateFrom: dateRanges.startDate,
                dateTo: dateRanges.endDate,
                productId: selectedProductId,
                type: "",
            }))
        }
    }

    useEffect(() => {
        getReportData()
        dispatch(getProductsApi({ status: "ACTIVE" }))
    }, [])

    useEffect(() => {
        if (reduxProps.currentSale) {
            setSelectedSale(reduxProps.currentSale)
        }
    }, [reduxProps.currentSale])

    useEffect(() => {
        if (reduxProps.currentPurchase) {
            setSelectedPurchase(reduxProps.currentPurchase)
        }
    }, [reduxProps.currentPurchase])

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleActionButtonClick = () => {
        getReportData()
    }

    const handleProductChange = (value) => {
        setSelectedProductId(value)
    }

    const renderProductSelect = () => {

        const productOptions = reduxProps?.products?.map(product => ({
            value: product.id?.toString() ?? '',
            label: product.name,
        })) ?? []

        const options = [
            {
                value: '0',
                label: descriptions.ALL_PRODUCTS,
            },
            ...productOptions,
        ]

        return <div style={{ width: '100%' }} >
            <Select
                onChange={handleProductChange}
                options={options}
                placeholder={descriptions.SELECT_PRODUCT}
                style={{ width: '100%' }}
                filterOption={selectFilterOption}
                showSearch
            />
        </div>
    }

    const buildReportData = () => {
        return reduxProps?.report?.map((reportItem: SaleKardexReport) => {
            return {
                columnA: moment(reportItem.datetime).format("YYYY-MM-DD"),
                columnB: reportItem.productId,
                columnC: "Fardo", // todo: fix this
                columnD: "Venta", // todo: fix this
                columnE: reportItem.document,
                columnF: 0,
                columnG: reportItem.outcome,
                columnH: 0,
                columnI: reportItem.product,
            }
        }) ?? []
    }

    const handleExport = () => {
        if (!reduxProps.report) {
            return
        }

        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(buildReportData());
        XLSX.utils.book_append_sheet(workbook, worksheet, "Kardex");

        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xls' });
        const dataUrl = window.URL.createObjectURL(
            new Blob([excelBuffer], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', `KARDEX_${moment().unix()}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
                onClick={handleExport}
            >
                {descriptions.EXPORT_DATA}
            </Button>
        </div>
    }

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, kardexRecord) => {
                const handleDetailClick = (refId: string) => {
                    const referenceId: string = refId.split("-")[1]

                    if (refId.includes("SALE-")) {
                        dispatch(getSaleByIdApi(Number(referenceId)))
                    } else if (refId.includes("PURCHASE-")) {
                        dispatch(getPurchaseByIdApi(Number(referenceId)))
                    }
                }

                return <Dropdown
                    placement="bottomRight"
                    dropdownRender={(menu) => (
                        <div style={{ backgroundColor: 'white', border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                            <Button
                                icon={<UserOutlined rev={undefined} />}
                                style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', border: 'none' }}
                                onClick={() => handleDetailClick(kardexRecord.refId!)}
                            >
                                Ver información
                            </Button>
                        </div>
                    )

                    }
                >
                    <Button size="small" >
                        <Space>
                            <DownOutlined rev={undefined} />
                        </Space>
                    </Button>
                </Dropdown>
            }
        },
    ]

    const renderContent = () => (
        <PageCard size="small" title={descriptions.TITLE} extra={renderExtraContent()} >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.report ?? []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                showActionButton
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumns}
            >
                {renderProductSelect()}
            </SearchableTable>
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            {
                selectedSale &&
                <SaleDetailModal
                    sale={selectedSale}
                    open={!!selectedSale}
                    onAccept={() => { setSelectedSale(undefined) }}
                    onCancel={() => { setSelectedSale(undefined) }}
                />
            }
            {
                selectedPurchase &&
                <PurchaseDetailModal
                    purchase={selectedPurchase}
                    open={!!selectedPurchase}
                    onAccept={() => { setSelectedPurchase(undefined) }}
                    onCancel={() => { setSelectedPurchase(undefined) }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingSaleById || reduxProps.isGettingPurchase}
            />
        </>
    )
}
