import styled from "styled-components";
import { Layout } from 'antd'
import { HorizontalFlexDiv } from "src/common/styles/div";

export const Sidebar = styled(Layout.Sider)`
    background: #343A3F !important;
    overflow: auto !important;
    min-height: 100vh !important;
    left: 0 !important;

    .ant-menu-item {
        background-color: #343A3F !important;
    }

    .ant-menu {
        background-color: #343A3F !important;
    }

    .ant-menu-item-selected {
        background-color: #28A744 !important;
    }

    .ant-layout-sider-children {
        heigth: 100%;
        overflow: auto;
    }
`
export const LogoContainer = styled(HorizontalFlexDiv)`
    gap: 8px;
    height: 62px;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    color: #B7BABC;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`
export const Logo = styled.img`
    width: 40px;
    border-radius: 50px;
`
export const CompanyNameLabel = styled.span`
    color: #B7BABC;
    font-size: 20px;
`