import moment, { Moment } from "moment"
import { SystemConstants } from "./constants"
import { SystemDescriptions } from "./descriptions"
import dayjs from "dayjs"

export const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0

export const parseDataKey = (data: any[]) => {
    return data.map((item: any, idx: number) => {
        item.key = (idx + 1)
        item.idx = (idx + 1)
        return item
    })
}

export const momentUtilities = {
    getFirstDayOfCurrentMonth: () => moment().startOf('month').format(SystemConstants.DATETIME_FORMAT),
    getLastDayOfCurrentMonth: () => moment().endOf('month').format(SystemConstants.DATETIME_FORMAT),
    getCurrentDay: () => moment().format(SystemConstants.DATETIME_FORMAT),
}

export const formatNumberAsCurrency = (number: number, decimalPlaces: number = 2, currencySymbol: string = '') => {
    const numberString = number.toFixed(decimalPlaces).toString()
    const [integerPart, decimalPart] = numberString.split('.');
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPlaces > 0 ? `${currencySymbol}${integerWithCommas}.${decimalPart}` : `${currencySymbol}${integerWithCommas}`;
}

export const stringToBase64 = (inputString: string): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    const padding = '=';

    let result = '';
    let paddingCount = 0;

    for (let i = 0; i < inputString.length; i += 3) {
        const a = inputString.charCodeAt(i);
        const b = inputString.charCodeAt(i + 1);
        const c = inputString.charCodeAt(i + 2);

        const index1 = a >> 2;
        const index2 = ((a & 3) << 4) | (b >> 4);
        const index3 = ((b & 15) << 2) | (c >> 6);
        const index4 = c & 63;

        result += characters.charAt(index1) + characters.charAt(index2) + characters.charAt(index3) + characters.charAt(index4);

        if (i + 2 >= inputString.length) {
            paddingCount = i + 2 - inputString.length + 1;
        }
    }

    if (paddingCount > 0) {
        result = result.slice(0, -paddingCount) + padding.repeat(paddingCount);
    }

    return result;
}

export const roundDecimalNumber = (number: number, decimalPositions: number = 2) => number.toFixed(decimalPositions)

const isValidCurrencyAmount = (value): boolean => {
    const currencyRegex = /^\d+(\.\d{1,6})?$/;

    if (!currencyRegex.test(value)) {
        return false
    }

    return true
}

export const amountValidator = (value, isZeroValid: boolean = false): Promise<string> => {
    if (!isValidCurrencyAmount(value)) {
        return Promise.reject("Invalid amount")
    }

    if (!isZeroValid && parseFloat(value) <= 0) {
        return Promise.reject("Amount must be greater than zero")
    } else if (isZeroValid && parseFloat(value) < 0) {
        return Promise.reject("Amount must be greater or equal than zero")
    }

    return Promise.resolve("")
}

export const selectFilterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const getInputRequiredRule = (fieldName: string): { required: boolean, message: string } => {
    return {
        required: true,
        message: SystemDescriptions.PAGES.COMMON.FORM.REQUIRED_FIELD(fieldName)
    }
}

export const formatToDisplayDatetime = (datetimeDBFormat: string = '') => dayjs(datetimeDBFormat.replace("Z", "").replace("T", " ")).format(SystemConstants.DATETIME_FORMAT_DISPLAY)

export const formatToDisplayDate = (datetimeDBFormat: string = '') => dayjs(datetimeDBFormat.replace("Z", "").replace("T", " ")).format("DD-MM-YYYY");

export const formatToCurrency = (number: string) => `${parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2 })}`;

export const addTimeToDateMoment = (dateWithoutTime: Moment): string => {
    const currentTime = moment();
    const cleanedDate = dateWithoutTime.clone()
        .set('hour', currentTime.hour())
        .set('minute', currentTime.minute())
        .set('second', currentTime.second())
        .set('millisecond', currentTime.second())

    return cleanedDate.format(SystemConstants.DATETIME_DATABASE_FORMAT);
}

export const cleanCurrencyValue = (value: string): number => {
    return parseFloat(value.replace(/,/g, ""));
}

export const formatNumber = (number: number, decimals: number = 2): number => {
    return parseFloat(number.toFixed(decimals))
}

export const removeTimeZoneFromDatetime = (datetime: string): string => {
    return datetime.replace("T", " ").replace("Z", "")
}

export const getDteDocument = (authId: string) => window.open(`${SystemConstants.BACKEND_SERVER.PRINT_BASE_URL}/pdf?uuid=${authId}`, '_blank', 'noopener,noreferrer');

export function formatNumberSuffix(num) {
    if (!num) {
        return ''
    }

    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K';
    }
    return num.toString();
}