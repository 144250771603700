import { Languages } from "src/common/descriptions/types";
import { PurchasePaymentRecordsDescriptions } from "./types";

const DescriptionsEnUs: PurchasePaymentRecordsDescriptions = {
    TITLE: "Payment Records",
    TOTAL: "Total",
    CASH_AMOUNT: "Cash Amount",
    CASH_DATE: "Cash Date",
    CASH_REF: "Cash Ref",
    TRANS_AMOUNT: "Trans Amount",
    TRANS_DATE: "Trans Date",
    TRANS_REF: "Trans Ref",
    CREDIT_NOTE_AMOUNT: "Credit Note Amount",
    CREDIT_NOTE_DATE: "Credit Note Date",
    CREDIT_NOTE_REF: "Credit Note Ref",
}

const DescriptionsEsUs: PurchasePaymentRecordsDescriptions = {
    TITLE: "Historial de Pagos",
    TOTAL: "Total",
    CASH_AMOUNT: "Efectivo Monto",
    CASH_DATE: "Eectivo Fecha",
    CASH_REF: "Efectivo Ref",
    TRANS_AMOUNT: "Trans Monto",
    TRANS_DATE: "Trans Fecha",
    TRANS_REF: "Trans Ref",
    CREDIT_NOTE_AMOUNT: "Nota Crédito Monto",
    CREDIT_NOTE_DATE: "Nota Crédito Fecha",
    CREDIT_NOTE_REF: "Nota Crédito Ref",
}

export const purchasePaymentRecordsDictionary = {}
purchasePaymentRecordsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
purchasePaymentRecordsDictionary[Languages.SPANISH_US] = DescriptionsEsUs