import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* createRoutingSaleApi(action: Actions.CreateRoutingSaleAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.ROUTING.CREATE_SALE, action.sale)
        yield put(Actions.createRoutingSaleSuccess(response.data))
    } catch (err) {
        yield put(Actions.createRoutingSaleError(err));
    }
}

function* getRoutingCustomersApi(_: Actions.GetRoutingCustomersAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.ROUTING.GET_CUSTOMERS)
        yield put(Actions.getRoutingCustomersSuccess(response.data))
    } catch (err) {
        yield put(Actions.getRoutingCustomersError(err));
    }
}

export default function* routingSagas() {
    yield takeLatest(ActionTypes.CREATE_ROUTING_SALE_API, createRoutingSaleApi)
    yield takeLatest(ActionTypes.GET_ROUTING_CUSTOMERS_API, getRoutingCustomersApi)
}