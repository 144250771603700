import { Action } from 'redux'
import { MenuOption, MenuSection } from '../types';
import * as Actions from './actionTypes'

export const selectMenuOption = (menuOption: MenuOption | MenuSection) => ({
    type: Actions.SELECT_MENU_OPTION,
    menuOption
});

export interface ToggleAction extends Action {
    status: boolean
}

export const toggleFullScreenMode = (status: boolean): ToggleAction => ({
    type: Actions.TOGGLE_FULL_SCREEN_MODE,
    status
});

export const toggleSidebarCollapse = (status: boolean): ToggleAction => ({
    type: Actions.TOGGLE_SIDEBAR_COLLAPSE,
    status,
});

export const toggleDrawerOpenStatus = (status: boolean): ToggleAction => ({
    type: Actions.TOGGLE_DRAWER_OPEN,
    status,
});

export const toggleSidebarBrokenStatus = (status: boolean): ToggleAction => ({
    type: Actions.TOGGLE_SIDEBAR_BROKEN,
    status,
});