import { Alert, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { amountValidator, formatNumber, formatToCurrency, getInputRequiredRule, selectFilterOption } from "src/common/util";
import { Col16, Col8 } from "src/components/Columns";
import { CurrencyInput } from "src/components/CurrencyInput";
import { Product } from "src/common/models/product";
import { FormActionButtons } from "src/components/FormActionButtons";
import { v4 as uuid } from 'uuid';
import { SystemDescriptions } from "src/common/descriptions";
import { ProductTransactionDetail, ProductTransactionType } from "../types";
import { ProductPresentation } from "src/common/models/productPresentation";


interface Props {
    product: Product
    detail?: ProductTransactionDetail
    onAccept: (detail: ProductTransactionDetail[]) => void
    onCancel: () => void
    type: ProductTransactionType
}

export const ProductTransactionForm = (props: Props) => {
    const [form] = Form.useForm();

    const [isMeassureSelected, setIsMeassureSelected] = useState<boolean>(false)
    const [selectedMeassure, setSelectedMeassure] = useState<ProductPresentation | undefined>()
    const [minPriceWarning, setMinPriceWarning] = useState<boolean>(false)

    const WARNING_TEXT: string = "***"

    useEffect(() => {
        if (props.product) {
            const product = props.product

            form.setFieldsValue({
                description: product.name,
                qty: 1,
                meassure: undefined,
                disscount: 0,
            })
            setIsMeassureSelected(false)
        }

        if (props.detail) {
            const detail = props.detail

            const foundMeassure = props.product.presentations.find(candidate => candidate.meassureId === props.detail?.meassureId)

            form.setFieldsValue({
                qty: detail?.qty ?? 1,
                meassure: foundMeassure?.ref,
                disscount: detail?.disscount ?? 0,
                unitPrice: formatToCurrency(detail.unitPrice.toString()),
                subtotal: formatToCurrency(detail.subtotal.toString()),
                total: formatToCurrency(detail.total.toString()),
            })
            setIsMeassureSelected(true)
            setSelectedMeassure(foundMeassure)
        }
    }, [props.product, props.detail])

    const descriptions = SystemDescriptions.COMPONENTS.PRODUCT_TRANSACTION

    const handleMeassureChange = (value) => {
        const foundMeassure = props.product.presentations.find(candidate => candidate.ref === value)

        if (foundMeassure) {
            setIsMeassureSelected(true)
            setSelectedMeassure(foundMeassure)
            form.setFieldsValue({
                unitPrice: props.type === ProductTransactionType.SALE
                    ? foundMeassure.salePrice
                    : foundMeassure.qty * props.product.coste,
            })
        } else {
            setIsMeassureSelected(false)
        }
        handleChangeQtyPriceDisscount()
    }

    const qtyValidator = (value) => {
        const integerPattern = /^[1-9]\d*$/;
        if (integerPattern.test(value)) {
            return Promise.resolve()
        } else {
            return Promise.reject(descriptions.ERRORS.INVALID_QTY)
        }
    }

    const calculateMinimalUnityValues = (qty: number, meassureId: string): { qtyMinimal: number } => {
        let qtyMinimal: number = qty

        const foundPresentation = props.product.presentations.find(candidate => candidate.ref === meassureId)

        if (!foundPresentation) {
            throw new Error("Unable to found product presentation from detail")
        }

        if (foundPresentation.qty > 1) {
            qtyMinimal = qty * foundPresentation.qty
        }

        return {
            qtyMinimal: Number(qtyMinimal),
        }
    }

    const stockValidator = (value) => {
        if (props.type === ProductTransactionType.PURCHASE) {
            return Promise.resolve()
        }

        const { meassure } = form.getFieldsValue()
        const { qtyMinimal } = calculateMinimalUnityValues(value, meassure)

        if (qtyMinimal > props.product.stock!) {
            return Promise.reject(descriptions.ERRORS.NOT_ENOUGH_STOCK)
        } else {
            return Promise.resolve()
        }
    }

    const handleChangeQtyPriceDisscount = () => {
        const { qty, unitPrice, disscount } = form.getFieldsValue()

        const subtotal: number = parseFloat(qty) * parseFloat(unitPrice)
        const total: number = subtotal - parseFloat(disscount)

        form.setFieldsValue({
            subtotal: isNaN(subtotal) ? 0 : subtotal,
            total: isNaN(total) ? subtotal : total,
        })

        if (props.type === ProductTransactionType.SALE && selectedMeassure && Number(selectedMeassure.minSalePrice) > unitPrice) {
            setMinPriceWarning(true)
        } else {
            setMinPriceWarning(false)
        }
    }

    const cleanForm = () => {
        form.resetFields()
    }

    const handleOnFinish = (formValues) => {
        const foundPresentation = props.product.presentations.find(candidate => candidate.ref === formValues.meassure)

        if (!foundPresentation) {
            return
        }

        const newDetail: ProductTransactionDetail = {
            productId: props.product.id!,
            product: props.product.name,
            meassureId: foundPresentation.meassureId,
            meassure: foundPresentation.meassure ?? '',
            meassureSymbol: foundPresentation.meassureSymbol,
            qty: formValues.qty,
            unitPrice: formValues.unitPrice,
            subtotal: formValues.subtotal,
            disscount: formValues.disscount,
            total: formValues.total,
            ref: props.detail?.ref ?? uuid(),
            productType: props.product.id ? props.detail?.productType! : props.product.productType,
            lastCoste: props.product.coste,
        }

        if (props.type === ProductTransactionType.SALE) {
            const minimalQty: number = Number(formValues.qty) * Number(foundPresentation.qty)
            const minimalPrice: number = (Number(formValues.unitPrice) * formValues.qty) / minimalQty

            newDetail['utility'] = formatNumber(minimalQty * (minimalPrice - Number(props.product.coste)), 4)
        }

        cleanForm()
        props.onAccept([
            {
                ...newDetail
            }
        ])
    }

    const getButtonStatus = (): boolean => {
        return !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL}
            actionText={descriptions.ACCEPT}
            onCancel={() => {
                cleanForm()
                props.onCancel()
            }}
            actionDisabled={getButtonStatus}
        />
    )

    const handleInputFocus = (event: any) => event.target.select();

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={handleOnFinish}
        >
            <Row gutter={[16, 0]}>
                <Col16>
                    <Form.Item
                        label={descriptions.SINGLE_OPERATION.PRODUCT.LABEL}
                        name={"description"}
                        validateTrigger="onBlur"
                    >
                        <Input readOnly />
                    </Form.Item>
                </Col16>
                <Col8>
                    <Form.Item
                        label={descriptions.SINGLE_OPERATION.MEASSURE.LABEL}
                        name={"meassure"}
                        validateTrigger="onBlur"
                        rules={[
                            getInputRequiredRule(descriptions.SINGLE_OPERATION.MEASSURE.LABEL),
                        ]}
                    >
                        <Select
                            options={props.product?.presentations?.map(presentation => ({
                                value: `${presentation.ref}` ?? '',
                                label: `${presentation.meassure}` ?? '',
                            }))}
                            placeholder={descriptions.SINGLE_OPERATION.MEASSURE.PLACEHOLDER}
                            filterOption={selectFilterOption}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleMeassureChange}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <>
                        <Form.Item
                            label={descriptions.SINGLE_OPERATION.QTY.LABEL}
                            name={"qty"}
                            validateTrigger={["onBlur", "onChange"]}
                            rules={[
                                getInputRequiredRule(descriptions.SINGLE_OPERATION.QTY.LABEL),
                                {
                                    validator: (_, value) => {
                                        return qtyValidator(value)
                                    }
                                },
                                {
                                    validator: (_, value) => {
                                        return stockValidator(value)
                                    }
                                }
                            ]}
                        >
                            <Input
                                style={{ textAlign: 'center' }}
                                placeholder={descriptions.SINGLE_OPERATION.QTY.PLACEHOLDER}
                                type="number"
                                min={1}
                                onChange={handleChangeQtyPriceDisscount}
                                disabled={!isMeassureSelected}
                                onFocus={handleInputFocus}
                                autoComplete="none"
                            />
                        </Form.Item>
                    </>
                </Col8>
                <Col8>
                    <Form.Item
                        label={
                            minPriceWarning
                                ? `${descriptions.SINGLE_OPERATION.PRICE.LABEL} ${WARNING_TEXT}`
                                : descriptions.SINGLE_OPERATION.PRICE.LABEL
                        }
                        name={"unitPrice"}
                        validateTrigger={["onBlur", "onChange"]}
                        rules={[
                            getInputRequiredRule(descriptions.SINGLE_OPERATION.PRICE.LABEL),
                            {
                                validator: (_, value) => {
                                    return amountValidator(value)
                                }
                            },
                        ]}
                    >
                        <CurrencyInput
                            placeholder={descriptions.SINGLE_OPERATION.PRICE.PLACEHOLDER}
                            onChange={handleChangeQtyPriceDisscount}
                            disabled={!isMeassureSelected}
                            onFocus={handleInputFocus}
                        />
                    </Form.Item>
                </Col8>
                <Col8>
                    <Form.Item
                        label={descriptions.SINGLE_OPERATION.SUBTOTAL.LABEL}
                        name={"subtotal"}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput placeholder={descriptions.SINGLE_OPERATION.PRICE.PLACEHOLDER} readOnly />
                    </Form.Item>
                </Col8>
                <Col16></Col16>
                <Col8>
                    <Form.Item
                        label={descriptions.SINGLE_OPERATION.DISSCOUNT.LABEL}
                        name={"disscount"}
                        validateTrigger={["onBlur", "onChange"]}
                        rules={[
                            getInputRequiredRule(descriptions.SINGLE_OPERATION.DISSCOUNT.LABEL),
                            {
                                validator: (_, value) => {
                                    return amountValidator(value, true)
                                }
                            },
                        ]}
                    >
                        <CurrencyInput
                            placeholder={descriptions.SINGLE_OPERATION.DISSCOUNT.PLACEHOLDER}
                            onChange={handleChangeQtyPriceDisscount}
                        />
                    </Form.Item>
                </Col8>
                <Col16></Col16>
                <Col8>
                    <Form.Item
                        label={descriptions.SINGLE_OPERATION.TOTAL.LABEL}
                        name={"total"}
                        validateTrigger="onBlur"
                    >
                        <CurrencyInput placeholder={descriptions.SINGLE_OPERATION.TOTAL.PLACEHOLDER} readOnly />
                    </Form.Item>
                </Col8>
            </Row>
            {
                minPriceWarning &&
                <Alert
                    style={{ marginBottom: 12 }}
                    message={descriptions.ERRORS.MIN_PRICE}
                    type="warning"
                    showIcon
                />
            }
            {renderButtons()}
        </Form>
    )

    return (
        renderForm()
    )
}
