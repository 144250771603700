import {
    Bounce1,
    Bounce2,
    Bounce3,
    Spinner,
    LoaderContainer,
    BounceContainer
} from './styles';

type Props = {
    darkMode?: boolean
    label?: string
}

export const InnerLoader = (props: Props) => {
    const color: string = props.darkMode ? "#fff" : "#000"

    return <Spinner>
        <LoaderContainer>
            <BounceContainer>
                <Bounce3 backgroundColor={color} />
                <Bounce2 backgroundColor={color} />
                <Bounce1 backgroundColor={color} />
            </BounceContainer>
            {props.label ?? "Loading..."}
        </LoaderContainer>
    </Spinner>
}

export default InnerLoader;
