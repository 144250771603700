import { Languages } from "src/common/descriptions/types";
import { SystemSettingsDescriptions } from "./types";

const DescriptionsEnUs: SystemSettingsDescriptions = {
    TITLE: "System Settings",
    LANGUAGE: {
        TITLE: "Language",
        LABEL: "Select system language",
        LANGUAGES: {
            SPANISH: "Spanish",
            ENGLISH: "English",
        },
    },
    SUCCESS: {
        TITLE: "Settings Applied",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "",
        DESCRIPTION: "System will reload now.",
    },
    CANCEL: "Cancel",
    APPLY: "Apply",
}

const DescriptionsEsUs: SystemSettingsDescriptions = {
    TITLE: "Sistema",
    LANGUAGE: {
        TITLE: "Lenguaje",
        LABEL: "Seleccione lenguaje",
        LANGUAGES: {
            SPANISH: "Español",
            ENGLISH: "Ingles",
        },
    },
    SUCCESS: {
        TITLE: "Configuraciones Aplicadas",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "",
        DESCRIPTION: "El sistema se reiniciará en este momento.",
    },
    CANCEL: "Cancelar",
    APPLY: "Aplicar",
}

export const systemSettingsDictionary = {}
systemSettingsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
systemSettingsDictionary[Languages.SPANISH_US] = DescriptionsEsUs