import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency } from "src/common/util";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.PRODUCT.PRODUCTS

export enum ColumnsKeys {
    MEASSURE = 'meassure',
    QTY = 'qty',
    PRICE = 'salePrice',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.PRESENTATIONS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.PRESENTATIONS.QTY,
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.PRESENTATIONS.PRICE,
        dataIndex: ColumnsKeys.PRICE,
        key: ColumnsKeys.PRICE,
        align: searchableTableUtils.alignment.centerAlign,
        render: (value) => formatToCurrency(value),
    },
];