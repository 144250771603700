import { Languages } from "../../types";
import { ProductTransactionDescriptions } from "./types";

const DescriptionsEnUs: ProductTransactionDescriptions = {
    TITLE: "Add Product",
    TITLE_EDIT: "Edit Product",
    TITLE_EXTRA: "Add by batch",
    SINGLE_OPERATION: {
        PRODUCT: {
            LABEL: "Product",
            PLACEHOLDER: "",
        },
        MEASSURE: {
            LABEL: "Meassure",
            PLACEHOLDER: "Select",
        },
        QTY: {
            LABEL: "Qty",
            PLACEHOLDER: "Input qty",
        },
        PRICE: {
            LABEL: "Price",
            PLACEHOLDER: "Input price",
        },
        SUBTOTAL: {
            LABEL: "Subtotal",
            PLACEHOLDER: "Subtotal",
        },
        DISSCOUNT: {
            LABEL: "Disscount",
            PLACEHOLDER: "Disscount",
        },
        TOTAL: {
            LABEL: "Total",
            PLACEHOLDER: "Total",
        },
    },
    BATCH_OPERATION: {
        TABLE: {
            ID: "Id",
            DESCRIPTION: "Description",
            MEASSURE: "Meassure",
            QTY: "Qty",
            PRICE: "Price",
            TOTAL: "Total",
        },
        CATEGORY: {
            LABEL: "Category",
            PLACEHOLDER: "Category",
        },
        BRAND: {
            LABEL: "Brand",
            PLACEHOLDER: "Brand",
        },
        SUB_BRAND: {
            LABEL: "Sub Brand",
            PLACEHOLDER: "Sub Brand",
        },
        MEASSURE: {
            LABEL: "Meassure",
            PLACEHOLDER: "Meassure",
        },
        ASSIGN_QTY: {
            LABEL: "Assign Qty",
            PLACEHOLDER: "Assign Qty",
        },
        ASSIGN_PRICE: {
            LABEL: "Assign Price",
            PLACEHOLDER: "Assign Price",
        },
        TOTAL: "Total",
    },
    ERRORS: {
        INVALID_QTY: "Invalid qty",
        NOT_ENOUGH_STOCK: "Not enough stock",
        MIN_PRICE: "Sale price is lower than minimum setted",
    },
    ACCEPT: "Accept",
    CANCEL: "Cancel",
}

const DescriptionsEsUs: ProductTransactionDescriptions = {
    TITLE: "Agregar Producto",
    TITLE_EDIT: "Editar Producto",
    TITLE_EXTRA: "Agregar por lote",
    SINGLE_OPERATION: {
        PRODUCT: {
            LABEL: "Producto",
            PLACEHOLDER: "",
        },
        MEASSURE: {
            LABEL: "Presentación",
            PLACEHOLDER: "Seleccione",
        },
        QTY: {
            LABEL: "Cant",
            PLACEHOLDER: "Ingrese cantidad",
        },
        PRICE: {
            LABEL: "Precio",
            PLACEHOLDER: "Ingrese precio",
        },
        SUBTOTAL: {
            LABEL: "Subtotal",
            PLACEHOLDER: "Subtotal",
        },
        DISSCOUNT: {
            LABEL: "Descuento",
            PLACEHOLDER: "Descuento",
        },
        TOTAL: {
            LABEL: "Total",
            PLACEHOLDER: "Total",
        },
    },
    BATCH_OPERATION: {
        TABLE: {
            ID: "Id",
            DESCRIPTION: "Descripción",
            MEASSURE: "Presentación",
            QTY: "Cant",
            PRICE: "Precio",
            TOTAL: "Total",
        },
        CATEGORY: {
            LABEL: "Categoría",
            PLACEHOLDER: "Categoría",
        },
        BRAND: {
            LABEL: "Marca",
            PLACEHOLDER: "Marca",
        },
        SUB_BRAND: {
            LABEL: "Sub Marca",
            PLACEHOLDER: "Sub Marca",
        },
        MEASSURE: {
            LABEL: "Presentación",
            PLACEHOLDER: "Presentación",
        },
        ASSIGN_QTY: {
            LABEL: "Asignar Cant",
            PLACEHOLDER: "Asignar Cant",
        },
        ASSIGN_PRICE: {
            LABEL: "Asignar Precio",
            PLACEHOLDER: "Asignar Precio",
        },
        TOTAL: "Total",
    },
    ERRORS: {
        INVALID_QTY: "Cantidad inválida",
        NOT_ENOUGH_STOCK: "Existencia insuficiente",
        MIN_PRICE: "El precio de venta es menor al precio mínimo configurado",
    },
    ACCEPT: "Aceptar",
    CANCEL: "Cancelar",
}

const productTransactionDictionary = {}
productTransactionDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
productTransactionDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getProductTransactionsDescriptions = (systemLang: Languages): ProductTransactionDescriptions => (
    productTransactionDictionary[systemLang]
)