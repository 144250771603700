import dayjs from 'dayjs'

interface IDefaultValues {
    SEARCH_LABEL: string,
    RANGEPICKER_DEFAULT: [dayjs.Dayjs, dayjs.Dayjs],
    ACTION_BUTTON_LABEL: string
}

export const DefaultValues: IDefaultValues = {
    SEARCH_LABEL: 'Search...',

    RANGEPICKER_DEFAULT: [dayjs().startOf('month'), dayjs()],

    ACTION_BUTTON_LABEL: 'Action'
}

export interface DateRanges {
    startDate: string
    endDate: string
}

export enum SummaryType {
    SUM = 'SUM',
    AVERAGE = 'AVERAGE',
    COUNT = 'COUNT',
}