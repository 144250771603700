import styled from "styled-components";
import * as Antd from "antd"
import { FlexDiv } from "./div";

export const Form = styled(Antd.Form)`
	width: 100%;
`

export const ItemForm = styled(Form.Item)`
	border-radius: 3px;
	width: 100%;
	font-family: Quicksand;

	.ant-select .ant-select-arrow span.ant-select-suffix {
        margin-left: 1px;
        margin-right: -10px;
	}

	label {
		font-family: "Quicksand", sans-serif;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: red;
	}
`

export const InputAttribute = styled(Antd.Input)`
    
	.ant-input-show-count-suffix{
		background-color: pink;
		color: red;
		font-family: Quicksand;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		padding: 2px;
		border-radius: 2px;
		word-spacing: -3px;
	}

`

export const PageCard = styled(Antd.Card)`
	width: 100%;
`

export const ContentContainer = styled(FlexDiv)`
    gap: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
	flex: 1;
`