import { useDispatch } from "react-redux"
import { Navigate } from "react-router";
import { clearSessionData } from "src/session/sessionStore";
import { logout } from "../Security/state/action";

export const Logout = () => {

    const dispatch = useDispatch();
    clearSessionData()
    dispatch(logout())

    return (
        <Navigate to="/login" />
    )
}
