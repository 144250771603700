import { SystemConstants } from "src/common/constants";

const CREATE_SALE = 'CREATE_SALE';
export const CREATE_SALE_API = CREATE_SALE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_SALE_API_SUCCESS = CREATE_SALE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_SALE_API_ERROR = CREATE_SALE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALE_RECORDS = 'GET_SALE_RECORDS';
export const GET_SALE_RECORDS_API = GET_SALE_RECORDS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALE_RECORDS_API_SUCCESS = GET_SALE_RECORDS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALE_RECORDS_API_ERROR = GET_SALE_RECORDS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALE_BY_ID = 'GET_SALE_BY_ID';
export const GET_SALE_BY_ID_API = GET_SALE_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALE_BY_ID_API_SUCCESS = GET_SALE_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALE_BY_ID_API_ERROR = GET_SALE_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAR_CURRENT_SALE = 'CLEAR_CURRENT_SALE'

const CERTIFY_SALE = 'CERTIFY_SALE';
export const CERTIFY_SALE_API = CERTIFY_SALE + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CERTIFY_SALE_API_SUCCESS = CERTIFY_SALE + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CERTIFY_SALE_API_ERROR = CERTIFY_SALE + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_SALES_REPORT_API = GET_SALES_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_SALES_REPORT_API_SUCCESS = GET_SALES_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_SALES_REPORT_API_ERROR = GET_SALES_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;