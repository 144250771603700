import { call, put, takeLatest, } from 'redux-saga/effects';
import { Urls } from 'src/common/urls';
import * as Actions from './action';
import * as ActionTypes from './actionTypes';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';
import { ProductSettingsType } from './types';

const buildSettingsQuery = (settingsTypes?: ProductSettingsType[]): string => {
    return settingsTypes && settingsTypes?.length > 0
        ? `?types=${settingsTypes.join(',')}`
        : ''
}

function* getProductSettingsApi(action: Actions.GetProductSettingsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PRODUCT_BRAND.GET_SETTINGS(buildSettingsQuery(action.settingsTypes)))
        yield put(Actions.getProductSettingsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getProductSettingsError(err));
    }
}

export default function* productSettingsSagas() {
    yield takeLatest(ActionTypes.GET_PRODUCT_SETTINGS_API, getProductSettingsApi)
}