import { PageCard } from "src/common/styles/styles"
import { CreateRoutingSaleForm } from "./CreateSaleForm"

export const CreateRoutingSale = () => {

    const onFinish = (sale: Partial<any>) => {
        // setFormValues(sale as Sale)
        // setModalConfirmationOpen(true)
    }

    const renderForm = () => (
        <CreateRoutingSaleForm
            onCancel={() => {
                // setModalCancelOpen(true)
            }}
            onFinish={onFinish}
            reCertification={true}
        />
    )

    return (
        <>
            <PageCard size="small">
                {renderForm()}
            </PageCard>
            {/* {renderModals()}
        {renderLoader()} */}
        </>
    )
}
