import { RoutingCustomer } from "src/common/models/routingCustomer";
import { CreateRoutingSaleSuccessAction, ErrorAction, GetRoutingCustomersSuccessAction } from "./action";
import { CREATE_ROUTING_SALE_API, CREATE_ROUTING_SALE_API_ERROR, CREATE_ROUTING_SALE_API_SUCCESS, GET_ROUTING_CUSTOMERS_API, GET_ROUTING_CUSTOMERS_API_ERROR, GET_ROUTING_CUSTOMERS_API_SUCCESS } from "./actionTypes";
import { RoutingSaleError, RoutingSaleErrorOrigin } from "./types";
import { RoutingSale } from "src/common/models/routingSale";

export interface RoutingState {
    error?: RoutingSaleError
    createSaleSuccess: boolean
    upsertedSale?: RoutingSale
    sales?: RoutingSale[]
    currentSale?: RoutingSale
    customers?: RoutingCustomer[]
}

const initialState: RoutingState = {
    createSaleSuccess: false,
}

const routingReducer = (state: RoutingState = initialState, action: any): RoutingState => {
    switch (action.type) {
        case CREATE_ROUTING_SALE_API: {
            return {
                ...state,
                error: undefined,
                createSaleSuccess: false,
            }
        }
        case CREATE_ROUTING_SALE_API_SUCCESS: {
            const successAction = action as CreateRoutingSaleSuccessAction;

            return {
                ...state,
                upsertedSale: successAction.sale,
                createSaleSuccess: true,
            }
        }
        case CREATE_ROUTING_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: RoutingSaleErrorOrigin.CREATE_ROUTING_SALE,
                    detail: errorAction.error
                }
            }
        }
        case GET_ROUTING_CUSTOMERS_API: {
            return {
                ...state,
                error: undefined,
                customers: undefined,
            }
        }
        case GET_ROUTING_CUSTOMERS_API_SUCCESS: {
            const successAction = action as GetRoutingCustomersSuccessAction;

            return {
                ...state,
                customers: successAction.customers,
            }
        }
        case GET_ROUTING_CUSTOMERS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: RoutingSaleErrorOrigin.GET_ROUTING_CUSTOMERS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default routingReducer;