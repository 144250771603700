import { Button, Modal } from "antd"
import { Product } from "src/common/models/product"
import { SystemDescriptions } from "src/common/descriptions"
import { ProductTransactionDetail, ProductTransactionType } from "./types"
import { ProductTransactionForm } from "./ProductTransactionForm"
import { useEffect, useState } from "react"
import { ProductBatchTransactionForm } from "./ProductBatchTransactionForm"
import * as Styles from './styles'
import { useDispatch, useSelector } from "react-redux"
import { cleanProductsByBatchApi, getProductsByBatchApi } from "src/features/Product/state/action"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_PRODUCTS_BY_BATCH_API } from "src/features/Product/state/actionTypes"

interface Props {
    open: boolean
    onAccept: (detail: ProductTransactionDetail[]) => void
    onCancel: () => void
    product?: Product
    detail?: ProductTransactionDetail
    type: ProductTransactionType
}


interface ReduxProps {
    isLoadingProductsByBatch: boolean
    productsByBatch?: Product[]
}

export const ProductTransactionModal = (props: Props) => {

    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingProductsByBatch: serviceLoading(state, [GET_PRODUCTS_BY_BATCH_API]),
        productsByBatch: state.product.productsByBatch,
    }))

    const [isBatchTransaction, setIsBatchTransaction] = useState<boolean>(false)

    const descriptions = SystemDescriptions.COMPONENTS.PRODUCT_TRANSACTION

    useEffect(() => {
        if (reduxProps.productsByBatch && reduxProps.productsByBatch.length > 1) {
            setIsBatchTransaction(true)
        }
    }, [reduxProps.productsByBatch])


    const handleAddByBatch = () => {
        if (!props.product) {
            return
        }

        dispatch(getProductsByBatchApi({
            brand: props.product.productBrandId,
            subBrand: props.product.subBrandId,
            category: props.product.categoryId,
            status: "ACTIVE",
        }))
    }

    const renderHeader = () => {
        return <Styles.Header>
            <Styles.HeaderData>
                <Styles.Title>{props.detail ? descriptions.TITLE_EDIT : descriptions.TITLE}</Styles.Title>
                <Button
                    type="link"
                    onClick={handleAddByBatch}
                    disabled={isBatchTransaction}
                    loading={reduxProps.isLoadingProductsByBatch}
                >
                    {descriptions.TITLE_EXTRA}
                </Button>
            </Styles.HeaderData>
        </Styles.Header>
    }

    const handleCancelBatch = () => {
        setIsBatchTransaction(false)
        dispatch(cleanProductsByBatchApi())
        props.onCancel()
    }

    const handleAcceptBatch = (transactionDetail: ProductTransactionDetail[]) => {
        dispatch(cleanProductsByBatchApi())
        props.onAccept(transactionDetail)
        setIsBatchTransaction(false)
    }

    return (
        props.product
            ? <Modal
                centered
                open={props.open}
                width={1000}
                footer={null}
                closeIcon={false}
                destroyOnClose
            >
                {renderHeader()}
                {
                    isBatchTransaction && reduxProps.productsByBatch
                        ? <ProductBatchTransactionForm
                            productsByBatch={reduxProps.productsByBatch}
                            onAccept={handleAcceptBatch}
                            onCancel={handleCancelBatch}
                            type={props.type}
                            productData={{
                                category: props.product.category ?? '',
                                brand: props.product.brand ?? '',
                                subBrand: props.product.subbrand ?? '',
                            }}
                        />
                        : <ProductTransactionForm
                            product={props.product}
                            onAccept={props.onAccept}
                            onCancel={props.onCancel}
                            detail={props.detail}
                            type={props.type}
                        />
                }

            </Modal>
            : null
    )
}
