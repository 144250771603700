import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { User, UserRoles } from 'src/common/models/user';
import { MenuOption } from 'src/components/Sidebar/types';
import { RootState } from 'src/state/reducer';

interface ReduxProps {
    selectedMenuOption?: MenuOption
    user?: User
}

interface Response {
    isUserAllowedInScreen: boolean,
    isUserAllowedToPerformAdminActions: boolean
}

const useUserPermission = (): Response => {
    const [isUserAllowed, setIsUserAllowed] = useState<boolean>(false);
    const [isUserAllowedAdminActions, setIsUserAllowedAdminActions] = useState<boolean>(false);

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        selectedMenuOption: state.menu.selectedMenuOption,
        user: state.security.user,
    }))

    const getUserScreenPermissions = (): boolean => {
        const roles: UserRoles[] = reduxProps.selectedMenuOption?.roles || []

        return (
            roles.includes(reduxProps.user?.userTypeId as any) ||
            roles.includes(UserRoles.ALL)
        ) || false
    }

    const getUserAdminPermissions = (): boolean => {
        const rolesWithAdminPermissions: UserRoles[] = [
            UserRoles.ADMIN,
        ]

        return rolesWithAdminPermissions.includes(reduxProps.user?.userTypeId as any)
    }

    useEffect(() => {
        if (reduxProps.user) {
            setIsUserAllowed(getUserScreenPermissions());
            setIsUserAllowedAdminActions(getUserAdminPermissions());
        }
    }, [reduxProps.user]);

    return {
        isUserAllowedInScreen: isUserAllowed,
        isUserAllowedToPerformAdminActions: isUserAllowedAdminActions,
    }
};

export default useUserPermission;
