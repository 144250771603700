import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'
import { GetProductsFilters } from 'src/common/models/product'

const buildProductQuery = (filters?: GetProductsFilters): string => {
    const params: string[] = []
    if (!filters) {
        return ''
    }

    if (filters.status) {
        params.push(`status=${filters.status}`)
    }
    if (filters.brand) {
        params.push(`brand=${filters.brand}`)
    }
    if (filters.subBrand) {
        params.push(`subBrand=${filters.subBrand}`)
    }
    if (filters.category) {
        params.push(`category=${filters.category}`)
    }

    return params.length > 0
        ? `?${params.join('&')}`
        : ``
}

function* getProductsApi(action: Actions.GetProductsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PRODUCT.GET_RECORDS(buildProductQuery(action.filters)))
        yield put(Actions.getProductsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getProductsError(err));
    }
}

function* createProductApi(action: Actions.CreateProductAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PRODUCT.CREATE, action.product)
        yield put(Actions.createProductSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.createProductError(err?.response?.data?.error));
    }
}

function* getProductByIdApi(action: Actions.GetProductByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PRODUCT.GET_BY_ID(action.id))
        yield put(Actions.getProductByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getProductByIdError(err));
    }
}

function* updateProductApi(action: Actions.UpdateProductAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().put, Urls.BACKEND.PRODUCT.UPDATE, action.product)
        yield put(Actions.updateProductSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.updateProductError(err?.response?.data?.error));
    }
}

function* getProductsByBatchApi(action: Actions.GetProductsByBatchAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PRODUCT.GET_RECORDS(buildProductQuery(action.filters)))
        yield put(Actions.getProductsByBatchSuccess(response.data))
    } catch (err) {
        yield put(Actions.getProductsByBatchError(err));
    }
}

function* getWarehouseStockApi(action: Actions.GetWarehouseStockAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.WAREHOUSE_STOCK.GET_RECORDS(action.warehouseId))
        yield put(Actions.getWarehouseStockSuccess(response.data))
    } catch (err) {
        yield put(Actions.getWarehouseStockError(err));
    }
}

export default function* productSagas() {
    yield takeLatest(ActionTypes.GET_PRODUCTS_API, getProductsApi)
    yield takeLatest(ActionTypes.CREATE_PRODUCT_API, createProductApi)
    yield takeLatest(ActionTypes.GET_PRODUCT_BY_ID_API, getProductByIdApi)
    yield takeLatest(ActionTypes.UPDATE_PRODUCT_API, updateProductApi)
    yield takeLatest(ActionTypes.GET_PRODUCTS_BY_BATCH_API, getProductsByBatchApi)
    yield takeLatest(ActionTypes.GET_WAREHOUSE_STOCK_API, getWarehouseStockApi)
}