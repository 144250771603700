import { Languages } from "../../types";
import { accountPayablesDictionary } from "./AccountPayables";
import { createPurchaseDictionary } from "./CreatePurchase";
import { purchasePaymentRecordsDictionary } from "./PurchasePaymentRecords";
import { purchaseRecordsDictionary } from "./PurchaseRecords";
import { PurchasePagesDescriptions } from "./types";

export const getPurchaseDescriptions = (systemLang: Languages): PurchasePagesDescriptions => ({
    CREATE: createPurchaseDictionary[systemLang],
    PURCHASE_RECORDS: purchaseRecordsDictionary[systemLang],
    ACCOUNT_PAYABLES: accountPayablesDictionary[systemLang],
    PURHCASE_PAYMENT_RECORDS: purchasePaymentRecordsDictionary[systemLang],
})