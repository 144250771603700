import { Languages } from "src/common/descriptions/types";
import { User } from "src/common/models/user";
import { ProductTransactionDetail } from "src/components/ProductTransactionModal/types";

export interface SessionData {
    accessToken?: string,
    user?: User
    saleDetail?: ProductTransactionDetail[]
    purchaseDetail?: ProductTransactionDetail[]
    language?: Languages
}

export enum SessionDataField {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    USER = 'USER',
    CURRENT_SALE = 'CURRENT_SALE',
    CURRENT_PURCHASE = 'CURRENT_PURCHASE',
    SYS_LANG = 'SYS_LANG',
}