export enum ActionButtonType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    DESTRUCTIVE = 'DESTRUCTIVE',
}

export const DictionaryOfBG: Record<ActionButtonType, string> = {
    PRIMARY: "#28A744",
    SECONDARY: "#d9d9d9",
    DESTRUCTIVE: "#DC3646",
}

export const DictionaryOfColor: Record<ActionButtonType, string> = {
    PRIMARY: "#fff",
    SECONDARY: "#000",
    DESTRUCTIVE: "#fff",
}