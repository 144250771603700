import { Layout } from "antd";
import styled from "styled-components";
const { Content } = Layout;

export const ContentContainer = styled(Content)`
    overflow: initial;
    flex: 1;

    gap: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
	flex: 1;
`