import { Modal, Result } from 'antd'
import * as Styles from './styles'

type Props = {
    isVisible: boolean
    title: string
    description: string
    onPrimaryAction: () => void
    onSecondaryActiont: () => void
    primaryLabel: string
    secondaryLabel: string
    hideSecondaryButton?: boolean
    successWithWarning?: boolean
}

export const ModalSuccess = (props: Props) => {

    const renderButtonsSection = () => {
        return <Styles.Container key={1}>
            {
                !props.hideSecondaryButton &&
                <Styles.ActionButton
                    onClick={props.onSecondaryActiont} key="console"
                >
                    {props.secondaryLabel}
                </Styles.ActionButton>
            }

            <Styles.ActionButton
                onClick={props.onPrimaryAction}
                type="primary"
                key="buy"
            >
                {props.primaryLabel}
            </Styles.ActionButton>
        </Styles.Container>
    }

    return (
        <Modal
            title=""
            centered
            open={props.isVisible}
            width={500}
            okText="Cancel"
            cancelText="Keep working"
            footer={<></>}
            closable={false}
            zIndex={1200}
            getContainer={false}
        >
            <Result
                status={props.successWithWarning ? "warning" : "success"}
                title={props.title}
                subTitle={props.description}
                extra={[
                    renderButtonsSection(),
                ]}
            />


        </Modal>
    )
}
