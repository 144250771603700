import { Button } from "antd";
import { HorizontalFlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const Container = styled(HorizontalFlexDiv)`
    justify-content: center;
    gap: 16px;
`

export const ActionButton = styled(Button)`
    height: 45px;
    min-width: 125px;
    font-size: 16px;
`