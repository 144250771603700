import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS } from "./config"
import { WarehouseStock as IWarehouseStock } from "src/common/models/warehouseStock"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_WAREHOUSE_STOCK_API } from "../state/actionTypes"
import { useEffect } from "react"
import { getWarehouseStockApi } from "../state/action"
import { Dropdown, MenuProps } from "antd"
import dayjs from "dayjs"
import { SystemDescriptions } from "src/common/descriptions"
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { Company } from "src/common/models/company"
import WarehouseStockReport from "./WarehouseStockReport"
import { SystemConstants } from "src/common/constants"
import { XlsExporter } from "src/common/XlsExporter"

interface ReduxProps {
    isLoadingData: boolean
    warehouseStock: IWarehouseStock[]
    company?: Company
}

export const WarehouseStock = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWarehouseStockApi(1))
    }, [])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_WAREHOUSE_STOCK_API]),
        warehouseStock: state.product.warehouseStock || [],
        company: state.common.company,
    }))


    const descriptions = SystemDescriptions.PAGES.PRODUCT.WAREHOUSE_STOCK

    const buildReportData = () => {
        return reduxProps?.warehouseStock?.map((reportItem: IWarehouseStock) => {
            return {
                ID: 0,
                PRODUCT: reportItem.product,
                MEASSURE: reportItem.meassure,
                STOCK: reportItem.stock,
            }
        }) ?? []
    }

    const items: MenuProps['items'] = [
        {
            label: descriptions.EXPORT_DATA,
            key: 'EXPORT_EXCEL',
            icon: <FileExcelOutlined rev={undefined} />,
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "EXPORT_EXCEL") {
            const exporter = new XlsExporter({
                sheetPrefixName: "Report",
                documentName: "WarehouseStockReport",
                appendTimestamp: true,
            })

            exporter.exportData(buildReportData())
        }
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        if (!reduxProps.warehouseStock || !reduxProps.company) {
            return
        }

        WarehouseStockReport({
            data: reduxProps.warehouseStock,
            date: dayjs().format(SystemConstants.DATETIME_FORMAT_DISPLAY),
            company: reduxProps.company,
        })
    };

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Dropdown.Button
                size="small"
                menu={menuProps}
                onClick={handleButtonClick}
                disabled={!reduxProps.warehouseStock || reduxProps.warehouseStock.length === 0}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }} >
                    {<PrinterOutlined rev={undefined} />} {descriptions.PRINT}
                </div>
            </Dropdown.Button>
        </div>
    }

    const renderContent = () => (
        <PageCard size="small" title={descriptions.TITLE} extra={renderExtraContent()}>
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.warehouseStock}
                tableColumns={TABLE_COLUMNS}
                showLoader={reduxProps.isLoadingData}
            />
        </PageCard >
    )

    return (
        renderContent()
    )
}
