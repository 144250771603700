import { Languages } from "../../types"
import { BreadcrumbDescriptions } from "./types"

const DescriptionsEnUs: BreadcrumbDescriptions = {
    ROOT: 'Home',
}

const DescriptionsEsUs: BreadcrumbDescriptions = {
    ROOT: 'Inicio',
}

const breadcrumbDictionary = {}
breadcrumbDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
breadcrumbDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getBreadcrumbDescriptions = (systemLang: Languages): BreadcrumbDescriptions => (
    breadcrumbDictionary[systemLang]
)