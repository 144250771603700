import { Charts } from "./Charts"
import { CompanyData } from "./CompanyData"
import { Shortcuts } from "./Shortcuts"
import * as Styles from './styles'
import { DashboardItems } from "./types"

interface Props {
    itemsToShow: DashboardItems[]
}

export const Dashboard = (props: Props) => {

    return (
        <Styles.Container>
            {
                (
                    props.itemsToShow.includes(DashboardItems.COMPANY_DATA) ||
                    props.itemsToShow.includes(DashboardItems.ALL)
                ) &&
                <CompanyData />
            }
            {
                (
                    props.itemsToShow.includes(DashboardItems.SHORTCUTS) ||
                    props.itemsToShow.includes(DashboardItems.ALL)) &&
                <Shortcuts />
            }
            {
                (
                    props.itemsToShow.includes(DashboardItems.CHARTS) ||
                    props.itemsToShow.includes(DashboardItems.ALL)) &&
                < Charts />
            }
        </Styles.Container>
    )
}
