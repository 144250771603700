import { SystemConstants } from "src/common/constants";

const GET_USERS = 'GET_USERS';
export const GET_USERS_API = GET_USERS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_USERS_SUCCESS = GET_USERS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_USERS_ERROR = GET_USERS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_API = CREATE_USER + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_USER_SUCCESS = CREATE_USER + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_USER_ERROR = CREATE_USER + SystemConstants.API_LOADER.ERROR_SUFFIX;
