import { Action } from 'redux'
import * as Actions from './actionTypes'
import { CustomerPayment } from 'src/common/models/customerPayment'

export interface GetCustomerPaymentsAction extends Action {
    dateFrom: string
    dateTo: string
}

export interface GetCustomerPaymentsSuccessAction extends Action {
    payments: CustomerPayment[]
}

export interface ErrorAction extends Action {
    error?
}

export const getCustomerPaymentsApi = (dateFrom: string, dateTo: string): GetCustomerPaymentsAction => ({
    type: Actions.GET_CUSTOMER_PAYMENTS_API,
    dateFrom,
    dateTo,
})

export const getCustomersApiSuccess = (payments: CustomerPayment[]): GetCustomerPaymentsSuccessAction => ({
    type: Actions.GET_CUSTOMER_PAYMENTS_SUCCESS,
    payments,
})

export const getCustomersApiError = (error?): ErrorAction => ({
    type: Actions.GET_CUSTOMER_PAYMENTS_ERROR,
    error,
})