import { Languages } from "../../types"
import { AddProductDescriptions } from "./types"

const DescriptionsEnUs: AddProductDescriptions = {
    TITLE_ADD: "Add Product",
    TITLE_EDIT: "Edit Product",
    FORM: {
        PRODUCT: {
            LABEL: "Product",
            PLACEHOLDER: "Product",
        },
        MEASSURE: {
            LABEL: "Meassure",
            PLACEHOLDER: "Select",
        },
        QTY: {
            LABEL: "Qty",
            PLACEHOLDER: "Input qty",
        },
        PRICE: {
            LABEL: "Price",
            PLACEHOLDER: "Input price",
        },
        SUBTOTAL: {
            LABEL: "Subtotal",
            PLACEHOLDER: "Subtotal",
        },
        DISSCOUNT: {
            LABEL: "Disscount",
            PLACEHOLDER: "Input Disscount",
        },
        TOTAL: {
            LABEL: "Total",
            PLACEHOLDER: "Total",
        },
    },
    ERRORS: {
        INVALID_QTY: "Invalid qty",
    },
    ACCEPT: "Accept",
    CANCEL: "Cancel",
}

const DescriptionsEsUs: AddProductDescriptions = {
    TITLE_ADD: "Agregar Producto",
    TITLE_EDIT: "Editar Producto",
    FORM: {
        PRODUCT: {
            LABEL: "Producto",
            PLACEHOLDER: "Producto",
        },
        MEASSURE: {
            LABEL: "Presentación",
            PLACEHOLDER: "Seleccione",
        },
        QTY: {
            LABEL: "Cant",
            PLACEHOLDER: "Ingresa cantidad",
        },
        PRICE: {
            LABEL: "Precio",
            PLACEHOLDER: "Ingresa precio",
        },
        SUBTOTAL: {
            LABEL: "Subtotal",
            PLACEHOLDER: "Subtotal",
        },
        DISSCOUNT: {
            LABEL: "Descuento",
            PLACEHOLDER: "Ingresa descuento",
        },
        TOTAL: {
            LABEL: "Total",
            PLACEHOLDER: "Total",
        },
    },
    ERRORS: {
        INVALID_QTY: "Cantidad inválida",
    },
    ACCEPT: "Aceptar",
    CANCEL: "Cancelar",
}

const addProductDictionary = {}
addProductDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
addProductDictionary[Languages.SPANISH_US] = DescriptionsEsUs

export const getAddProductDescriptions = (systemLang: Languages): AddProductDescriptions => (
    addProductDictionary[systemLang]
)