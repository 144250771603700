import { Action } from 'redux'
import * as Actions from './actionTypes'

export interface getCustomersAction extends Action {
}

export interface getCustomersSuccessAction extends Action {
    customers: any
}

export interface ErrorAction extends Action {
    error?
}

export const getCustomersApi = (): getCustomersAction => ({
    type: Actions.GET_CUSTOMERS_API
})

export const getCustomersApiSuccess = (customers: any): getCustomersSuccessAction => ({
    type: Actions.GET_CUSTOMERS_API_SUCCESS,
    customers,
})

export const getCustomersApiError = (error?): ErrorAction => ({
    type: Actions.GET_CUSTOMERS_API_ERROR,
    error,
})