
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/reducer'
import * as Styles from './styles'

interface ReduxProps {
    drawerOpened: boolean
}

interface Props {
    placement: any
    closable: boolean
    onClose: () => void
    children: any
}

export const SideMenu = (props: Props) => {

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        drawerOpened: state.menu.drawerOpened,
    }))

    return (
        <Styles.AppDrawer
            title=""
            placement={props.placement}
            closable={false}
            onClose={props.onClose}
            open={reduxProps.drawerOpened}
            key={props.placement}
            width={250}
        >
            {props.children}
        </Styles.AppDrawer>
    )
}
