import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* getCompanyByIdApi(action: Actions.GetCompanyByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.COMPANY.GET_BY_ID(action.companyId))
        yield put(Actions.getCompanyByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getCompanyByIdError(err));
    }
}

export default function* commonSagas() {
    yield takeLatest(ActionTypes.GET_COMPANY_API, getCompanyByIdApi)
}