import { CommonPagesDescriptions } from "./Common/types"
import { AddProductDescriptions } from "./components/AddProduct/types"
import { BreadcrumbDescriptions } from "./components/breadcrumb/types"
import { DashboardDescriptions } from "./components/Dashboard/types"
import { FooterDescriptions } from "./components/footer/types"
import { NavbarDescriptions } from "./components/navbar/types"
import { ModalPaymentDescriptions } from "./components/PaymentModal/types"
import { ProductTransactionDescriptions } from "./components/ProductTransactionModal/types"
import { SearchableTableDescriptions } from "./components/searchableTable/types"
import { SidebarDescriptions } from "./components/Sidebar/types"
import { ProductPagesDescriptions } from "./Pages/Product/types"
import { PurchasePagesDescriptions } from "./Pages/Purchase/types"
import { ReportsPagesDescriptions } from "./Pages/Reports/types"
import { SalePagesDescriptions } from "./Pages/Sale/types"
import { SecurityPagesDescriptions } from "./Pages/security/types"
import { SettingsPagesDescriptions } from "./Pages/Settings/types"

export enum Languages {
    ENGLISH_US = 'en-us',
    SPANISH_US = 'es-us'
}

export const DefaultSettings = {
    SYSTEM_LANG: Languages.SPANISH_US
}

export interface Descriptions {
    PAGES: {
        SECURITY: SecurityPagesDescriptions
        PRODUCT: ProductPagesDescriptions
        COMMON: CommonPagesDescriptions
        PURCHASE: PurchasePagesDescriptions
        SALE: SalePagesDescriptions
        REPORTS: ReportsPagesDescriptions
        SETTINGS: SettingsPagesDescriptions
    }
    COMPONENTS: {
        BREADCRUMB: BreadcrumbDescriptions
        FOOTER: FooterDescriptions
        NAVBAR: NavbarDescriptions
        SEARCHABLE_TABLE: SearchableTableDescriptions
        SIDEBAR: SidebarDescriptions
        ADD_PRODUCT: AddProductDescriptions
        PRODUCT_TRANSACTION: ProductTransactionDescriptions
        DASHBOARD: DashboardDescriptions
        MODAL_PAYMENT: ModalPaymentDescriptions
    }
}

export interface FormItem {
    LABEL: string
    PLACEHOLDER: string
}

export interface ConfirmationModal {
    TITLE: string
    DESCRIPTION: string
    ACTION_BUTTON: string
    CANCEL_BUTTON: string
}

export interface SuccessModal {
    TITLE: string
    PRIMARY_LABEL: string
    SECONDARY_LABEL: string
    DESCRIPTION: string
}

export interface FormPageDescriptions {
    TITLE: string
    SAVE_BUTTON: string
    CANCEL_BUTTON: string
    CONFIRM_MODAL: ConfirmationModal
    CANCEL_MODAL: ConfirmationModal
    SUCCESS_MODAL: SuccessModal
    SUCCESS_SECONDARY_MODAL?: SuccessModal
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => string
        SAVE_ACTION: string
        UNKNOWN: string
    }
    MAIN_ACTION_TITLE: string
    MAIN_ACTION_DESCRIPTION: string
}

export interface ListPageDescriptions {
    TITLE: string
    TABLE: {
        ACTION_BUTTON: string
        SEARCH_PLACEHOLDER: string
        LOADING_DATA: string
    }
}