import { Action } from 'redux'
import * as Actions from './actionTypes'
import { KardexParams, ReportParams } from './types'
import { SaleKardexReport } from '../SaleDetail/ProductsKardex/types'
import { UnitsSoldReport } from '../SaleDetail/UnitsSold/types'

export interface GetKardexReportAction extends Action {
    params: KardexParams
}
export interface GetKardexReportSuccessAction extends Action {
    report: SaleKardexReport[]
}
export interface ErrorAction extends Action {
    error?
}
export const getKardexReportApi = (params: KardexParams): GetKardexReportAction => ({
    type: Actions.GET_KARDEX_REPORT_API,
    params,
})
export const getKardexReportSuccess = (report: SaleKardexReport[]): GetKardexReportSuccessAction => ({
    type: Actions.GET_KARDEX_REPORT_API_SUCCESS,
    report,
})
export const getKardexReportError = (error?): ErrorAction => ({
    type: Actions.GET_KARDEX_REPORT_API_ERROR,
    error,
})

export interface GetUnitsSoldReportAction extends Action {
    params: ReportParams
}
export interface GetUnitsSoldSuccessAction extends Action {
    report: UnitsSoldReport[]
}
export const getUnitsSoldReporttApi = (params: ReportParams): GetUnitsSoldReportAction => ({
    type: Actions.GET_UNITS_SOLD_REPORT_API,
    params,
})
export const getUnitsSoldReporttSuccess = (report: UnitsSoldReport[]): GetUnitsSoldSuccessAction => ({
    type: Actions.GET_UNITS_SOLD_REPORT_API_SUCCESS,
    report,
})
export const getUnitsSoldReporttError = (error?): ErrorAction => ({
    type: Actions.GET_UNITS_SOLD_REPORT_API_ERROR,
    error,
})