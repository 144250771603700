import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* getKardexReport(action: Actions.GetKardexReportAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.REPORTS.GET_KARDEX_REPORT({
            dateFrom: action.params.dateFrom,
            dateTo: action.params.dateTo,
            productId: action.params.productId,
            type: action.params.type,
        }))
        yield put(Actions.getKardexReportSuccess(response.data))
    } catch (err) {
        yield put(Actions.getKardexReportError(err));
    }
}

function* getUnitsSoldReport(action: Actions.GetUnitsSoldReportAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.REPORTS.GET_UNITS_SOLD_REPORT(action.params))
        yield put(Actions.getUnitsSoldReporttSuccess(response.data))
    } catch (err) {
        yield put(Actions.getUnitsSoldReporttError(err));
    }
}

export default function* reportSagas() {
    yield takeLatest(ActionTypes.GET_KARDEX_REPORT_API, getKardexReport)
    yield takeLatest(ActionTypes.GET_UNITS_SOLD_REPORT_API, getUnitsSoldReport)
}