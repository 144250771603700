import styled from "styled-components";
import * as Antd from 'antd'
import { FlexDiv } from "src/common/styles/div";
import { Colors } from "src/common/styles/colors";
import { Fonts } from "src/common/styles/fonts";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: ${Fonts.INTER};
`;

export const FormBlockWrapper = styled.div<{ isSignup?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0.92;
  background-color: ${(props) => (props.isSignup ? '#433B7C' : '#E8ECEF')};
  transition: all 0.85s ease-in-out;

  &--is-login {
    opacity: 0.92;
    background-color: #2C497F;
  }

  &--is-signup {
    opacity: 0.94;
    background-color: #433B7C;
  }
`;

export const FormBlock = styled.section<{ isSignup?: boolean }>`
    font-family: 'Open Sans', sans-serif;
  position: relative;
  width: 337px;
  height: 330px;
  padding: 25px;
  background: white;
  border-radius: 2px;
  color: #000;
  box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07);

  &--is-login {
    opacity: 1;
  }

  &--is-signup {
    opacity: 0;
  }
`;

export const FormBlockHeader = styled.header`
  margin-bottom: 20px;

  h1 {
    font-size: 30px;
    margin: 0 0 20px;
  }
`;

export const FormBlockToggleBlock = styled.div`
  position: relative;

  span {
    font-size: 13px;
    font-weight: 300;
    color: #212529;
  }
`;

export const InputWrapper = styled(FlexDiv)`
    transition: all 0.25s cubic-bezier(0.36, 1, 0.62, 0.98) 0.3s;
`;

export const FormGroupInput = styled.input`
  display: block;
  font-size: 15px;
  color: #22262A;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 14px 10px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid #CCD0D2;
  border-radius: 1px;
  transition: transform 0.35s cubic-bezier(0.36, 1, 0.62, 0.98),
    opacity 0.3s ease-in-out 0.2s,
    background 0.15s ease-in-out,
    border 0.3s ease-in-out;

  &:focus {
    outline: none;
    border: 1px solid #CCD0D2;
    background-color: transparent;
  }

  .form-block--is-signup & {
    transform: translateX(-150%);
  }

  .form-group--login & {
    opacity: 1;
    display: flex;

    .form-block--is-signup & {
      opacity: 0;
    }
  }

  .form-group--signup & {
    opacity: 0;

    .form-block--is-signup & {
      opacity: 1;
    }
  }

  ::placeholder {
    font-weight: 300;
    color: #CCD0D2;
  }
`;

export const Button = styled(Antd.Button)`
    width: 100%;
    height: 40px;
    display: inline-block;
    padding: 8px 12px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 13px 8px rgba(0, 0, 0, 0.1);
    background-color: #007BFE;
    color: white;

  &:hover {
    box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.15);
    background-color: #3F95FE;
    color: white !important;
  }
`
export enum AlertType {
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
}
export const AlertContainer = styled(FlexDiv) <{ type: AlertType }>`
    width: 100%;
    height: 46px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.type === AlertType.SUCCESS ? Colors.GREEN_SUCCESS : Colors.RED_DANGER};
    color: white;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    overflow: hidden;
    font-family: ${Fonts.INTER};
`