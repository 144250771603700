import { Languages } from "src/common/descriptions/types";
import { SalesReportDescriptions } from "./types";

const DescriptionsEnUs: SalesReportDescriptions = {
    TITLE: "Sales Report",
    TABLE: {
        ACTION_BUTTON: "Generate Report",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        DAY: {
            KEY: "No",
            DATETIME: "Datetime",
            INVOICE_RECEIVER_ID: "NIT/CUI",
            CUSTOMER: "Customer",
            TOTAL: "Total",
        },
        MONTH: {
            KEY: "No",
            DATE: "Date",
            QTY: "Qty",
            AMOUNT: "Amount",
            AMOUNT_WITH_ID: "With Id",
            AMOUNT_WITHOUT_ID: "Without Id",
        },
    },
    EXPORT_DATA: "Export Data",
    PRINT: "Print",
    REPORT: {
        DATE_FROM: "From",
        DATE_TO: "To",
        PRINTED_AT: "Printed at",
        PAGE: "Page",
        TOTAL: "Total",
        VIEW: {
            DAY: "Day",
            MONTH: "Month",
            YEAR: "Year",
        },
    },
}

const DescriptionsEsUs: SalesReportDescriptions = {
    TITLE: "Unidades vendidas",
    TABLE: {
        ACTION_BUTTON: "Generar Reporte",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        DAY: {
            KEY: "No",
            DATETIME: "Fecha",
            INVOICE_RECEIVER_ID: "NIT/CUI",
            CUSTOMER: "Cliente",
            TOTAL: "Total",
        },
        MONTH: {
            KEY: "No",
            DATE: "Fecha",
            QTY: "Cant",
            AMOUNT: "Monto",
            AMOUNT_WITH_ID: "Con Id",
            AMOUNT_WITHOUT_ID: "Sin Id",
        },
    },
    EXPORT_DATA: "Exportar Datos",
    PRINT: "Imprimir",
    REPORT: {
        DATE_FROM: "Desde",
        DATE_TO: "Hasta",
        PRINTED_AT: "Impreso",
        PAGE: "Página",
        TOTAL: "Total",
        VIEW: {
            DAY: "Día",
            MONTH: "Mes",
            YEAR: "Año",
        },
    },
}

export const salesReportDictionary = {}
salesReportDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
salesReportDictionary[Languages.SPANISH_US] = DescriptionsEsUs