import { Menu } from 'antd'
import * as Styles from './styles'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuOption, toggleDrawerOpenStatus, toggleSidebarBrokenStatus, toggleSidebarCollapse } from './state/action';
import { MenuState } from './state/reducer';
import { RootState } from 'src/state/reducer';
import { useEffect, useState } from 'react';
import { MenuOption, MenuSection, MenuSections } from './types';
import { User, UserRoles } from 'src/common/models/user';
import { Assets } from 'src/common/assets';

interface ReduxProps {
    menu: MenuState
    user?: User
    sidebarCollapsed: boolean
    drawerOpened: boolean
}

interface Props {
    collapsible?: boolean
    collapsed?: boolean
}

export const Sidebar = (props: Props) => {

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        menu: state.menu,
        user: state.security.user,
        sidebarCollapsed: state.menu.sidebarCollapsed,
        drawerOpened: state.menu.drawerOpened
    }))

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const location = useLocation()

    const handleItemClick = (selected: MenuOption | MenuSection) => {
        if (reduxProps.drawerOpened) {
            setmenuOpenKeys([])
            dispatch(toggleDrawerOpenStatus(false))
        }
        navigate(selected.path || '')
    }

    const menuItems: any[] = MenuSections.filter(
        section => section.roles.includes(reduxProps.user?.userTypeId as any) || section.roles.includes(UserRoles.ALL)
    ).map(section => {

        return {
            key: section.id,
            icon: <i className={section.icon}></i>,
            label: section.displayName,
            onClick: section.items.length === 0 ? () => handleItemClick(section) : null,
            children: (section.items.length > 0)
                ? section.items.map(item => {
                    return {
                        key: item.id,
                        icon: <i className={item.icon}></i>,
                        label: item.displayName,
                        onClick: () => handleItemClick(item),
                    }
                })
                : null
        }
    })

    const onOpenChange = _openKeys => {
        setmenuOpenKeys([..._openKeys])
    }

    const [menuSelectedKeys, setMenuSelectedKeys] = useState<string[]>([])
    const [menuOpenKeys, setmenuOpenKeys] = useState<string[]>([])

    const getSelectedItems = () => {
        const currentPath = location.pathname

        const selectedKeys: string[] = []
        const openKeys: string[] = []

        MenuSections.forEach(section => {
            let foundSection

            if (section?.path === currentPath) {
                selectedKeys.push(section.id)
                foundSection = section
            }

            section.items.forEach(item => {
                if (currentPath === item.path) {
                    selectedKeys.push(item.id)
                    openKeys.push(section.id)
                    foundSection = item
                }

                item.children.forEach(child => {

                    const checkIfIsEditPattern = (currentPath: string, childPath: string): boolean => {
                        const currenPathSplitted = currentPath.split('/').filter((str) => str !== '')
                        const childPathSplitted = childPath.split('/').filter((str) => str !== '')

                        if (currenPathSplitted.length < 2 || childPathSplitted.length < 2) {
                            return false
                        } if (currenPathSplitted.length !== childPathSplitted.length) {
                            return false
                        } else if (currenPathSplitted[0] !== childPathSplitted[0]) {
                            return false
                        } else if (Number(currenPathSplitted[1]) <= 0) {
                            return false
                        }

                        return true
                    }

                    if (currentPath === child.path || checkIfIsEditPattern(currentPath, child.path)) {
                        selectedKeys.push(item.id)
                        openKeys.push(section.id)
                        foundSection = child
                    }
                })
            })

            if (foundSection) {
                foundSection['roles'] = section.roles
                dispatch(selectMenuOption(foundSection))
            }
        })

        setMenuSelectedKeys(selectedKeys)

        if (!reduxProps.drawerOpened && !reduxProps.sidebarCollapsed) {
            setmenuOpenKeys(openKeys)
        } else {
            setmenuOpenKeys([])
        }
    }

    useEffect(() => {
        getSelectedItems()
    }, [reduxProps.menu.selectedMenuOption, location.pathname])

    useEffect(() => {
        if (!reduxProps.sidebarCollapsed) {
            getSelectedItems()
        } else {
            setmenuOpenKeys([])
        }
    }, [reduxProps.sidebarCollapsed])

    return (<Styles.Sidebar
        width={250}
        collapsible={props.collapsible}
        trigger={null}
        collapsed={props.collapsed ?? reduxProps.sidebarCollapsed}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
            dispatch(toggleSidebarCollapse(broken))
            if (!broken) {
                dispatch(toggleDrawerOpenStatus(false))
            }
        }}
        onCollapse={(collapsed, type) => {
            dispatch(toggleSidebarBrokenStatus(collapsed))
        }}
    >
        <Styles.LogoContainer>
            <Styles.Logo
                src={Assets.COMPANY_LOGO_LOCAL}
            />
            <Styles.CompanyNameLabel>{"Control POV"}</Styles.CompanyNameLabel>
        </Styles.LogoContainer>
        <Menu
            theme='dark'
            mode="inline"
            items={menuItems}
            selectedKeys={menuSelectedKeys}
            openKeys={menuOpenKeys}
            onOpenChange={onOpenChange}
        />
    </Styles.Sidebar>
    )
}
