import { ErrorAction, GetCustomerPaymentsSuccessAction } from "./action";
import { parseDataKey } from "src/common/util";
import { CustomerPayment } from "src/common/models/customerPayment";
import { GET_CUSTOMER_PAYMENTS_API, GET_CUSTOMER_PAYMENTS_ERROR, GET_CUSTOMER_PAYMENTS_SUCCESS } from "./actionTypes";
import { CustomerPaymentError, CustomerPaymentErrorOrigin } from "./types";

export interface CustomerPaymentState {
    payments: CustomerPayment[]
    error?: CustomerPaymentError
}

const initialState: CustomerPaymentState = {
    payments: []
}

export default (state: CustomerPaymentState = initialState, action: any): CustomerPaymentState => {
    switch (action.type) {
        case GET_CUSTOMER_PAYMENTS_API: {
            return {
                ...state,
                payments: [],
                error: undefined
            }
        }
        case GET_CUSTOMER_PAYMENTS_SUCCESS: {
            const successAction = action as GetCustomerPaymentsSuccessAction;

            return {
                ...state,
                payments: parseDataKey(successAction.payments),
                error: undefined
            }
        }
        case GET_CUSTOMER_PAYMENTS_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                payments: [],
                error: {
                    type: CustomerPaymentErrorOrigin.GET_CUSTOMER_PAYMENTS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};
