import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { summaryColumnsMonth, TABLE_COLUMNS_MONTH } from "./config"
import moment from "moment"
import { useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import { SystemConstants } from "src/common/constants"
import dayjs from 'dayjs'
import { SaleReportData } from "src/features/Sale/state/reducer"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_SALES_REPORT_API } from "src/features/Sale/state/actionTypes"
import { getSalesReportApi } from "src/features/Sale/state/action"
import { ReportView } from "../../types"
import { Dropdown, MenuProps, Select } from "antd"
import { SystemDescriptions } from "src/common/descriptions"
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { XlsExporter } from "src/common/XlsExporter"
import { SalesReportMonthView } from "src/common/models/sale"
import { Company } from "src/common/models/company"
import SalesRecordsReport from "src/features/Sale/SaleRecords/SaleRecordsReport"

interface ReduxProps {
    isLoadingData: boolean
    salesReport?: SaleReportData
    company?: Company
}

export const SalesReportPage = () => {
    const dispatch = useDispatch()

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const [reportView, setReportView] = useState<ReportView>(ReportView.DAY)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALES_REPORT_API]),
        salesReport: state.sale.report,
        company: state.common.company,
    }))

    const descriptions = SystemDescriptions.PAGES.REPORTS.SALES_REPORT

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const getReportData = () => {
        dispatch(getSalesReportApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
            view: reportView,
        }))
    }

    const handleActionButtonClick = () => {
        getReportData()
    }

    const handleReportViewChange = (value: string) => {
        setReportView(value as ReportView)
    };

    const renderReportViewSelector = () => {
        return <Select
            style={{ width: '100%' }}
            onChange={handleReportViewChange}
            options={[
                { value: 'DAY', label: descriptions.REPORT.VIEW.DAY, disabled: true },
                { value: 'MONTH', label: descriptions.REPORT.VIEW.MONTH },
                { value: 'YEAR', label: descriptions.REPORT.VIEW.YEAR, disabled: true },
            ]}
        />
    }

    const buildReportData = () => {
        return reduxProps?.salesReport?.data?.map((reportItem: any, idx: number) => {
            const data = reportItem as SalesReportMonthView
            return {
                ID: idx,
                DATE: data.datetime,
                QTY: data.saleQty,
                AMOUNT: data.amount,
                AMOUNT_WITH_ID: data.amountWitId,
                AMOUNT_WITHOUT_ID: data.amountWithoutId,
            }
        }) ?? []
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "EXPORT_EXCEL") {
            const exporter = new XlsExporter({
                sheetPrefixName: "Report",
                documentName: "SalesReport",
                appendTimestamp: true,
            })

            exporter.exportData(buildReportData())
        }
    };

    const items: MenuProps['items'] = [
        {
            label: descriptions.EXPORT_DATA,
            key: 'EXPORT_EXCEL',
            icon: <FileExcelOutlined rev={undefined} />,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        if (!reduxProps.salesReport || !reduxProps.company) {
            return
        }

        SalesRecordsReport({
            data: reduxProps.salesReport,
            view: ReportView.MONTH,
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
            company: reduxProps.company,
        })

        // SalesReportDocument({
        //     data: reduxProps.salesReport,
        //     dateFrom: dateRanges.startDate,
        //     dateTo: dateRanges.endDate,
        //     company: reduxProps.company,
        // })
    };

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Dropdown.Button
                size="small"
                menu={menuProps}
                onClick={handleButtonClick}
                disabled={!reduxProps.salesReport || reduxProps.salesReport.data.length === 0}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }} >
                    {<PrinterOutlined rev={undefined} />} {descriptions.PRINT}
                </div>
            </Dropdown.Button>
        </div>
    }

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={(reduxProps.salesReport?.data as any) || []}
                tableColumns={TABLE_COLUMNS_MONTH}
                showLoader={reduxProps.isLoadingData}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                showActionButton
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumnsMonth}
            >
                {renderReportViewSelector()}
            </SearchableTable >
        </PageCard >
    )


    return (
        renderContent()
    )
}
