import { Descriptions, DescriptionsProps, List, Modal } from "antd"
import { PurchasePayment } from "src/common/models/purchasePayment"
import { SystemDescriptions } from "src/common/descriptions"
import { formatToCurrency, formatToDisplayDate } from "src/common/util"

interface Props {
    purchasePayments: PurchasePayment[]
    open: boolean
    onAccept: () => void
    onCancel: () => void
}

export const PurchasePaymentRecords = (props: Props) => {

    const descriptions = SystemDescriptions.PAGES.PURCHASE.PURHCASE_PAYMENT_RECORDS

    const buildListDescription = (purchasePayment): DescriptionsProps['items'] => {
        return [
            {
                key: 'CASH_AMOUNT',
                label: descriptions.CASH_AMOUNT,
                children: formatToCurrency(purchasePayment.cash_amount?.toString() || '0'),
            },
            {
                key: 'CASH_DATE',
                label: descriptions.CASH_DATE,
                children: purchasePayment.cash_date ? formatToDisplayDate(purchasePayment?.cashDate) : 'NA',
            },
            {
                key: 'CASH_REF',
                label: descriptions.CASH_REF,
                children: purchasePayment.cash_reference || 'NA',
            },
            {
                key: 'TRANS_AMOUNT',
                label: descriptions.TRANS_AMOUNT,
                children: formatToCurrency(purchasePayment.trans_amount?.toString() || '0'),
            },
            {
                key: 'TRANS_DATE',
                label: descriptions.TRANS_DATE,
                children: purchasePayment.trans_date ? formatToDisplayDate(purchasePayment?.trans_date) : 'NA',
            },
            {
                key: 'TRANS_REF',
                label: descriptions.TRANS_REF,
                children: `${purchasePayment.trans_bank} ${purchasePayment.trans_reference}` || 'NA',
            },
            {
                key: 'CREDIT_NOTE_AMOUNT',
                label: descriptions.CREDIT_NOTE_AMOUNT,
                children: formatToCurrency(purchasePayment.credit_note_amount?.toString() || '0'),
            },
            {
                key: 'CREDIT_NOTE_DATE',
                label: descriptions.CREDIT_NOTE_DATE,
                children: purchasePayment.credit_note_date ? formatToDisplayDate(purchasePayment?.credit_note_date) : 'NA',
            },
            {
                key: 'CREDIT_NOTE_REF',
                label: descriptions.CREDIT_NOTE_REF,
                children: purchasePayment.creadit_note_reference || 'NA',
            },
        ];
    }

    const renderPaymentsList = () => {
        return <List
            dataSource={props.purchasePayments}
            renderItem={(item: PurchasePayment, index: number) => {
                return <List.Item>
                    <Descriptions
                        key={index}
                        title={`- ${descriptions.TOTAL}: ${formatToCurrency(item.amount.toString())}`}
                        layout="horizontal"
                        items={buildListDescription(item)}
                    />
                </List.Item>
            }}
        />
    }

    return (
        <Modal
            title={descriptions.TITLE}
            centered
            open={props.open}
            onOk={props.onAccept}
            onCancel={props.onCancel}
            width={1000}
            footer={null}
            closable={true}
            maskClosable
        >
            {renderPaymentsList()}
        </Modal>
    )
}
