import { SystemConstants } from "src/common/constants";

const GET_KARDEX_REPORT = 'GET_KARDEX_REPORT';
export const GET_KARDEX_REPORT_API = GET_KARDEX_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_KARDEX_REPORT_API_SUCCESS = GET_KARDEX_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_KARDEX_REPORT_API_ERROR = GET_KARDEX_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_UNITS_SOLD_REPORT = 'GET_UNITS_SOLD_REPORT';
export const GET_UNITS_SOLD_REPORT_API = GET_UNITS_SOLD_REPORT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_UNITS_SOLD_REPORT_API_SUCCESS = GET_UNITS_SOLD_REPORT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_UNITS_SOLD_REPORT_API_ERROR = GET_UNITS_SOLD_REPORT + SystemConstants.API_LOADER.ERROR_SUFFIX;