import { ApiError } from "src/features/Security/networking/types";

export enum RoutingSaleErrorOrigin {
    CREATE_ROUTING_SALE = 'CREATE_ROUTING_SALE',
    GET_ROUTING_CUSTOMERS = 'GET_ROUTING_CUSTOMERS',
}

export interface RoutingSaleError {
    type: RoutingSaleErrorOrigin,
    detail: ApiError
}