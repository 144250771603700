import { SystemConstants } from "src/common/constants";

const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_API = GET_PRODUCTS + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PRODUCTS_API_SUCCESS = GET_PRODUCTS + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PRODUCTS_API_ERROR = GET_PRODUCTS + SystemConstants.API_LOADER.ERROR_SUFFIX;

const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_API = CREATE_PRODUCT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const CREATE_PRODUCT_API_SUCCESS = CREATE_PRODUCT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const CREATE_PRODUCT_API_ERROR = CREATE_PRODUCT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const GET_PRODUCT_BY_ID_API = GET_PRODUCT_BY_ID + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PRODUCT_BY_ID_API_SUCCESS = GET_PRODUCT_BY_ID + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PRODUCT_BY_ID_API_ERROR = GET_PRODUCT_BY_ID + SystemConstants.API_LOADER.ERROR_SUFFIX;

const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_API = UPDATE_PRODUCT + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const UPDATE_PRODUCT_API_SUCCESS = UPDATE_PRODUCT + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const UPDATE_PRODUCT_API_ERROR = UPDATE_PRODUCT + SystemConstants.API_LOADER.ERROR_SUFFIX;

const GET_PRODUCTS_BY_BATCH = 'GET_PRODUCTS_BY_BATCH';
export const GET_PRODUCTS_BY_BATCH_API = GET_PRODUCTS_BY_BATCH + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_PRODUCTS_BY_BATCH_API_SUCCESS = GET_PRODUCTS_BY_BATCH + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_PRODUCTS_BY_BATCH_API_ERROR = GET_PRODUCTS_BY_BATCH + SystemConstants.API_LOADER.ERROR_SUFFIX;

export const CLEAN_PRODUCTS_BY_BATCH = 'CLEAN_PRODUCTS_BY_BATCH';

const GET_WAREHOUSE_STOCK = 'GET_WAREHOUSE_STOCK';
export const GET_WAREHOUSE_STOCK_API = GET_WAREHOUSE_STOCK + SystemConstants.API_LOADER.TRIGGER_SUFFIX;
export const GET_WAREHOUSE_STOCK_API_SUCCESS = GET_WAREHOUSE_STOCK + SystemConstants.API_LOADER.SUCCESS_SUFFIX;
export const GET_WAREHOUSE_STOCK_API_ERROR = GET_WAREHOUSE_STOCK + SystemConstants.API_LOADER.ERROR_SUFFIX;