import { FlexDiv, HorizontalFlexDiv } from "src/common/styles/div";
import styled from "styled-components";

export const Header = styled(FlexDiv)`
`
export const HeaderData = styled(HorizontalFlexDiv)`
    justify-content: space-between;
`
export const Title = styled.div`
    font-weight: bold;
`