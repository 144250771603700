import styled from 'styled-components';

export const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HorizontalFlexDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CenteredFlexDiv = styled(FlexDiv)`
    align-items: center;
    justify-content: center;
`;

export const FullSizeFlexDiv = styled(FlexDiv)`
    height: 100%;
    width: 100%;
`;

export const FlexLink = styled.a`
    display: flex;
    flex-direction: column;
    text-decoration: none;
`;

export const FlexDivLink = styled(FlexLink)`
    align-items: center;
    justify-content: center;
`;