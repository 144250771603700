import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalesReportApi } from "src/features/Sale/state/action";
import { SalesReportDayView, SalesReportMonthView } from "src/common/models/sale";
import { RootState } from "src/state/reducer";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { GET_SALES_REPORT_API } from "src/features/Sale/state/actionTypes";
import dayjs from 'dayjs';
import { Divider, Row } from "antd";
import { Col12 } from "src/components/Columns";
import { SystemConstants } from "src/common/constants";
import { LineChart } from "./LineChart";
import { ChartData } from "./types";
import { ReportView } from "src/common/models/report";
import { SaleReportData } from "src/features/Sale/state/reducer";
import { getPurchasesReportApi } from "src/features/Purchase/state/action";
import { PurchaseeReportData } from "src/features/Purchase/state/reducer";
import { GET_PURCHASES_REPORT_API } from "src/features/Purchase/state/actionTypes";
import { SystemDescriptions } from "src/common/descriptions";
import { formatToCurrency } from "src/common/util";

interface ReduxProps {
  isLoadingMonthSales: boolean
  isLoadingMonthPurchases: boolean
  salesReport?: SaleReportData
  purchasesReport?: PurchaseeReportData
}

export const Charts = () => {

  const dispatch = useDispatch()

  const [monthSales, setMonthSales] = useState<ChartData | undefined>()
  const [monthPurchases, setMonthPurchases] = useState<ChartData | undefined>()
  const [monthSalesWithId, setMonthSalesWithId] = useState<string>('0.00')
  const [monthSalesWithoutId, setMonthSalesWithoutId] = useState<string>('0.00')

  const reduxProps: ReduxProps = useSelector((state: RootState) => ({
    isLoadingMonthSales: serviceLoading(state, [GET_SALES_REPORT_API]),
    isLoadingMonthPurchases: serviceLoading(state, [GET_PURCHASES_REPORT_API]),
    salesReport: state.sale.report,
    purchasesReport: state.purchase.report,
  }))

  const descriptions = SystemDescriptions.COMPONENTS.DASHBOARD.CHARTS

  useEffect(() => {
    dispatch(getSalesReportApi({
      dateFrom: dayjs().startOf('month').format(SystemConstants.DATETIME_DATABASE_FORMAT),
      dateTo: dayjs().format(SystemConstants.DATETIME_DATABASE_FORMAT),
      view: ReportView.MONTH,
    }))

    dispatch(getPurchasesReportApi({
      dateFrom: dayjs().startOf('month').format(SystemConstants.DATETIME_DATABASE_FORMAT),
      dateTo: dayjs().format(SystemConstants.DATETIME_DATABASE_FORMAT),
      view: ReportView.MONTH,
    }))

  }, [])

  const addZeroValuesToMonthData = (monthReportData) => {
    return [
      {
        datetime: dayjs(monthReportData[0].datetime).add(-1, 'day').format(SystemConstants.DATETIME_DATABASE_FORMAT),
        amount: 0,
        amountWithoutId: 0,
        amountWitId: 0,
        saleQty: 0,
      },
      monthReportData[0],
      {
        datetime: dayjs(monthReportData[0].datetime).add(1, 'day').format(SystemConstants.DATETIME_DATABASE_FORMAT),
        amount: 0,
        amountWithoutId: 0,
        amountWitId: 0,
        saleQty: 0,
      },
    ]
  }

  useEffect(() => {
    if (!reduxProps.salesReport) {
      return
    }

    let saleData: any[] = []
    let saleDataInvoiceId: any[] = []
    let saleDataNoInvoiceId: any[] = []
    let summaryAmount: number = 0
    let summaryWithId: number = 0
    let summaryWitoutId: number = 0

    if (reduxProps.salesReport.view === ReportView.DAY) {
      const dayReport = reduxProps.salesReport.data as SalesReportDayView[]

      saleData = dayReport.map(saleReport => {
        summaryAmount += saleReport.amount

        return {
          primary: dayjs(saleReport.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY),
          secondary: saleReport.amount,
          radius: undefined,
        }
      })
    } else if (reduxProps.salesReport.view === ReportView.MONTH) {
      let monthReport = reduxProps.salesReport.data as SalesReportMonthView[]

      if (monthReport.length === 1) {
        monthReport = addZeroValuesToMonthData(monthReport)
      }

      monthReport.forEach(saleReport => {
        summaryAmount += saleReport.amount

        saleData.push({
          primary: dayjs(saleReport.datetime).format("DD"),
          secondary: saleReport.amount,
          radius: undefined,
        })

        if (SystemConstants.INVOICE_FEATURE_FLAG) {
          saleDataInvoiceId.push({
            primary: dayjs(saleReport.datetime).format("DD"),
            secondary: saleReport.amountWitId,
            radius: undefined,
          })
          summaryWithId += saleReport.amountWitId

          saleDataNoInvoiceId.push({
            primary: dayjs(saleReport.datetime).format("DD"),
            secondary: saleReport.amountWithoutId,
            radius: undefined,
          })
          summaryWitoutId += saleReport.amountWithoutId
        }
      })
    }

    setMonthSalesWithId(formatToCurrency(summaryWithId.toString()))
    setMonthSalesWithoutId(formatToCurrency(summaryWitoutId.toString()))

    setMonthSales({
      title: descriptions.MONTH_SALES.TITLE,
      summaryAmount,
      data: [{
        label: descriptions.MONTH_SALES.TOTAL,
        data: saleData,
      },
      {
        label: descriptions.MONTH_SALES.TOTAL_WITH_ID,
        data: saleDataInvoiceId,
      },
      {
        label: descriptions.MONTH_SALES.TOTAL_WITHOUT_ID,
        data: saleDataNoInvoiceId,
      },
      ]
    })
  }, [reduxProps.salesReport])

  useEffect(() => {
    if (!reduxProps.purchasesReport) {
      return
    }

    let purchaseData: any[] = []
    let summaryAmount: number = 0

    if (reduxProps.purchasesReport.view === ReportView.DAY) {
      const dayReport = reduxProps.purchasesReport.data as SalesReportDayView[]

      purchaseData = dayReport.map(saleReport => {
        summaryAmount += saleReport.amount

        return {
          primary: dayjs(saleReport.datetime).format(SystemConstants.DATETIME_FORMAT_DISPLAY),
          secondary: saleReport.amount,
          radius: undefined,
        }
      })
    } else if (reduxProps.purchasesReport.view === ReportView.MONTH) {
      let monthReport = reduxProps.purchasesReport.data as SalesReportMonthView[]

      if (monthReport.length === 1) {
        monthReport = addZeroValuesToMonthData(monthReport)
      }

      purchaseData = monthReport.map(saleReport => {
        summaryAmount += saleReport.amount

        return {
          primary: dayjs(saleReport.datetime).format("DD"),
          secondary: saleReport.amount,
          radius: undefined,
        }
      })
    }

    setMonthPurchases({
      title: descriptions.MONTH_PURCHASES.TITLE,
      summaryAmount,
      data: [{
        label: descriptions.MONTH_PURCHASES.TOTAL,
        data: purchaseData,
      }]
    })
  }, [reduxProps.purchasesReport])

  return (
    <>
      <Divider orientation="left" plain>
        <b>{descriptions.TITLE}</b>
      </Divider>
      <Row gutter={[8, 8]}>
        <Col12>
          <LineChart
            params={monthSales}
            isLoading={reduxProps.isLoadingMonthSales}
            tooltipContent={SystemConstants.INVOICE_FEATURE_FLAG && <p>
              <b>{descriptions.MONTH_SALES.TOTAL_WITH_ID}</b> {monthSalesWithId}
              <br />
              <b>{descriptions.MONTH_SALES.TOTAL_WITHOUT_ID}</b> {monthSalesWithoutId}
            </p>}
          />
        </Col12>
        <Col12>
          <LineChart
            params={monthPurchases}
            isLoading={reduxProps.isLoadingMonthPurchases}
          />
        </Col12>
      </Row>
    </>
  )
}
