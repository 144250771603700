import { ErrorAction, GetKardexReportSuccessAction, GetUnitsSoldSuccessAction } from "./action";
import { ReportError, ReportErrorOrigin } from "./types";
import { parseDataKey } from "src/common/util";
import { GET_KARDEX_REPORT_API, GET_KARDEX_REPORT_API_ERROR, GET_KARDEX_REPORT_API_SUCCESS, GET_UNITS_SOLD_REPORT_API, GET_UNITS_SOLD_REPORT_API_ERROR, GET_UNITS_SOLD_REPORT_API_SUCCESS } from "./actionTypes";
import { SaleKardexReport } from "../SaleDetail/ProductsKardex/types";
import { UnitsSoldReport } from "../SaleDetail/UnitsSold/types";

export interface ReportState {
    kardexReport?: SaleKardexReport[]
    unitsSoldReport?: UnitsSoldReport[]
    error?: ReportError
}

const initialState: ReportState = {
}

const reportReducer = (state: ReportState = initialState, action: any): ReportState => {
    switch (action.type) {
        case GET_KARDEX_REPORT_API: {
            return {
                ...state,
                kardexReport: [],
                error: undefined
            }
        }
        case GET_KARDEX_REPORT_API_SUCCESS: {
            const successAction = action as GetKardexReportSuccessAction;

            return {
                ...state,
                kardexReport: parseDataKey(successAction.report),
            }
        }
        case GET_KARDEX_REPORT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ReportErrorOrigin.GET_KARDEX_REPORT,
                    detail: errorAction.error
                }
            }
        }
        case GET_UNITS_SOLD_REPORT_API: {
            return {
                ...state,
                unitsSoldReport: [],
                error: undefined
            }
        }
        case GET_UNITS_SOLD_REPORT_API_SUCCESS: {
            const successAction = action as GetUnitsSoldSuccessAction;

            return {
                ...state,
                unitsSoldReport: parseDataKey(successAction.report),
            }
        }
        case GET_UNITS_SOLD_REPORT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ReportErrorOrigin.GET_UNITS_SOLD_REPORT,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default reportReducer;