import { ApiError } from "src/features/Security/networking/types";

export enum PurchaseErrorOrigin {
    CREATE_PURCHASE = 'CREATE_PURCHASE',
    GET_PURCHASE_RECORDS = 'GET_PURCHASE_RECORDS',
    GET_PURCHASE_BY_ID = 'GET_PURCHASE_BY_ID',
    CREATE_PURCHASE_PAYMENT = 'CREATE_PURCHASE_PAYMENT',
    GET_PURCHASE_PAYMENTS = 'GET_PURCHASE_PAYMENTS',
    GET_PURCHASES_REPORT = 'GET_PURCHASES_REPORT',
}

export interface PurchaseError {
    type: PurchaseErrorOrigin,
    detail: ApiError
}