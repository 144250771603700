import { Languages } from "src/common/descriptions/types";
import { CreatePurchaseDescriptions } from "./types";

const DescriptionsEnUs: CreatePurchaseDescriptions = {
    TITLE: "Create Purchase",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save Purchase?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Purchase input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Accept",
        DESCRIPTION: "Purchase Created",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        PROVIDER: {
            LABEL: "Provider",
            PLACEHOLDER: "Select Provider",
        },
        DOCUMENT: {
            LABEL: "Document",
            PLACEHOLDER: "Input Document",
        },
        DATETIME: {
            LABEL: "Datetime",
            PLACEHOLDER: "Select Datetime",
        },
        TYPE: {
            LABEL: "Type",
            PLACEHOLDER: "Select Type",
        },
        PAYMENT_DATE: {
            LABEL: "Payment",
            PLACEHOLDER: "Select Payment Date",
        },
        REGULARIZATION: {
            LABEL: "Regularization",
            PLACEHOLDER: "Select",
        },
        IS_ACCOUNTABLE: {
            LABEL: "Is Accountable",
            PLACEHOLDER: "Select",
        },
        PRODUCT: {
            LABEL: "Product",
            PLACEHOLDER: "Select Product",
        },
        DETAIL: {
            COLUMNS: {
                NO: "No",
                DESCRIPTION: "Description",
                MEASSURE: "Meassure",
                QTY: "Qty",
                PRICE: "Price",
                DISSCOUNT: "Dsct",
                TOTAL: "Total",
                ACTIONS: "Actions",
            },
        },
        SUBTOTAL: "Subtotal",
        DISSCOUNT: "Dsct.",
        TOTAL: "Total",
        TYPE_CASH: "Cash",
        TYPE_CREDIT: "Credit",
        ERRORS: {
            TITLE: "An error ocurred",
        },
    },
    MAIN_ACTION_TITLE: "Creating Purchase",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
}

const DescriptionsEsUs: CreatePurchaseDescriptions = {
    TITLE: "Crear Compra",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Compra?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar Ingreso de Compra?",
        DESCRIPTION: "La información ingresada se perderá.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir trabajando",
    },
    SUCCESS_MODAL: {
        TITLE: "Éxito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Aceptar",
        DESCRIPTION: "Compra Creada",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la petición, contactar a soporte."
    },
    FORM: {
        PROVIDER: {
            LABEL: "Proveedor",
            PLACEHOLDER: "Seleccione Proveedor",
        },
        DOCUMENT: {
            LABEL: "Document",
            PLACEHOLDER: "Input Document",
        },
        DATETIME: {
            LABEL: "Fecha",
            PLACEHOLDER: "Seleccione Fecha",
        },
        TYPE: {
            LABEL: "Tipo",
            PLACEHOLDER: "Seleccione Tipo",
        },
        PAYMENT_DATE: {
            LABEL: "Pago",
            PLACEHOLDER: "Seleccione Fecha de Pago",
        },
        REGULARIZATION: {
            LABEL: "Regularización",
            PLACEHOLDER: "Seleccione",
        },
        IS_ACCOUNTABLE: {
            LABEL: "Es Contable",
            PLACEHOLDER: "Seleccione",
        },
        PRODUCT: {
            LABEL: "Producto",
            PLACEHOLDER: "Seleccione Producto",
        },
        DETAIL: {
            COLUMNS: {
                NO: "No",
                DESCRIPTION: "Descripción",
                MEASSURE: "Presentación",
                QTY: "Cant",
                PRICE: "Precio",
                DISSCOUNT: "Dscto",
                TOTAL: "Total",
                ACTIONS: "Acciones",
            },
        },
        SUBTOTAL: "Subtotal",
        DISSCOUNT: "Dscto.",
        TOTAL: "Total",
        TYPE_CASH: "Contado",
        TYPE_CREDIT: "Crédito",
        ERRORS: {
            TITLE: "Ha ocurrido un error",
        },
    },
    MAIN_ACTION_TITLE: "Creando Compra",
    MAIN_ACTION_DESCRIPTION: "Por favor espera...",
}

export const createPurchaseDictionary = {}
createPurchaseDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createPurchaseDictionary[Languages.SPANISH_US] = DescriptionsEsUs