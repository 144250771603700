import { Supplier } from "src/common/models/supplier";
import { ErrorAction, getSuppliersSuccessAction } from "./action";
import { GET_SUPPLIERS_API, GET_SUPPLIERS_API_ERROR, GET_SUPPLIERS_API_SUCCESS } from "./actionTypes";
import { ISupplierError, SupplierErrorOrigin } from "./types";
import { parseDataKey } from "src/common/util";

export interface SupplierState {
    suppliers: Supplier[]
    error?: ISupplierError
}

const initialState: SupplierState = {
    suppliers: []
}

const supplierReducer = (state: SupplierState = initialState, action: any): SupplierState => {
    switch (action.type) {
        case GET_SUPPLIERS_API: {
            return {
                ...state,
                suppliers: [],
                error: undefined
            }
        }
        case GET_SUPPLIERS_API_SUCCESS: {
            const successAction = action as getSuppliersSuccessAction;

            return {
                ...state,
                suppliers: parseDataKey(successAction.suppliers),
                error: undefined
            }
        }
        case GET_SUPPLIERS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                suppliers: [],
                error: {
                    type: SupplierErrorOrigin.GET_SUPPLIERS,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default supplierReducer;