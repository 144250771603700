import { Languages } from "../../types";
import { accountReceivablesDictionary } from "./AccountPayables";
import { createSaleDictionary } from "./CreateSale";
import { saleRecordsDictionary } from "./SaleRecords";
import { SalePagesDescriptions } from "./types";

export const getSaleDescriptions = (systemLang: Languages): SalePagesDescriptions => ({
    CREATE: createSaleDictionary[systemLang],
    RECORDS: saleRecordsDictionary[systemLang],
    ACCOUNT_RECEIVABLES: accountReceivablesDictionary[systemLang],
})