import { Col } from "antd"

export interface Props {
    children?: JSX.Element | null
}

const renderColumn = (props: Props, size: number) => (
    <Col xs={24} sm={12} md={size} lg={size} xl={size}>
        {props?.children}
    </Col>
)

export const Col24 = (props: Props) => renderColumn(props, 24)
export const Col20 = (props: Props) => renderColumn(props, 20)
export const Col18 = (props: Props) => renderColumn(props, 18)
export const Col16 = (props: Props) => renderColumn(props, 16)
export const Col14 = (props: Props) => renderColumn(props, 14)
export const Col12 = (props: Props) => renderColumn(props, 12)
export const Col10 = (props: Props) => renderColumn(props, 10)
export const Col9 = (props: Props) => renderColumn(props, 9)
export const Col8 = (props: Props) => renderColumn(props, 8)
export const Col6 = (props: Props) => renderColumn(props, 6)
export const Col5 = (props: Props) => renderColumn(props, 5)
export const Col4 = (props: Props) => renderColumn(props, 4)
export const Col3 = (props: Props) => renderColumn(props, 3)
export const Col2 = (props: Props) => renderColumn(props, 2)
export const Col1 = (props: Props) => renderColumn(props, 1)