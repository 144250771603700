import { User } from "src/common/models/user";
import * as Actions from "./action";
import * as ActionTypes from "./actionTypes";
import { UserError, UserErrorOrigin } from "./types";

export interface UserState {
    users: User[]
    success: boolean
    error?: UserError
}

const initialState: UserState = {
    users: [],
    success: false
}

export default (state: UserState = initialState, action: any): UserState => {
    switch (action.type) {
        case ActionTypes.GET_USERS_API: {
            return {
                ...state,
                users: [],
                error: undefined
            }
        }
        case ActionTypes.GET_USERS_SUCCESS: {
            const successAction = action as Actions.getUsersSuccessAction;

            return {
                ...state,
                users: successAction.users,
                error: undefined
            }
        }
        case ActionTypes.GET_USERS_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                users: [],
                error: {
                    type: UserErrorOrigin.GET_USERS,
                    detail: errorAction.error
                }
            }
        }
        case ActionTypes.CREATE_USER_API: {
            return {
                ...state,
                success: false,
                error: undefined
            }
        }
        case ActionTypes.CREATE_USER_SUCCESS: {
            return {
                ...state,
                success: true
            }
        }
        case ActionTypes.CREATE_USER_ERROR: {
            const errorAction = action as Actions.ErrorAction;

            return {
                ...state,
                success: false,
                error: {
                    type: UserErrorOrigin.CREATE_USER,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};
