import * as _ from 'lodash';
import { SELECT_MENU_OPTION, TOGGLE_DRAWER_OPEN, TOGGLE_FULL_SCREEN_MODE, TOGGLE_SIDEBAR_BROKEN, TOGGLE_SIDEBAR_COLLAPSE } from './actionTypes';
import { MenuOption, MenuSection } from '../types';
import * as Actions from "./action";

export interface MenuState {
    menuSections: MenuSection[];
    selectedMenuOption?: MenuOption;
    isFullScreenMode: boolean
    sidebarCollapsed: boolean
    drawerOpened: boolean
    sidebarBroken: boolean
}

const initialState: MenuState = {
    menuSections: [],
    isFullScreenMode: false,
    sidebarCollapsed: false,
    drawerOpened: false,
    sidebarBroken: false,
};

export default (state: MenuState = initialState, action: any): MenuState => {
    switch (action.type) {
        case SELECT_MENU_OPTION: {
            return {
                ...state,
                selectedMenuOption: action.menuOption
            }
        }
        case TOGGLE_FULL_SCREEN_MODE: {
            const toggleAction = action as Actions.ToggleAction
            return {
                ...state,
                isFullScreenMode: toggleAction.status,
            }
        }
        case TOGGLE_SIDEBAR_COLLAPSE: {
            const toggleAction = action as Actions.ToggleAction
            return {
                ...state,
                sidebarCollapsed: toggleAction.status,
            }
        }
        case TOGGLE_DRAWER_OPEN: {
            const toggleAction = action as Actions.ToggleAction
            return {
                ...state,
                drawerOpened: toggleAction.status,
            }
        }
        case TOGGLE_SIDEBAR_BROKEN: {
            const toggleAction = action as Actions.ToggleAction
            return {
                ...state,
                sidebarBroken: toggleAction.status,
            }
        }
    }
    return state;
};
