import { PageCard } from "src/common/styles/styles"
import { CreatePurchaseForm } from "./CreatePurchaseForm"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { useEffect, useState } from "react"
import { Urls } from "src/common/urls"
import { useDispatch, useSelector } from "react-redux"
import { ModalSuccess } from "src/components/ModalSuccess"
import { PurchaseError } from "../state/types"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_PURCHASE_API } from "../state/actionTypes"
import { Purchase } from "src/common/models/purchase"
import { createPurchaseApi } from "../state/action"
import { useNavigate } from "react-router"
import { SystemDescriptions } from "src/common/descriptions"
import { Loader } from "src/components/Loader"
import { removeSessionData } from "src/session/sessionStore"
import { SessionDataField } from "src/session/types"

interface ReduxProps {
  error?: PurchaseError
  createPurchaseSuccess: boolean
  isCreatingPurchase: boolean
}

export const CreatePurchase = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
  const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<Purchase | undefined>()
  const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

  const reduxProps: ReduxProps = useSelector((state: RootState) => ({
    error: state.purchase.error,
    createPurchaseSuccess: state.purchase.createPurchaseSuccess,
    isCreatingPurchase: serviceLoading(state, [CREATE_PURCHASE_API]),
  }))

  useEffect(() => {
    if (reduxProps.createPurchaseSuccess) {
      removeSessionData(SessionDataField.CURRENT_PURCHASE)
    }
  }, [reduxProps.createPurchaseSuccess])


  const descriptions = SystemDescriptions.PAGES.PURCHASE.CREATE

  const onFinish = (product: Partial<Purchase>) => {
    setFormValues(product as Purchase)
    setModalConfirmationOpen(true)
  }

  const renderForm = () => (
    <CreatePurchaseForm
      onCancel={() => {
        setModalCancelOpen(true)
      }}
      onFinish={onFinish}
    />
  )

  const handleCancelAccept = () => {
    navigate(Urls.FRONTEND.PURCHASE.RECORDS)
  }

  const handleConfirmationAccept = () => {
    setModalConfirmationOpen(false)
    setActionButtonClicked(true)

    if (formValues?.id) {
      console.log("UNDER CONSTRUCTION")
    } else {
      dispatch(createPurchaseApi(formValues as Purchase))
    }
  }

  const renderModals = () => (
    <>
      <CancelConfirmationModal
        isVisible={modalConfirmationOpen}
        onAccept={handleConfirmationAccept}
        onCancel={() => {
          setModalConfirmationOpen(false)
        }}
        title={descriptions.CONFIRM_MODAL.TITLE}
        okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
        cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
        description={descriptions.CONFIRM_MODAL.DESCRIPTION}
      />
      <CancelConfirmationModal
        isVisible={modalCancelOpen}
        onAccept={handleCancelAccept}
        onCancel={() => { setModalCancelOpen(false) }}
        title={descriptions.CANCEL_MODAL.TITLE}
        okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
        cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
        description={descriptions.CANCEL_MODAL.DESCRIPTION}
      />
      <ModalSuccess
        isVisible={(reduxProps.createPurchaseSuccess) && actionButtonClicked}
        title={descriptions.SUCCESS_MODAL.TITLE}
        description={descriptions.SUCCESS_MODAL.DESCRIPTION}
        onPrimaryAction={handleCancelAccept}
        onSecondaryActiont={handleCancelAccept}
        primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
        secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
        hideSecondaryButton={true}
      />
    </>
  )

  return (
    <>
      <PageCard size="small" title={""}>
        {renderForm()}
      </PageCard>
      {renderModals()}
      <Loader
        isVisible={reduxProps.isCreatingPurchase}
      />
    </>
  )
}