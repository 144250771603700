import * as GlobalStyles from "src/common/styles/styles";
import * as Styles from './styles';
import { Divider, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/reducer";
import { serviceLoading } from "src/common/apiLoader/state/selection";
import { stringToBase64 } from "src/common/util";
import { useEffect } from "react";
import { Urls } from "src/common/urls";
import { useNavigate } from "react-router";
import { getSessionData } from "src/session/sessionStore";
import { LOGIN_API } from "../Security/state/actionTypes";
import { SystemDescriptions } from "src/common/descriptions";
import { login, loginSuccess } from "../Security/state/action";
import { SecurityState } from "../Security/state/reducer";

interface ReduxProps {
    security: SecurityState,
    isLoginLoading: boolean
}

export const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        security: state.security,
        isLoginLoading: serviceLoading(state, [LOGIN_API])
    }))

    const [form] = Form.useForm();

    useEffect(() => {
        const user = getSessionData().user
        if (user) {
            dispatch(loginSuccess(user))
            navigate(Urls.FRONTEND.HOME)
        }
    }, [])

    useEffect(() => {
        if (reduxProps.security.user) {
            setTimeout(() => {
                navigate(Urls.FRONTEND.HOME)
            }, 500);
        }
    }, [reduxProps.security.user])

    const descriptions = SystemDescriptions.PAGES.SECURITY.LOGIN

    const renderAlert = () => {
        return <Styles.AlertContainer
            type={reduxProps.security.error ? Styles.AlertType.DANGER : Styles.AlertType.SUCCESS}
        >
            {
                reduxProps.security.user
                    ? descriptions.SIGNIN_SUCCESSFULL
                    : reduxProps.security.error || descriptions.ERROR_UNKNOWN
            }
        </Styles.AlertContainer>
    }

    const LoginComponent = () => (
        <div>
            <Styles.FormBlockWrapper>
                {
                    (reduxProps.security.error || reduxProps.security.user) &&
                    renderAlert()
                }
            </Styles.FormBlockWrapper>
            <Styles.FormBlock>
                <Styles.FormBlockHeader>
                    <h1>{descriptions.TITLE1} {descriptions.TITLE2}</h1>
                    <Divider />
                    <Styles.FormBlockToggleBlock className="form-block__toggle-block">
                        <span>
                            {descriptions.DESCRIPTION}
                        </span>
                    </Styles.FormBlockToggleBlock>
                </Styles.FormBlockHeader>
                <LoginForm />
            </Styles.FormBlock>
        </div>
    );

    const onFinish = (formValues) => {
        dispatch(login(formValues.username, stringToBase64(formValues.password)))
    }

    const LoginForm = () => (
        <GlobalStyles.Form
            name="formLogin"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            initialValues={{
                username: '',
                password: ''
            }}
        >
            <Styles.InputWrapper >
                <GlobalStyles.ItemForm
                    label={''}
                    name={"username"}
                    rules={[{ required: true, message: descriptions.FORM.ERRORS.REQUIRED_FIELD(descriptions.FORM.USERNAME.LABEL) }]}
                    style={{ fontSize: "10px" }}
                    validateTrigger='onBlur'
                >
                    <GlobalStyles.InputAttribute
                        prefix={<span className="fas fa-user mr-2" style={{ color: "gray" }} />}
                        style={{
                            marginRight: '20px'
                        }}
                        placeholder={descriptions.FORM.USERNAME.PLACEHOLDER}
                    />
                </GlobalStyles.ItemForm>
                <GlobalStyles.ItemForm
                    label={''}
                    name={"password"}
                    rules={[{ required: true, message: descriptions.FORM.ERRORS.REQUIRED_FIELD(descriptions.FORM.PASSWORD.LABEL) }]}
                    validateTrigger='onBlur'
                >
                    <GlobalStyles.InputAttribute
                        prefix={<span className="fas fa-lock mr-2" style={{ color: "gray" }} />}
                        type="password"
                        placeholder={descriptions.FORM.PASSWORD.PLACEHOLDER}
                    />
                </GlobalStyles.ItemForm>
            </Styles.InputWrapper>

            <GlobalStyles.ItemForm>
                <Styles.Button
                    type="primary"
                    loading={reduxProps.isLoginLoading}
                    disabled={reduxProps.isLoginLoading || (!!reduxProps?.security?.user ?? false)}
                    htmlType="submit"
                >
                    {
                        reduxProps.isLoginLoading
                            ? descriptions.LOADING
                            : descriptions.ACTION_BUTTON
                    }
                </Styles.Button>
            </GlobalStyles.ItemForm>
        </GlobalStyles.Form>
    );

    return (
        <Styles.Container>
            <LoginComponent />
        </Styles.Container>
    )
}
