import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { Urls } from "src/common/urls"
import { SecurityState } from "src/features/Security/state/reducer"
import { getSessionData } from "src/session/sessionStore"
import { RootState } from "src/state/reducer"

type ReduxProps = {
    security: SecurityState
}

export const PrivateRoute = ({ children }) => {

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        security: state.security
    }))

    const sessionData = getSessionData();

    return reduxProps.security.user
        ? children
        : sessionData.accessToken
            ? children
            : <Navigate to={Urls.FRONTEND.LOGIN} replace />
}
