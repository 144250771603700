export interface User {
    id: number
    name: string
    statusId: number
    surname: string
    userTypeId: number
    password: string
    username: string

    key?: string
}

export enum UserRoles {
    ALL = 'ALL',
    ADMIN = 1,
    SPECIAL = 2,
    BASIC = 3,
}