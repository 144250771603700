export interface ProductTransactionDetail {
    productId: number
    product: string
    meassureId: number
    meassure: string
    meassureSymbol: string
    qty: number
    unitPrice: number
    subtotal: number
    disscount: number
    total: number
    ref?: string
    stock?: number
    utility?: number
    lastCoste?: number

    productType: ProductType

    description?: string
}

export enum ProductTransactionType {
    PURCHASE = 'PURCHASE',
    SALE = 'SALE',
}

export enum ProductType {
    GOOD = 'G',
    SERVICE = 'S',
}