import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Supplier } from 'src/common/models/supplier'

export interface getSuppliersAction extends Action {
}

export interface getSuppliersSuccessAction extends Action {
    suppliers: Supplier[]
}

export interface ErrorAction extends Action {
    error?
}

export const getSuppliersApi = (): getSuppliersAction => ({
    type: Actions.GET_SUPPLIERS_API
})

export const getSuppliersApiSuccess = (suppliers: Supplier[]): getSuppliersSuccessAction => ({
    type: Actions.GET_SUPPLIERS_API_SUCCESS,
    suppliers,
})

export const getSuppliersApiError = (error?): ErrorAction => ({
    type: Actions.GET_SUPPLIERS_API_ERROR,
    error,
})