import { Modal, Spin } from "antd"

type Props = {
    isVisible: boolean
    title?: string
    description?: string
}

export const Loader = (props: Props) => {
    return (
        <Modal
            open={props.isVisible}
            closable={false}
            footer={null}
            zIndex={1100}
            centered
        >
            <h4>{props.title || "Loading"} &nbsp; <Spin size="large" /></h4>

            <p>{props.description || "This may take a while..."}</p>
        </Modal>
    )
}
