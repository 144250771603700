import { call, put, takeLatest, } from 'redux-saga/effects'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { Urls } from 'src/common/urls'
import { SessionData } from 'src/session/types'
import { storeSessionData } from 'src/session/sessionStore'
import { NetworkingProvider } from '../networking/networkingProvider'

function* loginApi(action: Actions.LoginAction) {
    try {
        const body = {
            username: action.username,
            password: action.password
        }

        const response = yield call(NetworkingProvider.getInstance().getLoginInterface().post, Urls.BACKEND.LOGIN(), body)

        const sessionData: SessionData = {
            user: response.data.user,
            accessToken: response.data.accessToken
        }
        storeSessionData(sessionData)
        const result = yield JSON.parse(JSON.stringify(response.data.user))
        yield put(Actions.loginSuccess(result))

    } catch (err: any) {
        if (err?.response?.status === 401) { // 401 - UNAUTHORIZED
            yield put(Actions.loginError("Usuario/contraseña incorrectos, intenta nuevamente."))
        } else {
            yield put(Actions.loginError(JSON.stringify(err.response)))
        }
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.LOGIN_API, loginApi)
}