import { Languages } from "src/common/descriptions/types";
import { LoginDescriptions } from "./types";

const DescriptionsEnUs: LoginDescriptions = {
    TITLE1: 'Control',
    TITLE2: 'SOFT',
    DESCRIPTION: 'SignIn to start your session.',
    FORM: {
        USERNAME: {
            LABEL: 'Username',
            PLACEHOLDER: 'Input username',
        },
        PASSWORD: {
            LABEL: 'Password',
            PLACEHOLDER: 'Input password',
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        },
    },
    ACTION_BUTTON: 'SignIn',
    FORGOT_PASSWORD: 'Forgot password',
    SIGNIN_SUCCESSFULL: `SingIn successfull, you'll be redirected in a moment...`,
    LOADING: 'Loading...',
    ERROR_UNKNOWN: 'Unknown error',
    ERROR_UNAUTHORIZED: 'Wrong password or username, try again.',
}

const DescriptionsEsUs: LoginDescriptions = {
    TITLE1: 'Control',
    TITLE2: 'SOFT',
    DESCRIPTION: 'Ingresa tus datos',
    FORM: {
        USERNAME: {
            LABEL: 'Usuario',
            PLACEHOLDER: 'Ingresas tu usuario',
        },
        PASSWORD: {
            LABEL: 'Contraseña',
            PLACEHOLDER: 'Ingresa tu contraseña',
        },
        ERRORS: {
            REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        },
    },
    ACTION_BUTTON: 'Acceder',
    FORGOT_PASSWORD: 'He olvidado mi contraseña',
    SIGNIN_SUCCESSFULL: `Inicio de sesión exitoso, en un momento serás redirigido...`,
    LOADING: 'Cargando...',
    ERROR_UNKNOWN: 'Error desconocido',
    ERROR_UNAUTHORIZED: 'Usuario/contraseña incorrectos, intenta nuevamente.',
}

export const loginDictionary = {}
loginDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
loginDictionary[Languages.SPANISH_US] = DescriptionsEsUs