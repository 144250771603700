import { CreateProductSuccessAction, ErrorAction, GetProductsByBatchSuccessAction, GetProductsSuccessAction, GetWarehouseStockSuccessAction, UpdateProductSuccessAction } from "./action";
import { ProductError, ProductErrorOrigin } from "./types";
import { parseDataKey } from "src/common/util";
import { Product } from "src/common/models/product";
import { CLEAN_PRODUCTS_BY_BATCH, CREATE_PRODUCT_API, CREATE_PRODUCT_API_ERROR, CREATE_PRODUCT_API_SUCCESS, GET_PRODUCTS_API, GET_PRODUCTS_API_ERROR, GET_PRODUCTS_API_SUCCESS, GET_PRODUCTS_BY_BATCH_API, GET_PRODUCTS_BY_BATCH_API_ERROR, GET_PRODUCTS_BY_BATCH_API_SUCCESS, GET_PRODUCT_BY_ID_API, GET_PRODUCT_BY_ID_API_ERROR, GET_PRODUCT_BY_ID_API_SUCCESS, GET_WAREHOUSE_STOCK_API, GET_WAREHOUSE_STOCK_API_ERROR, GET_WAREHOUSE_STOCK_API_SUCCESS, UPDATE_PRODUCT_API, UPDATE_PRODUCT_API_ERROR, UPDATE_PRODUCT_API_SUCCESS } from "./actionTypes";
import { WarehouseStock } from "src/common/models/warehouseStock";

export interface ProductState {
    products: Product[]
    error?: ProductError
    createProductSuccess: boolean
    currentProduct?: Product
    updateProductSuccess: boolean
    productsByBatch?: Product[]
    warehouseStock?: WarehouseStock[]
}

const initialState: ProductState = {
    products: [],
    createProductSuccess: false,
    updateProductSuccess: false,
}

const productReducer = (state: ProductState = initialState, action: any): ProductState => {
    switch (action.type) {
        case GET_PRODUCTS_API: {
            return {
                ...state,
                products: [],
                error: undefined
            }
        }
        case GET_PRODUCTS_API_SUCCESS: {
            const successAction = action as GetProductsSuccessAction;

            return {
                ...state,
                products: parseDataKey(successAction.products),
            }
        }
        case GET_PRODUCTS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductErrorOrigin.GET_PRODUCTS,
                    detail: errorAction.error
                }
            }
        }
        case CREATE_PRODUCT_API: {
            return {
                ...state,
                createProductSuccess: false,
                updateProductSuccess: false,
                error: undefined
            }
        }
        case CREATE_PRODUCT_API_SUCCESS: {
            return {
                ...state,
                createProductSuccess: true,
                error: undefined
            }
        }
        case CREATE_PRODUCT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                createProductSuccess: false,
                error: {
                    type: ProductErrorOrigin.CREATE_PRODUCT,
                    detail: errorAction.error
                },
            }
        }
        case GET_PRODUCT_BY_ID_API: {
            return {
                ...state,
                currentProduct: undefined,
                error: undefined,
            }
        }
        case GET_PRODUCT_BY_ID_API_SUCCESS: {
            const successAction = action as CreateProductSuccessAction;

            return {
                ...state,
                currentProduct: successAction.product,
            }
        }
        case GET_PRODUCT_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductErrorOrigin.GET_PRODUCT_BY_ID,
                    detail: errorAction.error,
                },
            }
        }
        case UPDATE_PRODUCT_API: {
            return {
                ...state,
                currentProduct: undefined,
                createProductSuccess: false,
                updateProductSuccess: false,
                error: undefined,
            }
        }
        case UPDATE_PRODUCT_API_SUCCESS: {
            const successAction = action as UpdateProductSuccessAction;

            return {
                ...state,
                currentProduct: successAction.product,
                updateProductSuccess: true,
            }
        }
        case UPDATE_PRODUCT_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductErrorOrigin.UPDATE_PRODUCT,
                    detail: errorAction.error,
                },
            }
        }
        case GET_PRODUCTS_BY_BATCH_API: {
            return {
                ...state,
                error: undefined,
                productsByBatch: undefined,
            }
        }
        case GET_PRODUCTS_BY_BATCH_API_SUCCESS: {
            const successAction = action as GetProductsByBatchSuccessAction;

            return {
                ...state,
                productsByBatch: parseDataKey(successAction.products),
            }
        }
        case GET_PRODUCTS_BY_BATCH_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductErrorOrigin.GET_PRODUCTS_BY_BATCH,
                    detail: errorAction.error,
                },
            }
        }
        case CLEAN_PRODUCTS_BY_BATCH: {
            return {
                ...state,
                productsByBatch: undefined,
            }
        }
        case GET_WAREHOUSE_STOCK_API: {
            return {
                ...state,
                warehouseStock: undefined,
            }
        }
        case GET_WAREHOUSE_STOCK_API_SUCCESS: {
            const successAction = action as GetWarehouseStockSuccessAction;

            return {
                ...state,
                warehouseStock: parseDataKey(successAction.warehouseStock),
            }
        }
        case GET_WAREHOUSE_STOCK_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: ProductErrorOrigin.GET_WAREHOUSE_STOCK,
                    detail: errorAction.error,
                },
            }
        }
    }
    return state;
};

export default productReducer;