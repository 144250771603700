import { Languages } from "src/common/descriptions/types";
import { SettingsDescriptions } from "./types";

const DescriptionsEnUs: SettingsDescriptions = {
    TITLE: "Settings",
    ACTION_BUTTON: "Apply",
    CANCEL_BUTTON: "Cancel",
}

const DescriptionsEsUs: SettingsDescriptions = {
    TITLE: "Configuraciones",
    ACTION_BUTTON: "Aplicar",
    CANCEL_BUTTON: "Cancelar",
}

export const settingsDictionary = {}
settingsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
settingsDictionary[Languages.SPANISH_US] = DescriptionsEsUs