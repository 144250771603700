import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* getInvoiceReceiverByIdApi(action: Actions.GetInvoiceReceiverByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.DTE.GET_INVOICE_RECIEVER_BY_ID(action.id))
        yield put(Actions.getInvoiceReceiverByIdSuccess(response.data, action.type))
    } catch (err: any) {
        yield put(Actions.getInvoiceReceiverByIdError(err?.response?.data?.error));
    }
}

function* getDtePdfApi(action: Actions.GetDtePdfAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getPrintDteInterface().get, Urls.BACKEND.DTE.GET_DTE_PDF(action.authId))
        yield put(Actions.getDtePdfSuccess(response.data))
    } catch (err: any) {
        yield put(Actions.getDtePdfError(err?.response?.data?.error));
    }
}

export default function* dteSagas() {
    yield takeLatest(ActionTypes.GET_INVOICE_RECEIVER_BY_ID_API, getInvoiceReceiverByIdApi)
    yield takeLatest(ActionTypes.GET_DTE_PDF_API, getDtePdfApi)
}