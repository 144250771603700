import { call, put, takeLatest, } from 'redux-saga/effects';
import { Urls } from 'src/common/urls';
import * as Actions from './action';
import * as ActionTypes from './actionTypes';
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider';

function* getCustomersApi() {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.CUSTOMER.GET_RECORDS())
        yield put(Actions.getCustomersApiSuccess(response.data))
    } catch (err) {
        yield put(Actions.getCustomersApiError(err));
    }
}

export default function* securitySagas() {
    yield takeLatest(ActionTypes.GET_CUSTOMERS_API, getCustomersApi)
}