import { PageCard } from "src/common/styles/styles"
import { CreateProductForm } from "./CreateProductForm"
import { SystemDescriptions } from "src/common/descriptions"
import { CancelConfirmationModal } from "src/common/descriptions/components/cancelConfirmationModal"
import { ModalSuccess } from "src/components/ModalSuccess"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Urls } from "src/common/urls"
import { Product } from "src/common/models/product"
import { ProductError } from "../state/types"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { CREATE_PRODUCT_API, GET_PRODUCT_BY_ID_API, UPDATE_PRODUCT_API } from "../state/actionTypes"
import { Loader } from "src/components/Loader"
import { createProductApi, getProductByIdApi, updateProductApi } from "../state/action"
import { useParams } from 'react-router-dom';
import InnerLoader from "src/components/InnerLoader"

interface ReduxProps {
    error?: ProductError
    isCreatingProduct: boolean
    isUpdatingProduct: boolean
    createProductSuccess: boolean
    isGettingProduct: boolean
    currentProduct?: Product
    updateProductSuccess: boolean
}

export const CreateProduct = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id: productId } = useParams<{ id: string }>();

    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false)
    const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<Product | undefined>()
    const [actionButtonClicked, setActionButtonClicked] = useState<boolean>(false)

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        error: state.product.error,
        isCreatingProduct: serviceLoading(state, [CREATE_PRODUCT_API]),
        isUpdatingProduct: serviceLoading(state, [UPDATE_PRODUCT_API]),
        createProductSuccess: state.product.createProductSuccess,
        isGettingProduct: serviceLoading(state, [GET_PRODUCT_BY_ID_API]),
        currentProduct: state.product.currentProduct,
        updateProductSuccess: state.product.updateProductSuccess,
    }))

    const descriptions = SystemDescriptions.PAGES.PRODUCT.CREATE_PRODUCT

    useEffect(() => {
        if (!productId) {
            return
        }

        dispatch(getProductByIdApi(parseInt(productId)))
    }, [productId])


    const handleCancelAccept = () => {
        navigate(Urls.FRONTEND.PRODUCT.PRODUCTS)
    }

    const handleConfirmationAccept = () => {
        setModalConfirmationOpen(false)
        setActionButtonClicked(true)

        if (formValues?.id) {
            dispatch(updateProductApi(formValues as Product))
        } else {
            dispatch(createProductApi(formValues as Product))
        }
    }

    const renderModals = () => (
        <>
            <CancelConfirmationModal
                isVisible={modalConfirmationOpen}
                onAccept={handleConfirmationAccept}
                onCancel={() => {
                    setFormValues(undefined)
                    setModalConfirmationOpen(false)
                }}
                title={descriptions.CONFIRM_MODAL.TITLE}
                okText={descriptions.CONFIRM_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CONFIRM_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <CancelConfirmationModal
                isVisible={modalCancelOpen}
                onAccept={handleCancelAccept}
                onCancel={() => { setModalCancelOpen(false) }}
                title={descriptions.CANCEL_MODAL.TITLE}
                okText={descriptions.CANCEL_MODAL.ACTION_BUTTON}
                cancelText={descriptions.CANCEL_MODAL.CANCEL_BUTTON}
                description={descriptions.CANCEL_MODAL.DESCRIPTION}
            />
            <ModalSuccess
                isVisible={(reduxProps.createProductSuccess || reduxProps.updateProductSuccess) && actionButtonClicked}
                title={descriptions.SUCCESS_MODAL.TITLE}
                description={descriptions.SUCCESS_MODAL.DESCRIPTION}
                onPrimaryAction={handleCancelAccept}
                onSecondaryActiont={handleCancelAccept}
                primaryLabel={descriptions.SUCCESS_MODAL.PRIMARY_LABEL}
                secondaryLabel={descriptions.SUCCESS_MODAL.SECONDARY_LABEL}
                hideSecondaryButton={true}
            />
        </>
    )

    const onFinish = (product: Partial<Product>) => {
        setFormValues(product as Product)
        setModalConfirmationOpen(true)
    }

    const onCancel = () => {
        setModalCancelOpen(true)
    }

    const renderLoader = () => (
        (reduxProps.isCreatingProduct || reduxProps.isUpdatingProduct)
        && <Loader
            isVisible={true}
            title={descriptions.MAIN_ACTION_TITLE}
            description={descriptions.MAIN_ACTION_DESCRIPTION}
        />
    )

    return (
        <>
            <PageCard size="small" title={productId
                ? `${descriptions.EDIT_TITLE} `
                : descriptions.TITLE}>
                {
                    reduxProps.isGettingProduct
                        ? <InnerLoader />
                        : <CreateProductForm
                            onFinish={onFinish}
                            initialValues={productId && reduxProps.currentProduct
                                ? reduxProps.currentProduct
                                : undefined
                            }
                            onCancel={onCancel}
                        />
                }
            </PageCard>
            {renderModals()}
            {renderLoader()}
        </>
    )
}
