import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Company } from '../models/company'

export interface GetCompanyByIdAction extends Action {
    companyId: number
}
export interface GetCompanyByIdSuccessAction extends Action {
    company: Company
}
export interface ErrorAction extends Action {
    error?
}
export const getCompanyByIdApi = (companyId: number): GetCompanyByIdAction => ({
    type: Actions.GET_COMPANY_API,
    companyId,
})
export const getCompanyByIdSuccess = (company: Company): GetCompanyByIdSuccessAction => ({
    type: Actions.GET_COMPANY_API_SUCCESS,
    company,
})
export const getCompanyByIdError = (error?): ErrorAction => ({
    type: Actions.GET_COMPANY_API_ERROR,
    error,
})

export interface UpdateSettingsAction extends Action {
    settings: Object
}
export const getServiceOriginsApi = (settings: Object): UpdateSettingsAction => ({
    type: Actions.UPDATE_SETTINGS,
    settings,
})